import React from 'react';
// react library for routing
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from '../components/Dash/DashNavbar';
import AdminFooter from '../components/Dash/DashFooter';
import Sidebar from '../components/Dash/Sidebar';
import '../assets/scss/custom/dash.scss';
import routes, { IRoute } from '../routes';

interface IAdminProps extends RouteComponentProps {}

interface IAdminState {
  sidenavOpen: boolean;
  doneAnimating: boolean;
}

class Admin extends React.Component<IAdminProps, IAdminState> {
  private mainContent = React.createRef<HTMLDivElement>();

  constructor(props: IAdminProps) {
    super(props);

    this.state = {
      sidenavOpen: true,
      doneAnimating: true
    };
  }

  componentDidUpdate() {
    if (this.props.history.location.pathname !== this.props.location.pathname) {
      document.documentElement.scrollTop = 0;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
      if (this.mainContent.current) {
        this.mainContent.current.scrollTop = 0;
      }
    }
  }
  getRoutes = (r: IRoute[]): JSX.Element[] => {
    const ret: JSX.Element[] = [];

    for (const prop of r) {
      if (prop.collapse && prop.views) {
        ret.push(...this.getRoutes(prop.views));
      }
      if (prop.path.startsWith('/admin')) {
        if (prop.redirect) {
          ret.push(<Redirect from={prop.path} to={prop.to!} key={prop.path} exact={prop.exact || false} />);
        } else {
          ret.push(<Route path={prop.path} component={prop.component} key={prop.path} exact={prop.exact || false} />);
        }
      }
    }

    return ret;
  };
  getBrandText = (path: string) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = () => {
    this.setState(
      {
        doneAnimating: false,
        sidenavOpen: !this.state.sidenavOpen
      },
      this.onFinishAnimation
    );
  };

  closeSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      this.setState(
        {
          sidenavOpen: false,
          doneAnimating: false
        },
        this.onFinishAnimation
      );
    }
  };

  onFinishAnimation = () => setTimeout(() => this.setState({ doneAnimating: true }), 100);

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };
  render() {
    return (
      <div className="dash-container">
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          doneAnimating={this.state.doneAnimating}
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/orbit-logo-dark.png'),
            imgAlt: '...'
          }}
        />
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div className="body-container">
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
          <AdminFooter {...this.props} />
        </div>
      </div>
    );
  }
}

export default Admin;
