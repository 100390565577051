/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Link, RouteComponentProps } from 'react-router-dom';

const AuthFooter = (props: RouteComponentProps) => {
  return (
    <>
      <footer className="orbit-footer" id="footer-main">
        <div className="copyright text-center text-xl-left text-muted">
          © {new Date().getFullYear()}{' '}
          <Link to="/" target="_blank">
            Orbit Jobs
          </Link>
        </div>

        <img src={require('assets/img/brand/orbit-logo-dark.png')} />

        <div>
          <Link className="m-l-25" to="/interviews">Interviews</Link>
        </div>
      </footer>
    </>
  );
};

export default AuthFooter;
