import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Form, Spinner } from 'reactstrap';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { API_BASE } from '../../config/config';
import { ReactStripeElements } from 'react-stripe-elements';
import { useUser, useFirestoreDoc } from '../../util/firebase';
import { ordinalSuffix } from '../../util/ordinal';
import { IOrganization } from '../../models/User.models';
import { FormError } from '../../components/FormError';

interface IPaymentModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	orgId: string;
}

const StripeInputStyle = {
	fontFamily: '"Open Sans", sans-serif',
	color: '#8898aa',
	backgroundColor: '#fff',
	'::placeholder': {
		color: '#adb5bd'
	}
};

interface IStripeIntentResponse {
	secret: string;
}

// focus border: #6b45c5

const PaymentModal = (props: IPaymentModalProps & ReactStripeElements.InjectedStripeProps) => {
	const org = useFirestoreDoc<IOrganization>(`Organizations/${props.orgId}`);
	const [purchasing, setPurchasing] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>('');
	const [user] = useUser();

	const closeBtn = (
		<button className="close"
			onClick={(e) => {
				e.preventDefault();
				props.setOpen(!props.open)
			}}
		>
			&times;
		</button>
	);

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setPurchasing(true);
		setErrorMessage('');

		const intent = await user!
			.getIdToken()
			.then(idToken => fetch(`${API_BASE}/stripe/intent`, { headers: { authorization: 'Bearer ' + idToken } }))
			.then(resp => resp.json() as Promise<IStripeIntentResponse>);

		const cardSetupResponse = await props.stripe!.confirmCardSetup(intent.secret, {
			payment_method: {
				card: props.elements!.getElement('card')!,
				billing_details: {
					name: user!.displayName,
					email: user!.email,
					address: {
						line1: org!.address.line1,
						line2: org!.address.line2,
						postal_code: org!.address.postalCode,
						city: org!.address.city,
						state: org!.address.state,
						country: org!.address.country
					},
					phone: org!.phone
				}
			}
		});

		if (cardSetupResponse.error) {
			console.error(cardSetupResponse.error);
			setErrorMessage(cardSetupResponse.error.message);
		} else {
			if (cardSetupResponse.setupIntent && cardSetupResponse.setupIntent.status === 'succeeded') {
				const idToken = await user!.getIdToken();

				try {
					await fetch(`${API_BASE}/stripe/set-payment-method`, {
						method: 'POST',
						body: JSON.stringify({
							token: cardSetupResponse.setupIntent.payment_method!
						}),
						headers: { 'content-type': 'application/json', authorization: 'Bearer ' + idToken }
					});
				} catch (err) {
					console.error(err);
					setPurchasing(false);
					return;
				}

				let subscription: { token: string; status: string } = { token: '', status: '' };
				try {
					const subscriptionResp = await fetch(`${API_BASE}/stripe/subscription`, {
						headers: { authorization: 'Bearer ' + idToken },
						method: 'PUT'
					});
					subscription = (await subscriptionResp.json()) as { token: string; status: string };
				} catch (err) {
					console.error(err);
					setPurchasing(false);
					return;
				}

				if (subscription && subscription.status && subscription.status === 'active') {
					setSuccess(true);
				}
			}
		}

		setPurchasing(false);
	};

	if (success) {
		return (
			<Modal size="lg" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
				<ModalHeader toggle={() => props.setOpen(!props.open)} close={closeBtn}>
					Success
				</ModalHeader>
				<ModalBody>
					<p>Your payment was successful. You are now an Orbit Business subscriber. Congratulations!</p>
				</ModalBody>
			</Modal>
		);
	}

	return (
		<Modal size="lg" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
			<Form onSubmit={onSubmit}>
				<ModalHeader toggle={() => props.setOpen(!props.open)} close={closeBtn}>
					Purchase
				</ModalHeader>
				<ModalBody>
					{!user ? <div>Please log in to purchase. If you do not have an account, request a demo for the Free tier first.</div> : null}
					{/*<FormGroup>
            <label className="form-control-label" htmlFor="text">
              Card Number
            </label>

            <CardNumberElement style={{ base: StripeInputStyle }} />
          </FormGroup>

          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Card Expiry
            </label>

            <CardExpiryElement style={{ base: StripeInputStyle }} />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Card CVC
            </label>

            <CardCvcElement style={{ base: StripeInputStyle }} />
          </FormGroup>*/}
					{user ? (
						<FormGroup>
							<label className="form-control-label" htmlFor="text">
								Card
							</label>

							<CardElement style={{ base: StripeInputStyle }} />
						</FormGroup>
					) : null}
					<p>You will be billed upon submission and on the {ordinalSuffix(new Date().getDate())} of every month until you cancel.</p>
					{errorMessage ? <FormError>{errorMessage}</FormError> : null}
				</ModalBody>
				<ModalFooter>
					{purchasing ? (
						<Spinner color="primary" />
					) : (
						<>
							{user ? (
								<Button color="primary" type="submit">
									Purchase
								</Button>
							) : null}{' '}
							<Button color="secondary" onClick={() => props.setOpen(!props.open)}>
								Cancel
							</Button>
						</>
					)}
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default injectStripe(PaymentModal);
