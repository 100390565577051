import React, { useEffect, useState} from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import '../../assets/css/video-player-forest-min.css';

interface VideoPlayerProps {
  playerOptions: VideoJsPlayerOptions
}
const VideoPlayer = (props: VideoPlayerProps) => {
  const [player, setPlayer] = useState<videojs.Player | undefined>();
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>();

  useEffect(() => {
    if(player)
      player.dispose();
  }, []);

  useEffect(() => {
    if(videoNode && !player)
      setPlayer(videojs(videoNode, props.playerOptions));
  }, [videoNode, props.playerOptions]);

  return (
    <div data-vjs-player>
      <video id="answer-video-player" ref={ node => setVideoNode(node) } className="video-js vjs-theme-forest" />
    </div>
  )
}

export default VideoPlayer;