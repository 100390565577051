import React from 'react';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import NotificationAlert from 'react-notification-alert';
import { RouteComponentProps } from 'react-router';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Button from "../../components/General/Buttons"

interface ILoginProps extends RouteComponentProps { }

interface ILoginForm {
	email: string;
	password: string;
}

const loginFormValidator = yup.object<ILoginForm>({
	email: yup
		.string()
		.required('Please enter an email.')
		.email('Please enter a valid email.'),
	password: yup
		.string()
		.required('Please enter a password.')
		.min(6, 'Password must be at least 6 characters.')
});

export const Login = (props: ILoginProps) => {
	const [focusedEmail, setFocusedEmail] = useState<boolean>();
	const [focusedPassword, setFocusedPassword] = useState<boolean>();
	const [loading, SetLoading] = useState<boolean>();
	const form = useForm<ILoginForm>({ validationSchema: loginFormValidator });
	const notificationRef = useRef<any>(undefined);
	const [sentResetNotification, setSentResetNotification] = useState<boolean>(false);

	const onSubmit = (data: ILoginForm) => {
		SetLoading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(data.email, data.password)
			.then(user => {
				SetLoading(false);
				if (user && user.user) {
					props.history.push('/admin/dashboard');
				}
			})
			.catch(error => {
				SetLoading(false);
				let options = {
					place: 'tc',
					message: (
						<div className="alert-text">
							<span className="alert-title" data-notify="title">
								{' '}
								Error
							</span>
							<span data-notify="message">{error.message}</span>
						</div>
					),
					type: 'danger',
					icon: 'ni ni-bell-55',
					autoDismiss: 7
				};
				if (notificationRef.current) {
					notificationRef.current.notificationAlert(options);
				}
			});
	};

	if (!sentResetNotification && notificationRef.current && props.history.location.state && (props.history.location.state as any).reset) {
		setSentResetNotification(true);
		delete (props.history.location.state as any).reset;

		let options = {
			place: 'tc',
			message: (
				<div className="alert-text">
					<span className="alert-title" data-notify="title">
						Password Reset
					</span>
					<span data-notify="message">You have been sent a password reset email. Please check your email for further instructions.</span>
				</div>
			),
			type: 'success',
			icon: 'ni ni-bell-55',
			autoDismiss: 7
		};

		notificationRef.current.notificationAlert(options);
	}

	return (
		<>
			<div className="login-container flex-horizontal fade-in-fast">
				<div className="left-img">
					<a href="/">
						<img src={require('assets/img/brand/orbit-logo-dark.png')} />
					</a>
				</div>

				<div className="flex-vertical right-side login flex-center">
					<h1>Login</h1>

					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div>
							<input
								ref={form.register({ required: true })}
								onFocus={() => setFocusedEmail(true)}
								onBlur={() => setFocusedEmail(false)}
								type="email"
								name="email"
								id="email"
								className={`login-input ${form.errors.email ? 'invalid' : ''} ${focusedEmail ? 'focused' : ''}`}
								placeholder="Email"
							/>
							{form.errors.email && <>{form.errors.email.message}</>}
						</div>
						<div>
							<input
								className={`login-input ${form.errors.password ? 'invalid' : ''} ${focusedPassword ? 'focused' : ''}`}
								ref={form.register({ required: true })}
								onFocus={() => setFocusedPassword(true)}
								onBlur={() => setFocusedPassword(false)}
								type="password"
								name="password"
								id="password"
								placeholder="Password"
								autoComplete="on"
							/>
							{form.errors.password && <>{form.errors.password.message}</>}
						</div>
						<Button disabled={loading || !!Object.keys(form.errors).length} type="submit" primary>
							Login
						</Button>
					</form>
					<Link to="/auth/forgot-password">Forgot Password?</Link>
					{/* <Link to="/auth/register">Don't Have An Account?</Link> */}
				</div>
			</div>
			<div className="rna-wrapper">
				<NotificationAlert ref={notificationRef} />
			</div>
		</>
	);
};

export default Login;
