import React from 'react';
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

const AdminFooter = (props: RouteComponentProps) => {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{' '}
                <Link className="font-weight-bold ml-1" to="/" target="_blank">
                  Orbit Jobs
                </Link>
              </div>
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink to="/blog" tag={Link}>
                    Blog
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
};

export default AdminFooter;
