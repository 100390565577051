import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

interface IEmptyNoticeProps {
  message: string;
}

export const EmptyNotice = (props: IEmptyNoticeProps) => (
  <div className="empty-notice">
    <UncontrolledAlert color="info">
      <span className="alert-icon">
        <i className="ni ni-bell-55" />
      </span>
      <span className="alert-text ml-1">{props.message}</span>
    </UncontrolledAlert>
  </div>
);
