import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import AuthHeader from '../../components/Landing/LandingHeaderMinimal';
import InterviewRecorder from '../../components/InterviewRecorder';
import { RouteComponentProps } from 'react-router';
import { useFirestoreDoc, useUser } from '../../util/firebase';
import { IPlacement } from '../../models/Interview.models';
import { IOrganization, ICandidate } from '../../models/User.models';
import { EmptyNotice } from '../../components/Util/EmptyNotice';
import InterviewAnswerProgress from '../../components/Landing/InterviewAnswerProgress';
import { getMediaStream } from '../../components/Util/MediastreamGetter';
import firebase from 'firebase/app';
import LandingNavbar from '../../components/Landing/LandingNavbar';
import Icon from '../../components/General/Icon';
import Button from '../../components/General/Buttons';
import { useForm } from 'react-hook-form';

interface IInterviewProps extends RouteComponentProps<{ org: string; placement: string }> {
  embed?: Boolean;
  setDisplayAuthHeader: React.Dispatch<React.SetStateAction<boolean>>;
  setHideInterviewAnswerProgress: React.Dispatch<React.SetStateAction<boolean>>;
  questionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}

interface ICanidateForm {
  name: string;
  email: string;
  skill: string;
}

const Spinner = (props: { loading?: boolean }) =>
  props.loading ? (
    <div  className="fade-in">
      <div className="nb-spinner"></div>
    </div>
  ) : (
    <div />
  );

export const Interview = (props: IInterviewProps) => {
  const organization = useFirestoreDoc<IOrganization>(`Organizations/${props.match.params.org}`);
  const placement = useFirestoreDoc<IPlacement>(`Organizations/${props.match.params.org}/Placements/${props.match.params.placement}`);
  const [resumeFile, setResumeFile] = useState<FileList | null>(null);
  const [interviewComplete, setInterviewComplete] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<Number>(0.0);
  const [confirmResumeDetails, setConfirmResumeDetails] = useState<boolean>(false);
  const [confirmStartInterview, setConfirmStartInterview] = useState<boolean>(false);
  const [draggedOver, setDraggedOver] = useState(false);
  const [user, userLoaded] = useUser();

  const [slideIndex, setSlideIndex] = useState(0);
  const [recording, setRecording] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [devicesNotFound, setDevicesNotFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const transitions = useTransition(slideIndex, p => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  });

  const [resume, setResume] = useState<boolean>(false);
  const [uploadingResume, setUploadingResume] = useState<boolean>(false);

  const finalQuestion = placement && props.questionIndex === placement.questions.length - 1;
  const question = placement ? placement.questions[props.questionIndex] : undefined;

  //const [responseEnabled, setResponseEnabled] = useState((question && (question.type === 'text' || !question.type)) || false);
  const [responseEnabled, setResponseEnabled] = useState(true);

  const [mediaStream, setMediaStream] = useState<MediaStream|undefined>();
  const [displayRecorder, setDisplayRecorder] = useState(false);
  const [forceFallback, setForceFallback] = useState(false);
  const [deniedPermissions, setDeniedPermissions] = useState(false);
  const [askForMediastream, setAskForMediastream] = useState(false);

  const analytics = firebase.analytics();
  analytics.logEvent('interview_view');

  useEffect(() => {
    if(mediaStream || forceFallback)
      setDisplayRecorder(true);
    else
      setDisplayRecorder(false);
  }, [mediaStream, forceFallback]);

  useEffect(() => {
    if(!mediaStream && askForMediastream)
      getMediaStream(
        setAskForMediastream,
        setDevicesNotFound,
        setErrorMessage,
        mediaStream,
        setMediaStream,
        setForceFallback,
        setDeniedPermissions
      )
  }, [askForMediastream]);

  // Stop updating skills from server if user manually adds or deletes on client
  let skillsLock = false;

  const uploadResume = async () => {
    setUploadLoading(true);
    const orgId = organization!.id;
    const userId = user!.uid;

    setUploadingResume(true);

    // const sp = resumeFile![0].name.split('.');
    // const extension = sp[sp.length - 1];

    const name = resumeFile![0].name;

    const resumeRef = firebase.storage().ref(`resumes/${userId}/${name}`);
    let uploadTask = resumeRef.put(resumeFile![0], {
      contentType: resumeFile![0].type,
      customMetadata: {
        userId: userId
      }
    });

    uploadTask.on(
      'state_changed',

      //Update
      snapshot => {
        setUploadProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      },

      //Error
      error => {
        console.error(error);
      },

      //Success
      async () => {
        const candidateRef = firebase.firestore().doc(`Candidates/${userId}`);

        const dlUrl = await resumeRef.getDownloadURL();

        analytics.logEvent('interview_resume_uploaded', {
          uid: userId,
          url: dlUrl
        });

        await candidateRef.set(
          {
            resume: resumeRef.fullPath,
            resumeUrl: dlUrl,
            orgId: orgId,
            placement: props.match.params.placement
          },
          { merge: true }
        );

        setUploadLoading(false);
        setUploadingResume(false);
        setResume(true);
        setSlideIndex(1);
      }
    );
  };

  const onRecordingDone = async (blob: Blob, timestamp: string, duration?: number) => {


    debugger;

    console.log('Uploading...');

    const orgId = organization!.id;
    const placementId = props.match.params.placement;
    const userId = user!.uid;
    const n = props.questionIndex.toString();



    const extension = blob.type === 'video/quicktime' ? 'mov' : 'webm';

    setRecording(false);
    setUpload(false);
    setUploadLoading(true);

    const r = firebase.storage().ref(`interviews/${orgId}/${placementId}/${userId}/${props.questionIndex}.${extension}`);
    await r.put(blob, {
      contentType: blob.type,
      customMetadata: {
        userId: userId,
        placementId: placementId,
        question: n,
        orgId: orgId,
        timestamp
      }
    });
    const videoUrl = await r.getDownloadURL();

    analytics.logEvent('interview_response_complete', {
      uid: userId,
      orgId: orgId,
      questionIndex: n,
      videoUrl: videoUrl
    });

    //TODO: Make sure that we have rules to secure this

    await firebase
      .firestore()
      .doc(`Organizations/${orgId}/Candidates/${userId}/Placements/${placementId}/Responses/${n}`)
      .set(
        {
          completed: true,
          videoUrl,
          videoMime: blob.type,
          duration,
          score: 0
        },
        { merge: true }
      );

      //account for fade in
      setTimeout(()=>{
        console.log('Uploaded.');
        setUploadLoading(false);
      },2000)




    if (!finalQuestion) {
      const nextQuestion = placement ? placement.questions[props.questionIndex + 1] : undefined;

      props.setQuestionIndex(props.questionIndex + 1);
      setSlideIndex(slideIndex + 1);

      setResponseEnabled((nextQuestion && (nextQuestion.type === 'text' || !nextQuestion.type)) || false);
    } else {
      setDisplayRecorder(false);
      if(mediaStream)
        mediaStream.getTracks().forEach(t => t.stop())
        console.log(mediaStream)
      let defaultList = await firebase
        .firestore()
        .collection(`Organizations/${orgId}/Lists`)
        .where('isDefault', '==', true)
        .get();
      const listId = defaultList.docs[0].id;
      let orgCandidate = (
        await firebase
          .firestore()
          .doc(`Organizations/${orgId}/Candidates/${userId}`)
          .get()
      ).data();
      let candidate = (
        await firebase
          .firestore()
          .doc(`Candidates/${userId}`)
          .get()
      ).data();

      if (orgCandidate) {
        orgCandidate.listId = listId;

        if (orgCandidate.placements) orgCandidate.placements = [...orgCandidate.placements, placementId];
        else orgCandidate.organizations = [placementId];

        await firebase
          .firestore()
          .doc(`Organizations/${orgId}/Candidates/${userId}`)
          .set(orgCandidate, { merge: true });
      }

      if (candidate) {
        if (candidate.organizations) candidate.organizations = [...candidate.organizations, orgId];
        else candidate.organizations = [orgId];

        //reset in case the user uploads a new resume later
        candidate.extractionSuccess = false;
        await firebase
          .firestore()
          .doc(`Candidates/${userId}`)
          .set(candidate, { merge: true });
      }

      //update the placement status
    
      await firebase
      .firestore()
      .doc(`Organizations/${orgId}/Candidates/${userId}/Placements/${placementId}`)
      .update({status: 'complete'});

      setInterviewComplete(true);
      props.setHideInterviewAnswerProgress(true);
      
    }
  };

  const ConcatFileName = (name: string) => (name.length > 22 ? `${name.substr(0, 20)}...` : name);

  const GetUploadStatusText = () => {
    if (resumeFile && !uploadLoading && !uploadingResume) return 'File Loaded: ';
    if (resumeFile && uploadLoading && !uploadingResume) return 'Starting Upload... ';
    if (resumeFile && (uploadLoading || uploadingResume)) return 'Uploading: ';

    return '';
  };

  const OnQuestionVideoEnded = async (event: any) => {
    analytics.logEvent('interview_interview_complete', {
      uid: user!.uid,
    });

    setResponseEnabled(true);
  };

  if (userLoaded && !user) {
    firebase.auth().signInAnonymously();
  }

  if (!organization || !placement) {
    return <div />;
  }

  const CandidateForm = () => {
    const candidate = user && user.uid ? useFirestoreDoc<ICandidate | null>(`Candidates/${user!.uid}`) : null;
    const resumeKeywords = candidate && candidate!.resumeKeywords ? candidate!.resumeKeywords!.map(k => k.word) : [];
    const [keywords, setKeywords] = useState<string[]>(resumeKeywords);
    const [invalidSkill, setInvalidSkill] = useState(true);
    const { register, getValues, setValue, handleSubmit, errors } = useForm<ICanidateForm>();

    //This useEffect will run once after first render, and after that, if candidate is updated. This will prevent
    //infinite loops generated by both if statements bellow
    useEffect(() => {
      if (candidate && candidate.resumeKeywords && !skillsLock) {
        setKeywords(candidate.resumeKeywords.map(s => s.word));
      }

      if (candidate && (candidate.name || candidate.email)) {
        setValue('name', candidate.name);
        setValue('email', candidate.email);
      }
    }, [candidate]);

    const onCandidateSave = async (data: any) => {
      setUploadLoading(true);

      await firebase
        .firestore()
        .doc(`Candidates/${user!.uid}`)
        .update({
          name: data.name,
          email: data.email,
          extractionSuccess: false,
          resumeKeywords: keywords.map(word => {
            return { word, score: 100, type: 'ORGANIZATION' };
          })
        });

      setUploadLoading(false);
      setConfirmResumeDetails(true);
      setSlideIndex(2);
    };

    const onAddKeyword = async (keyword: string) => {
      if(keyword){
        skillsLock = true;
        const keywordsCopy = [...keywords];
        keywordsCopy.unshift(keyword);
        setKeywords(keywordsCopy);
      }
      setValue('skill', '');
    };

    const onInputChange = async () => {
      setInvalidSkill(!getValues().skill ? true : false);
    }

    return (
      <div className="orbit-interview-card">
        <form onSubmit={handleSubmit(onCandidateSave)}>
          <div className="orbit-interview-card-left flex-vertical">
            <div key="step-1" className="flex-horizontal orbit-interview-step done step-1">
              <Icon name="check" /> Step 1 - Upload Your Resume
            </div>
            <div key="step-2" className="flex-horizontal orbit-interview-step active">
              <i /> Step 2 - Confirm Details
            </div>
            <div key="step-3" className="flex-horizontal orbit-interview-step">
              <i /> Step 3 - Video Interview
            </div>
            <Button key="continue-button" primary disabled={uploadLoading || (candidate? !candidate.extractionSuccess : true)}>
              Continue
            </Button>
          </div>
          <div className={`orbit-interview-card-right confirm-details flex-vertical flex-center`}>

          {(candidate && candidate.extractionSuccess) ?
                               (<>

            <p className="explanation">Please confirm the following application details for our records.</p>

            <div className="personal-details">
              <input
                className="w-100 space-between"
                type="text"
                placeholder="Name"
                id="name"
                name="name"
                ref={register({
                  required: 'Required'
                })}
              />

              {errors && errors.name && (
                <div className="error">
                  <i className="fas fa-exclamation" />
                  Please enter a valid name
                </div>
              )}

              <input
                className="w-100"
                type="text"
                id="email"
                name="email"
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address'
                  }
                })}
                placeholder="Email Address"
              />

              {errors && errors.email && (
                <div className="error">
                  <i className="fas fa-exclamation" />
                  {errors.email.message}
                </div>
              )}

              <div className="skills-container">


                               <h2>Skills</h2>
                               <p>Specify various skills that make you a unique candidate. Add as many skills as you like.</p>
             
                               <div className="flex-horizontal add-skill">
                                 <input
                                   type="text"
                                   placeholder="Enter Skill"
                                   id="skill"
                                   name="skill"
                                   ref={register}
                                   onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                                     e.key === 'Enter' && onAddKeyword(getValues().skill) && e.preventDefault();
                                   }}
                                   onChange={onInputChange}
                                 />
                                 <button type="button" onClick={() => onAddKeyword(getValues().skill)} className="orbit-button primary" disabled={invalidSkill}>
                                   Add
                                 </button>
                               </div>
             
                               {keywords && (
                                 <div className="skill-list">
                                   {keywords.map((k, index) => (
                                     <div
                                       onClick={() => {
                                         skillsLock = true;
                                         setKeywords(
                                           keywords.filter(word => {
                                             return word !== k;
                                           })
                                         );
                                       }}
                                       key={index}
                                       className="skill"
                                     >
                                       {k.trim()}
                                     </div>
                                   ))}
                                 </div>
                               )}



              </div>
            </div>
          
            </>)
               
               : <div className="step-1" ><Spinner loading={true}></Spinner></div> }
          
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      {!props.embed && <LandingNavbar title="Online Interview" />}
      <div className={`interview-container${props.embed ? ' embed' : ''}`}>
        {transitions.map(transition => (
          <div key={transition.key}>
            {!resume && (
              <animated.div style={transition.props} className="step">
                <div className="orbit-interview-card">
                  <div className="orbit-interview-card-left flex-vertical">
                    <div key="step-1" className="flex-horizontal orbit-interview-step active m-t-40">
                      <i /> Step 1 - Upload Your Resume
                    </div>
                    <div key="step-2" className="flex-horizontal orbit-interview-step">
                      <i /> Step 2 - Confirm Details
                    </div>
                    <div key="step-3" className="flex-horizontal orbit-interview-step">
                      <i /> Step 3 - Video Interview
                    </div>

                    <Button key="continue-button" primary disabled={uploadingResume || !resumeFile} onClick={uploadResume}>
                      Continue
                    </Button>
                  </div>
                  <div
                    className={`orbit-interview-card-right${draggedOver ? ' dragged-over' : ''} orbit-dropbox flex-vertical flex-center`}
                    onDragEnter={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDraggedOver(true);
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDraggedOver(true);
                    }}
                    onDragLeave={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDraggedOver(false);
                    }}
                    onDrop={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDraggedOver(false);
                      if (e.dataTransfer && e.dataTransfer.files) {
                        setResumeFile(e.dataTransfer.files);
                        let inp = document.getElementById('customFileLang');
                        if (inp) {
                          inp.focus();
                          inp.blur();
                        }
                      }
                    }}
                  >
                    <div>
                      <div
                        className={`flex-horizontal flex-center justify-between orbit-dropbox-inner${draggedOver ? ' dragged-over' : ''}`}
                      >
                        <Icon name="upload" />

                        <p className="text-center">Drop file here to upload or</p>

                        <input
                          type="file"
                          id="customFileLang"
                          name="resume"
                          lang="en"
                          accept=".pdf,.docx"
                          onChange={e => {
                            setResumeFile(e.target.files);
                            e.target.blur();
                          }}
                        />
                        <label htmlFor="customFileLang" className="orbit-button green">
                          Browse
                        </label>
                      </div>
                      {resumeFile && (
                        <div className="flex-horizontal upload-row">
                          <p className={`status-text${uploadLoading || uploadingResume ? ' active' : ''}`}>{GetUploadStatusText()}</p>
                          <p className="file-name">{ConcatFileName(resumeFile[0].name)}</p>
                          <div
                            style={{
                              //@ts-ignore
                              '--progress': `${250 * uploadProgress}px`
                            }}
                            className={`upload-bar${uploadLoading || uploadingResume ? ' active' : ''}`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </animated.div>
            )}

            {resume && !confirmResumeDetails && (
              <animated.div style={transition.props} className="step">
                {user && user.uid && <CandidateForm></CandidateForm>}
              </animated.div>
            )}

            {resume && confirmResumeDetails && !confirmStartInterview && (
              <animated.div style={transition.props} className="step">
                <div className="orbit-interview-card">
                  <div className="orbit-interview-card-left flex-vertical">
                    <div key="step-1" className="flex-horizontal orbit-interview-step done m-t-40">
                      <Icon name="check" /> Step 1 - Upload Your Resume
                    </div>
                    <div key="step-2" className="flex-horizontal orbit-interview-step done">
                      <Icon name="check" /> Step 2 - Confirm Details
                    </div>
                    <div key="step-3" className="flex-horizontal orbit-interview-step active">
                      <i /> Step 3 - Video Interview
                    </div>

                    <div className="begin-interview-text">
                      <p>Get a head start on the other candidates and complete a video interview.</p>
                      <p>
                        <strong className="font-w-500">Not ready for an interview?</strong> No problem. Bookmark this page and come back
                        later to complete.
                      </p>
                    </div>
                    <Button
                      key="continue-button"
                      primary
                      onClick={() => {
                        debugger
                        setConfirmStartInterview(true);
                        setSlideIndex(3);
                        setAskForMediastream(true);
                      }}
                    >
                      Start Video Interview
                    </Button>
                  </div>
                  <div className={`orbit-interview-card-right begin-interview flex-vertical flex-center`}>
                    <img src={require('assets/img/landing/astronaut.svg')} />
                  </div>
                </div>
              </animated.div>
            )}

            {resume && confirmResumeDetails && confirmStartInterview && !interviewComplete && (
              <>
                {props.setDisplayAuthHeader(false)}

                <animated.div style={transition.props} className="step">
                  <div className={`flex-vertical flex-center question-card ${recording ? 'recording' : ''}`}>
                    <div className={!recording ? 'show fade-in' : 'hidden'}>
                      <div className="flex-vertical flex-center">
                        {!deniedPermissions && !devicesNotFound && !uploadLoading && <h4 className="question-number">Question #{props.questionIndex + 1}</h4>}

                        {!deniedPermissions && !devicesNotFound && !uploadLoading && (question!.type === 'text' || !question!.type) && (
                          <h1 className="question-text">{question!.text}</h1>
                        )}
                        {!deniedPermissions && !devicesNotFound && !uploadLoading && question!.type === 'video' && (
                          <video className="video" autoPlay controls onEnded={OnQuestionVideoEnded}>
                            <source src={question!.videoUrl ? question!.videoUrl : question!.text} type={question!.mimeType} />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        <Spinner loading={uploadLoading} />

            

                        {!deniedPermissions && !devicesNotFound && !recording && !upload && !uploadLoading && responseEnabled && (
                          <Button onClick={() => setRecording(true)} className="begin-recording" primary>
                            Begin Recording
                          </Button>
                        )}

                        {devicesNotFound && <EmptyNotice message={errorMessage} />}
                        {deniedPermissions && <EmptyNotice message={errorMessage} />}
                      </div>
                    </div>
                    {displayRecorder && 
                      <div className={`record-container ${recording ? 'show fade-in' : 'hidden'}`}>
                        <InterviewRecorder 
                          onRecordingDone={onRecordingDone} 
                          record={recording} 
                          upload={upload} 
                          onDevicesNotFound={setDevicesNotFound}
                          setErrorMessage={setErrorMessage}
                          mediaStream={mediaStream}
                          setMediaStream={setMediaStream}
                          forceFallback={forceFallback}
                          deniedPermissions={deniedPermissions} />
                      </div>}
                  </div>
                </animated.div>
              </>
            )}
            {resume && confirmResumeDetails && confirmStartInterview && interviewComplete && (
              <animated.div style={transition.props} className="step">
                <div className={`flex-vertical flex-center question-card flex-vertical flex-center`}>
                  <h4 className="question-number finished">Your Interview Is Complete</h4>

                  <h1 className="question-text finished">
                    Thank you {name || ''} for taking the time to complete your video interview. We will be in touch with you if you are
                    shortlisted.
                  </h1>

                  <Button onClick={() => props.history.push('/')} className="begin-recording" primary>
                    End Your Interview
                  </Button>
                  <img id="antenna" src={require('assets/img/landing/antenna.svg')} />
                  <img id="planet" src={require('assets/img/landing/planet.svg')} />
                </div>
              </animated.div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export const InterviewPage = (props: IInterviewProps) => {
  const organization = useFirestoreDoc<IOrganization>(`Organizations/${props.match.params.org}`);
  const placement = useFirestoreDoc<IPlacement>(`Organizations/${props.match.params.org}/Placements/${props.match.params.placement}`);
  const [displayAuthHeader, setDisplayAuthHeader] = useState<boolean>(true);
  const [hideInterviewAnswerProgress, setHideInterviewAnswerProgress] = useState<boolean>(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  if (!organization || !placement) {
    return <div />;
  }

  return (
    <>
      { displayAuthHeader ? <AuthHeader title={placement.title} lead={organization.name} /> :
       !displayAuthHeader && !hideInterviewAnswerProgress ? 
        <InterviewAnswerProgress questionIndex={questionIndex} setQuestionIndex={setQuestionIndex} totalquestions={placement.questions.length} /> :
        <AuthHeader />
      }
      <br />
      <Interview 
        {...props} 
        setDisplayAuthHeader={setDisplayAuthHeader} 
        questionIndex={questionIndex} 
        setQuestionIndex={setQuestionIndex} 
        setHideInterviewAnswerProgress={setHideInterviewAnswerProgress} />
    </>
  );
};

export const InterviewEmbed = (props: IInterviewProps) => {
  return <Interview embed {...props} />;
};
