import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import InterviewRecorder from '../../components/InterviewRecorder';
import firebase from 'firebase/app';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { IPlacement, IInterviewQuestionResponse } from '../../models/Interview.models';
import { WithID } from '../../models/Helpers.models';

interface IPlacementInterviewProps extends RouteComponentProps<{ id: string; question: string }> {}

interface IPlacementInterviewState {
  record: boolean;
  upload: boolean;

  orgId: string;

  interview?: WithID<IPlacement>;
  badInterview?: boolean;

  response?: WithID<IInterviewQuestionResponse>;
}

export default class PlacementInterview extends React.Component<IPlacementInterviewProps, IPlacementInterviewState> {
  private userSub!: () => void;
  private orgSub!: () => void;
  private questionSub!: () => void;
  private responseSub!: () => void;

  constructor(props: IPlacementInterviewProps) {
    super(props);

    this.state = {
      record: false,
      upload: false,
      orgId: '',
      interview: undefined,
      badInterview: false
    };
  }

  componentDidMount() {
    this.userSub = firebase.auth().onAuthStateChanged(user => {
      if (this.orgSub) {
        this.orgSub();
      }
      if (this.questionSub) {
        this.questionSub();
      }

      if (user) {
        this.orgSub = firebase
          .firestore()
          .doc(`OrganizationUsers/${user.uid}`)
          .onSnapshot(snap => {
            const orgId = snap.exists ? snap.data()!.organizationId : '';
            this.setState({ orgId });

            if (this.questionSub) {
              this.questionSub();
            }
            if (orgId) {
              this.questionSub = firebase
                .firestore()
                .doc(`Organizations/${orgId}/Placements/${this.props.match.params.id}`)
                .onSnapshot(interview => {
                  if (interview.exists) {
                    this.setState({ interview: { ...(interview.data()! as IPlacement), id: interview.id } });
                  } else {
                    this.setState({ interview: undefined, badInterview: true });
                  }
                });
            }
          });
      }
    });
  }

  componentDidUpdate(prevProps: IPlacementInterviewProps) {
    if (this.responseSub) {
      this.responseSub();
    }

    if (this.state.orgId) {
      const placementId = this.props.match.params.id;
      const userId = this.getUserId();
      const n = this.props.match.params.question;

      this.responseSub = firebase
        .firestore()
        .doc(`Organizations/${this.state.orgId}/Candidates/${userId}/Placements/${placementId}/Responses/${n}`)
        .onSnapshot(response => {
          if (response.exists) {
            this.setState({ response: { ...(response.data()! as IInterviewQuestionResponse), id: response.id } });
          } else {
            this.setState({ response: undefined });
          }
        });
    }
  }

  componentWillUnmount() {
    if (this.userSub) {
      this.userSub();
    }
    if (this.orgSub) {
      this.orgSub();
    }
    if (this.questionSub) {
      this.questionSub();
    }
    if (this.responseSub) {
      this.responseSub();
    }
  }

  private getUserId() {
    return firebase.auth().currentUser!.uid;
  }

  private onRecordingDone = async (blob: Blob, timestamp: string, duration?: number) => {
    console.log('Uploading...');

    const placementId = this.props.match.params.id;
    const userId = this.getUserId();
    const n = this.props.match.params.question;

    const extension = blob.type === 'video/quicktime' ? 'mov' : 'webm';

    const r = firebase.storage().ref('interviews/' + placementId + '/' + userId + '/' + n + '.' + extension);
    await r.put(blob, {
      contentType: blob.type,
      customMetadata: {
        userId: userId,
        placementId: placementId,
        question: n,
        orgId: this.state.orgId,
        timestamp
      }
    });
    const videoUrl = await r.getDownloadURL();

    await firebase
      .firestore()
      .doc(`Organizations/${this.state.orgId}/Candidates/${userId}/Placements/${placementId}/Responses/${n}`)
      .set(
        {
          completed: true,
          videoUrl,
          videoMime: blob.type,
          duration,
          score: 0
        },
        { merge: true }
      );

    console.log('Uploaded.');
    this.setState({ upload: false });
  };

  getQuestionNum() {
    return (parseInt(this.props.match.params.question, 10) || 0) + 1;
  }

  toggleRecording = () => {
    if (!this.state.record) {
      this.setState({ record: true });
    } else {
      this.setState({ record: false, upload: true });
    }
  };

  hasPrevious = (): boolean => {
    return this.getQuestionNum() > 1;
  };

  hasNext = (): boolean => {
    if (!this.state.interview) {
      return true;
    }

    return this.getQuestionNum() - 1 < this.state.interview.questions.length - 1;
  };

  goToNext = () => {
    this.props.history.push(`/admin/interviews/${this.props.match.params.id}/${this.getQuestionNum()}`);
  };

  goToPrev = () => {
    this.props.history.push(`/admin/interviews/${this.props.match.params.id}/${this.getQuestionNum() - 2}`);
  };

  render() {
    if (this.state.badInterview) {
      return <Redirect to="/admin/interviews" />;
    }

    return (
      <Container>
        <Row>
          <Col xs="3">
            <Card>
              <CardBody>
                <CardTitle>Question {this.getQuestionNum()}</CardTitle>
              </CardBody>
              <CardBody>
                <CardText>{this.state.interview ? this.state.interview.questions[this.getQuestionNum() - 1].text : ''}</CardText>
                <Button
                  color={this.state.record ? 'danger' : this.state.response && this.state.response.completed ? 'success' : 'secondary'}
                  block
                  onClick={this.toggleRecording}
                  disabled={this.state.upload}
                >
                  {this.state.record
                    ? 'Recording...'
                    : this.state.upload
                    ? 'Uploading...'
                    : this.state.response && this.state.response.completed
                    ? 'Done!'
                    : 'Record'}
                </Button>
                <Button color="primary" block onClick={this.goToNext} disabled={!this.hasNext() || this.state.upload}>
                  {this.hasNext() ? 'Next' : 'Submit!'}
                </Button>
                {this.hasPrevious() ? (
                  <Button color="primary" block onClick={this.goToPrev} disabled={!this.hasPrevious() || this.state.upload}>
                    Back
                  </Button>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col xs="9">
            <InterviewRecorder onRecordingDone={this.onRecordingDone} record={this.state.record} upload={this.state.upload} />
          </Col>
        </Row>
      </Container>
    );
  }
}
