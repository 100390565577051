import React from 'react';

interface IIconProps extends React.ClassAttributes<React.Component> {
  name: string;
  onClick?: () => any;
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { name, className, onClick } = props;
  return <img onClick={onClick} className={`orbit-icon ${className || ''}`} src={require(`assets/img/icons/${name}.svg`)} />;
};

export default Icon;
