import * as firebase from 'firebase/app';

const hash = (s: string) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export class Reporter {
  public static error = async (text: string, error?: Error, source?: string, lineno?: number, colno?: number) => {
    console.error(text);
    if (error) {
      console.error(error);
    }

    const key = hash((error && error.stack) || text);

    const existingRef = firebase.firestore().doc(`Errors/${key}`);
    const existing = await existingRef.get();
    const now = new firebase.firestore.Timestamp(Math.floor(new Date().getTime() / 1000), 0);

    if (existing.exists) {
      return existingRef.update({ count: existing.data()!.count + 1, recentOccurrence: now, lastUserAgent: navigator.userAgent });
    } else {
      return existingRef.set({
        firstOccurrence: now,
        recentOccurrence: now,
        text,
        errorStack: error ? error.stack : '',
        errorMessage: error ? error.message : '',
        errorName: error ? error.name : '',
        lastUserAgent: navigator.userAgent,
        count: 1
      });
    }
  };
}
