/*eslint-disable*/
import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import AuthFooter from '../../components/Landing/LandingFooter';
import Navbar from '../../components/Landing/LandingNavbar';
import '../../assets/scss/custom/landing.scss';
import Icon from '../../components/General/Icon';
import Button from '../../components/General/Buttons';
import firebase from 'firebase';

class Index extends React.Component<RouteComponentProps> {
	render() {

		const analytics = firebase.analytics();
		analytics.logEvent('home_main_view');

		return (
			<>
				<Navbar title="" transparent />

				<div className="call-to-action">
					<div className="flex-container  fade-in-fast">
						<div className="flex-vertical">
							<h2 className="large-text text-white">Stars Are</h2>
							<h2 className="large-text text-white">Worth Orbiting</h2>
							<p className="medium-text text-white">Simplify your ability to find them.</p>

							<div className="flex-horizontal m-t-75">
								<Link to="/auth/demo" onClick={() => analytics.logEvent('home_start_for_free_click')}>
									<Button className="m-l-0" primary>
										Request a Demo
									</Button>
								</Link>
								{/* <Button green>Get Orbit Basic</Button> */}
							</div>
						</div>
					</div>
					<img className="fade-in-fast" id="phone" src={require('assets/img/call-to-action/Header-iPhone4.png')} />
					<img className="fade-in-fast" id="laptop" src={require('assets/img/call-to-action/LapTop-Video.png')} />
					<div className="fill-1" />
					<div className="fill-2" />
					<div className="dots" />
				</div>

				<div className="pad-150 bg-white hiring-process">
					<div className="flex-horizontal justify-around bg-white text-center flex-center">
						<h1 className="what-can-we-do">What Can Orbit Do for Your Hiring Process?</h1>
						<p className="we-can">
							Finding great talent is difficult and time-consuming. Companies spend months recruiting, screening and interviewing
							candidates. Orbit handles most of this for you and presents candidates based on proven matching techniques.
						</p>
					</div>

					<div className="flex-container flex-vertical getting-started-container bg-white">
						<h1 className="getting-started">Getting started is easy and best of all, it's FREE!</h1>
						<div className="flex-horizontal flex-center justify-around icon-features">
							<div className="flex-vertical flex-center icon-feature">
								<Icon name="account" className="icon-size" />
								<p className="icon-text">Register for an account</p>
							</div>
							<div className="flex-vertical flex-center icon-feature">
								<Icon name="automated" className="icon-size" />
								<p className="icon-text">Set Up an Interview</p>
							</div>
							<div className="flex-vertical flex-center icon-feature">
								<Icon name="best" className="icon-size" />
								<p className="icon-text">Automated Interview Process</p>
							</div>
							<div className="flex-vertical flex-center icon-feature">
								<Icon name="interview" className="icon-size" />
								<p className="icon-text">Shortlist Best Candidates </p>
							</div>
						</div>
					</div>
				</div>

				<div className="revolutionize-container">
					<div className="flex-vertical flex-center">
						<p>Revolutionize the way you find new talent for your business.</p>
						<Link to="/auth/demo">
							<Button className="m-t-30" primary onClick={() => analytics.logEvent('home_start_for_free_mid_click')}>
								Request a Demo
							</Button>
						</Link>
					</div>
				</div>

				<div className="flex-horizontal justify-between the-future the-future-desktop">
					<div className="flex-vertical">
						<h1>The Future of Interviewing</h1>
						<p>
							Orbit’s Interview A.I. is a futuristic approach to simplifying the hiring process. Through your personalized Orbit portal,
							your A.I. hiring assistant will conduct interviews and present you with the best applicants based on your requested needs.
							Orbit’s Interview A.I. is powered by machine learning, natural language processing, internal matching algorithms, and aptitude
							assessment tools -- allowing your hiring skills to be maximized at a more critical juncture in the selection proceedings after
							your hiring assistant has conducted initial interviews.
						</p>
					</div>
					<img id="UI" src={require('assets/img/landing/UI.png')} />
				</div>

				<div className="flex-horizontal justify-between the-future the-future-tablet">
					<div className="flex-vertical">
						<h1>The Future of Interviewing</h1>
						<img id="UI" src={require('assets/img/landing/UI.png')} />
						<p>
							Orbit’s Interview A.I. is a futuristic approach to simplifying the hiring process. Through your personalized Orbit portal,
							your A.I. hiring assistant will conduct interviews and present you with the best applicants based on your requested needs.
							Orbit’s Interview A.I. is powered by machine learning, natural language processing, internal matching algorithms, and aptitude
							assessment tools -- allowing your hiring skills to be maximized at a more critical juncture in the selection proceedings after
							your hiring assistant has conducted initial interviews.
						</p>
					</div>
				</div>

				<div className="flex-vertical call-to-action final">
					<div className="flex-container">
						<div className="flex-vertical flex-center">
							<h2 className="large-text text-white call-to-action-final-text">
								Your time is meaningful, the hiring process is a necessity, Orbit’s Interview A.I. makes sifting through the dark and
								finding stars that much easier.{' '}
							</h2>
							<div className="flex-horizontal flex-center m-t-75">
								<Link to="/auth/demo">
									<Button className="m-l-0" primary onClick={() => analytics.logEvent('home_start_for_free_bottom_click')}>
										Request a Demo
									</Button>
								</Link>
								{/* <Button green>Get Orbit Basic</Button> */}
							</div>
						</div>
					</div>

					<div className="fill-1" />
					<div className="fill-2" />
					<div className="dots" />
				</div>
				<AuthFooter {...this.props} />
			</>
		);
	}
}

export default Index;
