// import Activity from './views/Dash/Activity';
import Pricing from './views/Landing/Pricing';
import Placements from './views/Dash/Placements';
import Login from './views/Landing/Login';
import Privacy from './views/Landing/Privacy';
import Register from './views/Landing/Register';
import Demo from './views/Landing/Demo';
import ViewCandidate from './views/Dash/CandidateDetails'
// import Candidate from './views/Dash/CandidateDetailsOld';
import Team from './views/Dash/Team';
import Candidates from './views/Dash/Candidates';
import PlacementInterview from './views/Dash/PlacementInterview';
import Dashboard from './views/Dash/Dashboard';
import { ForgotPassword } from './views/Landing/ForgotPassword';

export interface IRoute {
	path: string;
	name: string;
	icon?: string;
	component?: any;
	state?: string;
	hideInNav?: boolean;
	collapse?: boolean;
	views?: IRoute[];
	redirect?: boolean;
	to?: string;
	exact?: boolean;
}

const routes: IRoute[] = [
	{
		path: '/admin/dashboard',
		name: 'Dashboard',
		icon: 'activity',
		component: Dashboard
	},
	{
		path: '/admin/interviews',
		name: 'Interviews',
		state: 'interviewsCollapse',
		icon: 'interviews',
		component: Placements,
		exact: true
	},
	{
		name: 'Preview Interview',
		path: '/admin/interviews/:id',
		redirect: true,
		exact: true,
		to: '/admin/interviews/:id/0',
		hideInNav: true
	},
	{
		name: 'Preview Interview',
		path: '/admin/interviews/:id/:question',
		component: PlacementInterview,
		hideInNav: true
	},
	{
		name: 'Candidates',
		path: '/admin/candidates',
		state: 'candidatesCollapse',
		component: Candidates,
		icon: 'candidates',
		exact: true
	},
	{
		name: 'View Candidate',
		path: '/admin/candidates/:id/:placementId',
		component: ViewCandidate,
		hideInNav: true
	},
	{
		name: 'Team',
		path: '/admin/team',
		state: 'teamCollapse',
		component: Team,
		icon: 'team'
	},
	{
		path: '/auth/pricing',
		name: 'Pricing',
		hideInNav: true,
		component: Pricing
	},
	{
		path: '/auth/login',
		name: 'Login',
		hideInNav: true,
		component: Login
	},
	{
		path: '/auth/register',
		name: 'Register',
		hideInNav: true,
		component: Register
	},
	{
		path: '/auth/demo',
		name: 'Demo',
		hideInNav: true,
		component: Demo
	},
	{
		path: '/auth/privacy',
		name: 'Privacy',
		hideInNav: true,
		component: Privacy
	},
	{
		path: '/auth/forgot-password',
		name: 'Forgot Password',
		hideInNav: true,
		component: ForgotPassword
	}
];

export default routes;
