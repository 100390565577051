import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';
import { injectStripe } from 'react-stripe-elements';
import { ReactStripeElements } from 'react-stripe-elements';
import { useUser } from '../../util/firebase';
import { API_BASE } from '../../config/config';

interface IDowngradeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DowngradeModal = (props: IDowngradeModalProps & ReactStripeElements.InjectedStripeProps) => {
  const [user] = useUser();
  const [submitting, setSubmitting] = React.useState(false);

  const closeBtn = (
    <button className="close" onClick={() => props.setOpen(false)}>
      &times;
    </button>
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitting(true);

    const idToken = await user!.getIdToken();

    const resp = await fetch(`${API_BASE}/stripe/subscription`, {
      method: 'DELETE',
      headers: { 'content-type': 'application/json', authorization: 'Bearer ' + idToken }
    });

    if (resp.status === 204) {
      props.setOpen(false);
    } else {
      console.error(resp);
    }

    setSubmitting(false);
  };

  return (
    <Modal size="sm" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={() => props.setOpen(!props.open)} close={closeBtn}>
          Downgrade
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to downgrade?</p>
        </ModalBody>
        <ModalFooter>
          {user ? (
            <Button color="danger" type="submit" disabled={submitting}>
              Confirm
            </Button>
          ) : null}{' '}
          <Button color="secondary" onClick={() => props.setOpen(!props.open)} disabled={submitting}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default injectStripe(DowngradeModal);
