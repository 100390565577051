import * as React from 'react';
import * as ReactDOM from 'react-dom';
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
// import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
//import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
//import "assets/vendor/select2/dist/css/select2.min.css";
//import "assets/vendor/quill/dist/quill.core.css";
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0';
import 'assets/scss/orbit.scss?v0.0.1';

import DashLayout from './layouts/Dash';
import LandingLayout from './layouts/Landing';
import InterviewLayout from './layouts/Interview';
import LandingView from './views/Landing/Index';
import { useUser } from './util/firebase';
import Loading from 'react-fullscreen-loading';
import Blog from './layouts/Blog';
import { Reporter } from './util/errors';
import { InterviewEmbed } from './views/Landing/Interview';
import { LandingInterviews } from './views/Landing/Interviews';
import ScrollToTop from './components/General/ScrollToTop';

const Loader = <Loading loading background="rgb(131, 83, 225)" loaderColor="#FFFFFF" />;

const App = () => {
  const [user, userLoaded] = useUser();

  if (!userLoaded) {
    return Loader;
  }

  return (
    <Switch>
      {user && !user.isAnonymous ? <Route path="/admin" component={DashLayout} /> : undefined}
      <Route path="/auth" component={LandingLayout} />
      <Route path="/blog" component={Blog} />
      <Route path="/interview-embed/:org/:placement" component={InterviewEmbed} />
      <Route path="/interviews" component={LandingInterviews} />
      <Route path="/interview" component={InterviewLayout} />
      <Route path="/" component={LandingView} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <React.Suspense fallback={Loader}>
      <App />
    </React.Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

window.onerror = (message, source, line, col, error) => {
  Reporter.error(message as string, error, source, line, col);
};
