import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Form, FormGroup, Input, Spinner, Container, Row, Col } from 'reactstrap';
import { useOrganization, useFirestoreCollection } from '../../util/firebase';
import firebase from 'firebase/app';
import { IUser, UserRoleToString } from '../../models/User.models';
import { useForm } from 'react-hook-form';
import { WithID } from '../../models/Helpers.models';
import * as yup from 'yup';
import { FormError } from '../../components/FormError';
import Icon from '../../components/General/Icon';
import Button from '../../components/General/Buttons';
import { ConfirmModal, default as Modal } from '../../components/General/Modal';

interface IEditTeamMemberModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedTeamMember: WithID<IUser> | undefined;
  orgId: string;
}

const editUserFormValidator = yup.object<any>({
  name: yup
    .string()
    .required('Please enter a name.')
    .min(3, 'Name must be at least 3 characters long.'),
  email: yup
    .string()
    .required('Please enter an email.')
    .email('Please enter a valid email.'),
  department: yup.mixed().notRequired(),
  role: yup
    .mixed()
    .required('Please enter a role.')
    .oneOf(['admin', 'reviewer', 'editor'], 'Please enter a valid role.')
});

const EditTeamMemberModal = (props: IEditTeamMemberModalProps) => {
  const { register, handleSubmit, errors, setError, clearError } = useForm<IUser>({
    defaultValues: props.selectedTeamMember,
    validationSchema: editUserFormValidator
  });
  const [avatar, setAvatar] = useState<File>();
  const [savingTeamMember, setSavingTeamMember] = useState(false);

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0];

    if (image) {
      if(image.type.includes('image')){
        setAvatar(image);
        clearError('avatar')
      }
      else {
        setError('avatar', 'SingleFieldError' ,'Invalid file. Only images are supported');
        setAvatar(undefined)
      }
    }
  };

  const onTeamMemberSave = async (data: IUser) => {
    clearError('avatar');
    if(errors && (errors.name || errors.email  || errors.department || errors.role)) return;
    
    setSavingTeamMember(true);
    if (avatar) {
      var storageRef = firebase.storage().ref();
      const storagePath = `images/${avatar.name}`;
      await storageRef.child(storagePath).put(avatar);

      data.avatar = await storageRef.child(storagePath).getDownloadURL();
    }
    const teamRef = firebase.firestore().collection(`Organizations/${props.orgId}/Team`);

    if (props.selectedTeamMember) {
      await teamRef.doc(props.selectedTeamMember.id).set(data, { merge: true });
    } else {
      await teamRef.add(data);
    }

    setSavingTeamMember(false);
    props.setOpen(false);
    setAvatar(undefined);
  };

  return (
    <Modal
      submitProps={{ disabled: savingTeamMember }}
      onSubmit={handleSubmit(onTeamMemberSave)}
      customSubmitText="Save"
      title={`${props.selectedTeamMember ? 'Edit' : 'Create'} Team Member`}
      open={props.open}
      onClose={() => {
        clearError('avatar');
        props.setOpen(false);
      }}
    >
      <Form onSubmit={handleSubmit(onTeamMemberSave)}>
        <FormGroup>
          <label className="form-control-label" htmlFor="text">
            Name
          </label>

          <Input
            id="name"
            name="name"
            rows="8"
            innerRef={register({ required: true })}
            placeholder="Enter a name"
            type="text"
            defaultValue={props.selectedTeamMember ? props.selectedTeamMember.name : ''}
          />
          {errors.name && <FormError>{errors.name.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="text">
            Email
          </label>

          <Input
            id="email"
            name="email"
            rows="8"
            innerRef={register({ required: true })}
            placeholder="Enter an email"
            type="email"
            defaultValue={props.selectedTeamMember ? props.selectedTeamMember.email : ''}
          />
          {errors.email && <FormError>{errors.email.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="text">
            Department
          </label>

          <Input
            id="department"
            name="department"
            rows="8"
            innerRef={register()}
            placeholder="Enter a department"
            type="text"
            defaultValue={props.selectedTeamMember ? props.selectedTeamMember.department : ''}
          />
          {errors.department && <FormError>{errors.department.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="exampleFormControlSelect1">
            Role
          </label>
          <Input
            innerRef={register({ required: true })}
            id="role"
            name="role"
            type="select"
            defaultValue={props.selectedTeamMember ? props.selectedTeamMember.role : 'reviewer'}
          >
            <option value="reviewer">Reviewer</option>
            <option value="editor">Editor</option>
            <option value="admin">Admin</option>
          </Input>
          {errors.role && <FormError>{errors.role.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="exampleFormControlSelect1" >
            Avatar
          </label>
          <div className="custom-file">
            <input className="custom-file-input" id="customFileLang" onChange={handleAvatarChange} lang="en" type="file" accept="image/*" />
            <label className="custom-file-label" htmlFor="customFileLang">
              {avatar ? avatar.name : 'Select file'}
            </label>
          </div>
          {errors.avatar && <FormError>{errors.avatar.message}</FormError>}
        </FormGroup>
      </Form>
    </Modal>
  );
};

interface IHeaderProps {
  open: () => void;
}

const Header = (props: IHeaderProps) => {
  return (
    <div className="activity-header">
      <h6 className="activity-title">Team</h6>
      <div className="button-container flex-horizontal justify-between">
        <Button primary onClick={props.open}>
          <Icon name="plus" />
          Invite Team Member
        </Button>
      </div>
    </div>
  );
};

function Team() {
  const [editMemberParams, setEditMemberParams] = useState<{ member: WithID<IUser> | undefined; open: boolean }>({
    member: undefined,
    open: false
  });

  const [confirmOpen, setConfirmOpen] = useState(false);

  const orgId = useOrganization();

  const [teamList, loadedTeamList] = useFirestoreCollection<IUser>(`Organizations/${orgId}/Team`);
  const [teamView, setTeamView] = useState(<></>);

  useEffect(() => {
    if(loadedTeamList && teamList && teamList.length){
      let innerTeamVIew = (
        <Container>
          <Row>
            {teamList.map((teamMember, i) => (
              <Col className="col-xxxl-1 col-xxl-2" xl="3" lg="4" md="6" sm="12" key={i}>
                <div className="contact-details contact-card-margin">
                  <div className="contact-card">
                    <div className="contact-inner">
                      <div className="contact">
                        <div className="contact-photo" style={{ backgroundImage: `url(${teamMember.avatar})` }} />
                        <div className="contact-text">
                          <h3 className="name">{teamMember.name}</h3>
                          {/* <div className="team-email">{teamMember.email}</div> */}
                        </div>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="btn-neutral" color="default" >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>

                            <DropdownMenu right>
                              <DropdownItem
                                onClick={e => {
                                  setEditMemberParams({ member: teamMember, open: true });
                                }}
                              >
                                <span>Edit</span>
                              </DropdownItem>

                              <DropdownItem
                                onClick={e => {
                                  setEditMemberParams({ member: teamMember, open: false });
                                  setConfirmOpen(true);
                                }}
                              >
                                <span>Delete</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                      </div>
          
                      <div className="contact-card-divider" />
          
                      <div className="contact-summary">
                        <div className="position">
                          <p>Department</p>
                          <p>{teamMember.department || 'NA'}</p>
                        </div>
                        <div className="position">
                          <p>Role</p>
                          <p><span className="fg-purple-darker mr-1">●</span>{UserRoleToString(teamMember.role)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )
  
      setTeamView(innerTeamVIew);
    }else {
      setTeamView(<></>)
    }
  }, [teamList, loadedTeamList]);

  return (
    <>
      <div className="activity-container flex-vertical dash-content">
        <Header open={() => setEditMemberParams({ member: undefined, open: true })} />

        <ConfirmModal
          open={confirmOpen}
          title="Are you sure?"
          onClose={() => setConfirmOpen(false)}
          onSubmit={async () => {
            await firebase
              .firestore()
              .doc(`Organizations/${orgId}/Team/${editMemberParams!.member!.id}`)
              .delete();
              setConfirmOpen(false)
          }}
        >
          Delete Team Member {editMemberParams && editMemberParams.member ? editMemberParams!.member!.name : ''}?
        </ConfirmModal>
        <div className="feed-container">
          {!loadedTeamList && (
            <div className="cards-spinner">
              <Spinner color="primary" />
            </div>
          )}
          <div className="item-list">
            {teamView}
          </div>
        </div>
      </div>
      <EditTeamMemberModal
        open={editMemberParams.open}
        setOpen={(open: boolean) => setEditMemberParams({ member: editMemberParams.member, open })}
        orgId={orgId}
        selectedTeamMember={editMemberParams.member}
      />
    </>
  );
}

export default Team;
