import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Button, ModalFooter } from 'reactstrap';
import { FormError } from '../FormError';
import { useUser} from '../../util/firebase';

interface IAccountModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface IAccountPasswordForm {
  password: string;
  confirmPassword: string;
}

const editAccountPasswordFormValidator = yup.object<IAccountPasswordForm>({
  password: yup
    .string()
    .required('Please enter a password.')
    .min(6, 'Password must be at least 6 characters.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const AccountModal = (props: IAccountModalProps) => {
  const initAccountPasswordState = {
    password: '',
    confirmPassword: ''
  }

  const [accountPassword] = React.useState(initAccountPasswordState);
  const [serverError, setServerError] = React.useState('');
  const { register, handleSubmit, errors } = useForm<IAccountPasswordForm>({ defaultValues: accountPassword, validationSchema: editAccountPasswordFormValidator });
  const user = useUser()[0];

  const onAccountPasswordSave = async (data: IAccountPasswordForm) => {
    if(errors.password || errors.confirmPassword) return;

    if(user)
      user.updatePassword(data.password).then(() => {
        setServerError('');
        toggle();
      }).catch((err) => {
        setServerError(err.message);
      });
  }

  const toggle = () => props.setOpen(!props.open);

  return (
    <Modal size="lg" isOpen={props.open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Update Account Details
      </ModalHeader>
      <ModalBody>
      <Form onSubmit={handleSubmit(onAccountPasswordSave)}>
          <FormGroup>
              <label className="form-control-label" htmlFor="password">
                Password
              </label>

              <Input
                id="password"
                name="password"
                rows="8"
                innerRef={register({ required: true })}
                placeholder="Password"
                type="password"
              />
            </FormGroup>
            {errors.password && <FormError>{errors.password.message}</FormError>}

            <FormGroup>
              <label className="form-control-label" htmlFor="text">
                Confirm Password
              </label>

              <Input
                id="confirmPassword"
                name="confirmPassword"
                rows="8"
                innerRef={register({ required: true })}
                placeholder="Confirm password"
                type="password"
              />
            </FormGroup>
            {errors.confirmPassword && <FormError>{errors.confirmPassword.message}</FormError>}
            <Button color="primary" onClick={handleSubmit(onAccountPasswordSave)}>Update Password</Button>
            {serverError && <FormError>{serverError}</FormError>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}