//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardTitle, CardText, CardImg, Container, Row, Col, Spinner } from 'reactstrap';
import LandingFooter from '../components/Landing/LandingFooter';
import Navbar from '../components/Landing/LandingNavbar';
import { RouteComponentProps } from 'react-router';
import AuthHeader from '../components/Landing/LandingHeaderMinimal';
import { API_BASE } from '../config/config';

interface IBlogProps extends RouteComponentProps {}

interface IBlogEntry {
  title: string;
  body: string;
  date: string;
  link: string;
}

export const Blog = (props: IBlogProps) => {
  const [blogs, setBlogs] = useState<IBlogEntry[]>([]);

  useEffect(() => {
    let closed = false;
    fetch(API_BASE + "/blog")
      .then(resp => resp.json())
      .then((body: IBlogEntry[]) => {
        if (!closed) {
          setBlogs(body);
        }
      });

    return () => {
      closed = true;
    };
  });

  return (
    <>
      <div className="main-content bg-purple blog-content">
        <Navbar transparent title="" />
        <>
          <AuthHeader white title="Orbit News" lead="" />
          <br />
          <Container className="mt--8 pb-5">
            {blogs.length === 0 ? (
              <div className="flex-container flex-center">
                <Spinner color="white" />
              </div>
            ) : null}
            <Row className="card-wrapper">
              {blogs.map(blog => (
                <Col lg="4" key={blog.date}>
                  <Card>
                    <CardImg alt="..." src={require('assets/img/theme/img-1-1000x600.jpg')} top />

                    <CardBody>
                      <CardTitle className="mb-3" tag="h3">
                        {blog.title}
                      </CardTitle>
                      <CardText className="mb-4">{blog.body}</CardText>
                      <Button color="primary" href={blog.link} target="_blank" rel="noopener">
                        Read more
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </>
        <div className="fill-1" />
        <div className="fill-2" />
        <div className="dots" />
      </div>
      <LandingFooter {...props} />
    </>
  );
};

export default Blog;
