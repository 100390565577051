import React from 'react';
import '../../assets/scss/custom/landing.scss';

interface IAuthHeaderProps {
  title?: string;
  lead?: string;
  white?: Boolean;
}

class AuthHeader extends React.Component<IAuthHeaderProps> {
  render() {
    let { white } = this.props;
    return (
      <>
        <div className="flex-vertical interview-header text-center">
          {this.props.title ? <h1 className={`interview-title${white ? ' fg-white' : ''}`}>{this.props.title}</h1> : null}
          {this.props.lead ? <p className={`interview-company${white ? ' fg-white' : ''}`}>{this.props.lead}</p> : null}
        </div>
      </>
    );
  }
}

export default AuthHeader;
