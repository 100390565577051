import { ISkill } from '../models/Skills.models';
import { API_BASE } from '../config/config';

export const GetSkills = async (query: string): Promise<ISkill[]> => {
  let data: ISkill[] = [];
  try {
    const resp = await fetch(`${API_BASE}/skills?q=${query}`);
    if (resp.status !== 200) {
      throw resp;
    }

    data = await resp.json();
  } catch (err) {
    console.error(err);
    return [];
  }

  return data;
};
