import React, { useState, useRef } from 'react';
// core components
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import * as firebase from 'firebase/app';
//import { IAddress } from '../../models/User.models';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
//import { GoogleMapsResponseToAddress } from '../../util/gmaps';
import Button from '../../components/General/Buttons';

interface IDemoFormProps extends RouteComponentProps { }

interface IDemoForm {
	email: string;
	agree: boolean;
	company: any;
}

const registerFormValidator = yup.object<IDemoForm>({
	email: yup
		.string()
		.required('Please enter an email.')
		.email('Please enter a valid email.'),
	company: yup
		.string()
		.required('Please enter a company name.'),
	agree: yup
		.bool()
		.required()
		.oneOf([true], 'Please agree to the Privacy Policy.')
});

const Demo = (props: IDemoFormProps) => {
	//const [focusedName, setFocusedName] = useState<boolean>();
	const [focusedEmail, setFocusedEmail] = useState<boolean>();
	const [focusedCompany, setFocusedCompany] = useState<boolean>();

	const [Loading, SetLoading] = useState<boolean>();
	const [, setPlace] = React.useState<google.maps.places.PlaceResult>();
	//const [setParsedAddress] = React.useState<Partial<IAddress>>({});
	const form = useForm<IDemoForm>({
		validationSchema: registerFormValidator,
		submitFocusError: true,
		mode: 'onChange',
		defaultValues: {
			email: '',
		}
	});

	const notificationRef = useRef<typeof NotificationAlert>(null);

	const inputRef = useRef<HTMLInputElement>(null);

	const onSubmit = async (data: IDemoForm) => {

		try {
			await firebase
				.firestore()
				.collection(`DemoRequests`)
				.add(data);

			let options = {
				place: 'tc',
				message: (
					<div className="alert-text">
						<span className="alert-title" data-notify="title">
							{' '}
							Success
						</span>
						<span data-notify="message">Thanks for your interest in Orbit Jobs. We will get back to you ASAP.</span>
					</div>
				),
				type: 'success',
				icon: 'ni ni-bell-55',
				autoDismiss: 7
			};
			if (notificationRef.current) {
				notificationRef.current.notificationAlert(options);
			}

		} catch (error) {

			debugger;

			SetLoading(false);

			let options = {
				place: 'tc',
				message: (
					<div className="alert-text">
						<span className="alert-title" data-notify="title">
							{' '}
							Error
						</span>
						<span data-notify="message">An Error Occured</span>
					</div>
				),
				type: 'danger',
				icon: 'ni ni-bell-55',
				autoDismiss: 7
			};
			if (notificationRef.current) {
				notificationRef.current.notificationAlert(options);
			}
			return;
		}

		SetLoading(false);


	};

	const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | undefined>(undefined);

	if (inputRef.current) {
		if (!autocomplete) {
			const ac = new google.maps.places.Autocomplete(inputRef.current, {
				types: ['establishment']
			});
			setAutocomplete(ac);
			ac.addListener('place_changed', () => {
				debugger;
				setPlace(ac.getPlace());
				//setParsedAddress(GoogleMapsResponseToAddress(ac.getPlace()));
			});
		}
	}

	return (
		<>
			<div className="login-container flex-horizontal fade-in-fast">
				<div className="left-img">
					<a href="/">
						<img src={require('assets/img/brand/orbit-logo-dark.png')} />
					</a>
				</div>

				<div className="flex-vertical right-side flex-center">
					<h1>Revolutionize the way you find new talent for your business.</h1>

					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div>
							<input
								className={`login-input ${form.errors.company ? 'invalid' : ''} ${focusedCompany ? 'focused' : ''}`}
								ref={form.register}
								onFocus={() => setFocusedCompany(true)}
								onBlur={() => setFocusedCompany(false)}
								type="text"
								name="company"
								id="company"
								placeholder="Company Name"
							/>
							{form.errors.company && <>{form.errors.company}</>}
						</div>
						{/* <div>
							<input className={`login-input`} ref={inputRef} type="text" name="place" id="place" placeholder="Company Name" />
						</div> */}
						<div>
							<input
								ref={form.register}
								onFocus={() => setFocusedEmail(true)}
								onBlur={() => setFocusedEmail(false)}
								type="email"
								name="email"
								id="email"
								className={`login-input ${form.errors.email ? 'invalid' : ''} ${focusedEmail ? 'focused' : ''}`}
								placeholder="Email"
							/>
							{form.errors.email && <>{form.errors.email.message}</>}
						</div>
						<label className="checkbox">
							<input
								onChange={e => form.setValue('agree', e.target.checked, true)}
								ref={form.register}
								type="checkbox"
								name="agree"
								id="agree"
							/>
							<span>
								I agree to the <Link to="/auth/privacy">Privacy Policy</Link>
							</span>
						</label>
						<Button
							// disabled={Loading || !!Object.keys(form.errors).length || !form.formState.dirtyFields.has('agree') || !place} //Place will be optional for the momment
							disabled={Loading || !!Object.keys(form.errors).length || !form.formState.dirtyFields.has('agree')}
							type="submit"
							primary
						>
							Submit
						</Button>
					</form>
					<Link to="/auth/login" className="existing-account">Already Have An Account?</Link>
				</div>
			</div>
			<div className="rna-wrapper">
				<NotificationAlert ref={notificationRef} />
			</div>
		</>
	);
};

export default Demo;
