export type IPlacementStatus = 'draft' | 'active' | 'archived' | 'complete';

export interface IPlacement {
  title: string;
  questions: IPlacementQuestion[];
  status: IPlacementStatus;
  tags: string[];
  createdBy: string | null;
  createdAt?: firebase.firestore.Timestamp;
  expiryDate?: firebase.firestore.Timestamp;
}

export interface IPlacementQuestionResponse {
  id: string;
}

export type IPlacementQuestionType = 'text' | 'video';

export type IPlacementQuestion = {
  type: IPlacementQuestionType;
  text: string;
  weight: number;
  mimeType?: string;
  videoUrl?: string;
};

export interface IInterviewQuestionResponse {
  completed: boolean;
  videoMime: string;
  videoUrl: string;
  thumbNail: string;
  transcript: any;
  duration?: number;
  hasActivityPost?: boolean;
  score: number;
}

export const PlacementStatusText = (status: IPlacementStatus): string => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'draft':
    default:
      return 'Draft';
    case 'archived':
      return 'Archived';
  }
};

export const PlacementStatusClass = (status: IPlacementStatus): string => {
  switch (status) {
    case 'active':
      return 'fg-purple-darker';
    case 'draft':
    default:
      return 'fg-yellow';
    case 'archived':
      return 'fg-blue';
  }
};

export interface IGoogleNLPAnnotation {
  categories: any[];
  documentSentiment: { magnitude: number; score: number };
  entities: {
    mentions: {
      sentiment: {
        magnitude: number;
        score: number;
      };
      text: {
        beginOffset: number;
        content: string;
      };
      type: string;
    }[];
    metadata: any;
    name: string;
    salience: number;
    type: string;
  }[];
  language: string;
  sentences: {
    sentiment: {
      magnitude: number;
      score: number;
    };
    text: {
      beginOffset: number;
      content: string;
    };
  }[];
  tokens: any[];
}

export interface ITranscriptAnnotation {
  sentiment: { magnitude: number; score: number };
  keywords: { word: string; score: number }[];
}

export interface ITranscript {
  transcript: string;
  confidence: number;
  annotation?: ITranscriptAnnotation;
}

export type ILikelihood = 'VERY_UNLIKELY' | 'UNLIKELY' | 'POSSIBLE' | 'LIKELY' | 'VERY_LIKELY';

export const LikelihoodScore = (value: ILikelihood): number => {
  switch (value) {
    case 'VERY_UNLIKELY':
    default:
      return 0;
    case 'UNLIKELY':
      return 1;
    case 'POSSIBLE':
      return 2;
    case 'LIKELY':
      return 3;
    case 'VERY_LIKELY':
      return 4;
  }
};

export interface IFaceResult {
  url: string;

  angerLikelihood: ILikelihood;
  blurredLikelihood: ILikelihood;
  headwearLikelihood: ILikelihood;
  joyLikelihood: ILikelihood;
  sorrowLikelihood: ILikelihood;
  surpriseLikelihood: ILikelihood;
  underExposedLikelihood: ILikelihood;

  detectionConfidence: number;

  panAngle: number;
  rollAngle: number;
  tiltAngle: number;
}

export type IPlacementResponseStatus = 'started' | 'complete';

export interface IPlacementResponse {
  status: IPlacementResponseStatus;
  score: number;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  thumbNail?: string;
}

export interface ISampleQuestion {
  question: string;
}

export interface ICandidateInvite {
  name: string;
  email: string;
  placement: string;
  url: string;
  emailEventData?: any;
  dateCreated?: Date;
}

