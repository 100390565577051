import React from 'react';
// react library for routing
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';

// core components
import LandingFooter from '../components/Landing/LandingFooter';

import routes, { IRoute } from '../routes';

interface ILandingProps extends RouteComponentProps<any> {
  oldNav: Boolean
}

class Auth extends React.Component<ILandingProps> {
  private mainContent: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    document.body.classList.add('bg-default');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }
  componentDidUpdate(props: ILandingProps) {
    if (props.history.location.pathname !== props.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement!.scrollTop = 0;
      if (this.mainContent.current) {
        this.mainContent.current.scrollTop = 0;
      }
    }
  }
  getRoutes = (r: IRoute[]): JSX.Element[] => {
    const ret: JSX.Element[] = [];

    for (const prop of r) {
      if (prop.collapse && prop.views) {
        ret.push(...this.getRoutes(prop.views));
      }
      if (prop.path.startsWith('/auth')) {
        if (prop.redirect) {
          ret.push(<Redirect from={prop.path} to={prop.to!} key={prop.path} exact={prop.exact || false} />);
        } else {
          ret.push(<Route path={prop.path} component={prop.component} key={prop.path} exact={prop.exact || false} />);
        }
      }
    }

    return ret;
  };
  render() {
    return (
      <>
        <div className="main-content" ref={this.mainContent}>
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <LandingFooter {...this.props} />
      </>
    );
  }
}

export default Auth;
