import React from 'react';
import * as yup from 'yup';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/app';
import Button from '../../components/General/Buttons'

interface IForgotPasswordProps extends RouteComponentProps {}

interface IForgotPasswordForm {
  email: string;
}

const forgotPasswordvalidator = yup.object<IForgotPasswordForm>({
  email: yup
    .string()
    .required('Please enter an email.')
    .email('Please enter a valid email.')
});

export const ForgotPassword = (props: IForgotPasswordProps) => {
  const [focusedEmail, setFocusedEmail] = React.useState<boolean>();
  const [loading, SetLoading] = React.useState<boolean>();
  const form = useForm<IForgotPasswordForm>({ validationSchema: forgotPasswordvalidator });

  const onSubmit = (data: IForgotPasswordForm) => {
    SetLoading(true);
    firebase
      .auth()
      .sendPasswordResetEmail(data.email)
      .then(() => {
        SetLoading(false);
        props.history.push(`/auth/login`, { reset: true });
      });
  };

  return (
    <>
      <div className="login-container flex-horizontal fade-in-fast">
        <div className="left-img">
          <a href="/">
            <img src={require('assets/img/brand/orbit-logo-dark.png')} />
          </a>
        </div>

        <div className="flex-vertical right-side login flex-center">
          <h1>Forgot Password</h1>

          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div>
              <input
                ref={form.register({ required: true })}
                onFocus={() => setFocusedEmail(true)}
                onBlur={() => setFocusedEmail(false)}
                type="email"
                name="email"
                id="email"
                className={`login-input ${form.errors.email ? 'invalid' : ''} ${focusedEmail ? 'focused' : ''}`}
                placeholder="Email"
              />
              {form.errors.email && <>{form.errors.email.message}</>}
            </div>
            <Button disabled={loading || !!Object.keys(form.errors).length} type="submit" primary>
              Submit
            </Button>
          </form>
          <Link to="/auth/login">Go Back</Link>
        </div>
      </div>
    </>
  );
};
