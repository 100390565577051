import React, { useState, useEffect } from 'react';
import { Navbar } from 'reactstrap';

interface IInterviewAnswerProgress {
  totalquestions: number;
  questionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}

const InterviewAnswerProgress = (props: IInterviewAnswerProgress) => {
  const [progressItems, setProgressItems] = useState<JSX.Element[]>([<></>]);
  const [highestRecordedAnswer, setHighestRecordedAnswer] = useState(0);

  useEffect(() => {
    let innerItems = [<></>] ;

    for(let i = 0; i < props.totalquestions; i++){
      innerItems.push(
        <div 
          className={"item " + (props.questionIndex === i ? "active" : (highestRecordedAnswer >= i ? "completed" : "inactive"))}
          onClick={() => handleClick(i)}
          >
          <i className="fas fa-check" />
        </div>
      )
    }

    setProgressItems(innerItems);
  }, [props.questionIndex, props.totalquestions]);

  useEffect(() => {
    if(props.questionIndex > highestRecordedAnswer)
      setHighestRecordedAnswer(props.questionIndex);
  }, [props.questionIndex]);

  const handleClick = (selectedQuestionIndx: number) => {
    if(props.questionIndex !== selectedQuestionIndx && selectedQuestionIndx <= highestRecordedAnswer)
      props.setQuestionIndex(selectedQuestionIndx);
  };

  return (
    <div>
      <Navbar className="interview-answer-progress">
        <div className="interview-answer-inner">
          <p className="label-text">{"Question " + (props.questionIndex + 1) + " of " + props.totalquestions}</p>
          <div className="answer-progress">
            {progressItems.map(item => item)}
          </div>
        </div>
      </Navbar>
    </div>
  )
};

export default InterviewAnswerProgress;