import React from 'react';
import { Reporter } from '../../util/errors';

const dimensions = { width: 1280, height: 720 };

export const getMediaStream = async (
  setAskForMediaStream: React.Dispatch<React.SetStateAction<boolean>>,
  onDevicesNotFound: ((devicesNotFound: boolean) => void) | undefined,
  setErrorMessage: ((error: string) => void) | undefined,
  stream: MediaStream | undefined,
  setMediaStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>,
  setForceFallback: React.Dispatch<React.SetStateAction<boolean>>,
  setDeniedPermissions: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if(stream)
    return;

  console.log('Getting Stream');
  let waitAndAskAgain = false;

  do {console.log("trying")
    navigator.mediaDevices
    .getUserMedia({
      audio: true,
      video: {
        ...dimensions,
        facingMode: 'user'
      }
    })
    .then(async stream => {
      if(onDevicesNotFound)
        onDevicesNotFound(false);

      if(setErrorMessage)
        setErrorMessage("");

      waitAndAskAgain = false;
      console.log('Getting stream done');
      await setMediaStream(stream);
    })
    .catch(async err => {
      if (err.name === 'NotAllowedError') {
        if (navigator.userAgent.toLowerCase().includes('safari')) {
          setForceFallback(true);
          waitAndAskAgain = false;
          await setAskForMediaStream(false);
        } else {
          if(setErrorMessage)
            await setErrorMessage("To proceed, please enable webcam recording by clicking the target icon in the navigation bar.");

          waitAndAskAgain = true;
          await setDeniedPermissions(true);
        }
        return;
      } else if(err.name === 'NotFoundError') {
        if(onDevicesNotFound)
          onDevicesNotFound(true);

        if(setErrorMessage)
          await setErrorMessage("We have no access to your microphone and/or camera. Please make sure they are enabled");

        waitAndAskAgain = true;
      } else {
        Reporter.error("Couldn't start stream.", err);
      }
    });

    if(waitAndAskAgain)
      timeout(1000);
  } while (waitAndAskAgain);
};

const timeout = (delay: number) => {
  return new Promise( res => setTimeout(res, delay));
}