import React, {useState, useEffect} from 'react';
import '../../assets/scss/custom/comments.scss';
import { IFeedComment, IFeedPost } from '../../models/Feed.models';
import { useFirestoreDoc, useOrganization, useUser, useFirestoreCollection} from '../../util/firebase';
import { postComment } from '../Util/CandidateInfo';
import { IUser } from '../../models/User.models';
import Moment from 'react-moment';
import Drawer from 'rc-drawer';

import 'rc-drawer/assets/index.css';

interface ICommentsProps {
  id: string;
  placementId: string;
  isSummarized?: boolean;
  setPreventRedirect?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Comments = (props: ICommentsProps) => {
  let [newComment, setNewComment] = useState<string>("");
  const orgId = useOrganization();
  const [user] = useUser();
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>(props.id);
  const [selectedPlacementId, setSelectedPlacementId] = useState<string>(props.placementId);
  const [postId, setPostId] = useState<string>("-1");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [posts, loadedPosts] = useFirestoreCollection<IFeedPost>(
    `Organizations/${orgId}/Feed`,
     q => q.where('attachmentUserId', '==', selectedCandidateId).where('attachmentPlacementId', '==', selectedPlacementId).orderBy('date', 'desc'),
     [selectedCandidateId, selectedPlacementId]
     );

  const [feedComments, loadedFeedComments] = useFirestoreCollection<IFeedComment>(
    `Organizations/${orgId}/Feed/${postId}/Comments`,
    q => q.orderBy('date', 'desc')
    );

  useEffect(() => {
    setSelectedPlacementId(props.placementId);
  }, [props.placementId]);

  useEffect(() => {
    setSelectedCandidateId(props.id);
  }, [props.id]);

  useEffect(() => {
    if(loadedPosts && posts && posts.length)
      setPostId(posts[0].id);
  }, [posts]);

  const postCmnt = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if(newComment){      
      let innerComment = newComment;

      await setNewComment("");
      await postComment(user!.uid, innerComment, [], orgId, postId);
    }
  }

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <div className="comments">
      <div className="comment">{loadedFeedComments && feedComments && feedComments.length ? feedComments.length : 0}</div>
      {!props.isSummarized &&
        <>
          <p>Team Comments</p>
          <p onClick={toggleDrawer}>View Comments</p>
        </>}

       {props.isSummarized && props.setPreventRedirect &&
        <div 
          className="summarized-version" 
          onClick={async (event) => {
            toggleDrawer();
            event.preventDefault();
            event.stopPropagation();
            await props.setPreventRedirect!(true);
            }}>View Comments</div>}

      <Drawer
          open={drawerOpen}
          onClose={toggleDrawer}
          placement="right"
          handler={false}
          level={null}
          width="700px"
        >
          <div className="side-comments-container" onClick={toggleDrawer}>
            <div className="comments-inner set-height" onClick={e => e.stopPropagation()}>
              <h2>Comments</h2>
              <div className="comments-container">
                {loadedFeedComments && feedComments && feedComments.map((cmt, idx) => {
                  return <Comment key={`comment-${cmt.date}-${idx}`} feedComment={cmt} />
                })}
              </div>
              <textarea
                onChange={e => setNewComment(e.target.value)}
                value={newComment}
                className="comment-input"
              />
              <button onClick={postCmnt} className="comment-submit">Post Comment</button>
            </div>
          </div>
        </Drawer>
    </div>
  )
};

interface CommentProps {
  feedComment: IFeedComment;
}
const Comment = (props: CommentProps) => {
  const orgId = useOrganization();
  const author = useFirestoreDoc<IUser>(`Organizations/${orgId}/Team/${props.feedComment.userId}`);

  return (
    <div className="comment-container">
      <div className="meta-row">
        <div className="user">
          <div className="avatar" style={{backgroundImage: `url(${author? author.avatar: ''})`}} />
          <div>
            <h5 className="name">{author? author.name: ''}</h5>
            <p className="title">{author? author.role : ''}</p>
          </div>
          <Moment fromNow className="time" date={props.feedComment.date.toDate()} />
        </div>
      </div>
      <p className="comment-text">
        {props.feedComment.content}
      </p>
    </div>
  )
}

export default Comments;