import * as React from 'react';

interface ISocialButtonProps {
  network: 'twitter' | 'email' | 'linkedin' | 'facebook';
  message: string;
  url: string;
  header?: string;
}

export const SocialButton = (props: ISocialButtonProps) => {
  const getLink = () => {
    if (props.network === 'twitter') {
      return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(props.message || '')}&url=${encodeURIComponent(props.url || '')}`;
    } else if (props.network === 'email') {
      return `mailto:?subject=${encodeURIComponent(props.header || '')}&body=${encodeURIComponent(
        (props.message && `${props.message}\n\n${props.url}`) || props.url || ''
      )}`;
    } else if (props.network === 'facebook') {
      return `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url || '')}`;
    } else if (props.network === 'linkedin') {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(props.url)}&title=${encodeURIComponent(
        props.message
      )}&summary=${encodeURIComponent(props.message)}&source=${encodeURIComponent(props.url!)}`;
    }

    return '';
  };

  const getSVG = () => {
    if (props.network === 'twitter') {
      return <i className="fab fa-twitter" />;
    } else if (props.network === 'email') {
      return <i className="fas fa-envelope" />;
    } else if (props.network === 'linkedin') {
      return <i className="fab fa-linkedin" />;
    } else if (props.network === 'facebook') {
      return <i className="fab fa-facebook" />;
    }

    return <svg />;
  };

  return (
    <a className={'social ' + props.network} target="_blank" href={getLink()}>
      {getSVG()}
    </a>
  );
};
