import { IAddress } from '../models/User.models';

export const GoogleMapsResponseToAddress = (place: google.maps.places.PlaceResult): IAddress => {
  const c = place.address_components!;

  const streetNum = c.find(ad => ad.types.includes('street_number'));
  const route = c.find(ad => ad.types.includes('route'));
  const line2 = c.find(ad => ad.types.includes('room'));
  const postalCode = c.find(ad => ad.types.includes('postal_code'));
  const city = c.find(ad => ad.types.includes('locality'));
  const state = c.find(ad => ad.types.includes('administrative_area_level_1'));
  const country = c.find(ad => ad.types.includes('country'));

  return {
    line1: streetNum && route ? streetNum.long_name + ' ' + route.long_name : '',
    line2: line2 ? 'Unit ' + line2.long_name : '',
    postalCode: postalCode ? postalCode.long_name : '',
    city: city ? city.long_name : '',
    state: state ? state.long_name : '',
    country: country ? country.long_name : ''
  };
};

export const AddressToString = (address: Partial<IAddress>): string => {
  return address.line1 + ', ' + address.city + ', ' + address.state + ', ' + address.country + ' ' + address.postalCode;
};
