export const FirebaseConfig = {
  apiKey: 'AIzaSyDQQT4LggschrVFuzrjtKhTIfZmj3D3Oio',
  authDomain: 'interview-ai-83723.firebaseapp.com',
  databaseURL: 'https://interview-ai-83723.firebaseio.com',
  projectId: 'interview-ai-83723',
  storageBucket: 'interview-ai-83723.appspot.com',
  messagingSenderId: '707441557020',
  appId: '1:707441557020:web:3bf75fe170414d3a23e9d9',
  measurementId: 'G-V2LPE5S4X4'
};

export const API_BASE = `https://us-central1-interview-ai-83723.cloudfunctions.net/api`;

export const STRIPE_KEY = `pk_test_ntnLMrMoZnI0AuDxByF15aAh000u5aOkaE`;

export const FROM_EMAIL = 'hello@orbit.jobs';
