import React from 'react';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container
} from 'reactstrap';
import { useUser, useOrganization, useFirestoreDoc } from '../../util/firebase';
import firebase from 'firebase/app';
import { RouteComponentProps } from 'react-router';
import { useState } from 'react';
import { IOrganization } from '../../models/User.models';
import { WithID } from '../../models/Helpers.models';
import { OrgEditModal } from './DashNavbarOrgModal';
import { OrgIntegrationModal } from './DashNavbarIntegrationModal';
import { AccountModal } from './DashNavbarAccountModal';
import { DashNavbarSupportModal } from './DashNavbarSupportModal';

interface IUserNavProps extends RouteComponentProps {
  org: WithID<IOrganization>;
  user: firebase.User;
}

const UserNav = (props: IUserNavProps) => {
  // organization
  const [isEditOrganizationOpen, setIsEditOrganizationOpen] = useState(false);
  const [integrationOpen, setIntegrationOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);

  // settings
  // const [isEditSettingsOpen, setIsEditSettingsOpen] = useState(false);

  //support
  const [isSupportRequestOpen, setIsSupportRequestOpen] = useState(false);

  return (
    props.user && (
      <Nav className="align-items-center ml-auto ml-md-0" navbar>
        <UncontrolledDropdown nav className="user-dropdown">
          <DropdownToggle className="nav-link pr-0" color="" tag="a">
            <Media className="align-items-center">
              <Media className="ml-2 d-none d-lg-block mr-2">
                <span className="mb-0 text-sm font-weight-bold">{props.org.name}</span>
              </Media>
              <span className="avatar avatar-sm rounded-circle">
                <img alt="..." src={props.org.avatarUrl || require('assets/img/theme/user-default.png')} />
              </span>
            </Media>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">Welcome!</h6>
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={e => {
                setIsEditOrganizationOpen(true);
                e.preventDefault;
              }}
            >
              <i className="ni ni-single-02" />
              <span>My Organization</span>
            </DropdownItem>
            <OrgEditModal org={props.org} open={isEditOrganizationOpen} setOpen={setIsEditOrganizationOpen} />
            <DropdownItem
              href="#"
              onClick={e => {
                setIntegrationOpen(true);
                e.preventDefault;
              }}
            >
              <i className="ni ni-ui-04"/>
              <span>Integrations</span>
            </DropdownItem>
            <OrgIntegrationModal org={props.org} open={integrationOpen} setOpen={setIntegrationOpen} />
            {/*<DropdownItem href="#" onClick={() => setIsEditSettingsOpen(true)}>
              <i className="ni ni-settings-gear-65" />
              <span>Settings</span>
            </DropdownItem>
            <EditSettingsModal open={isEditSettingsOpen} setOpen={setIsEditSettingsOpen} org={props.org} />*/}
            <DropdownItem href="#" onClick={e => e.preventDefault()}>
              <i className="ni ni-calendar-grid-58" />
              <span>Activity</span>
            </DropdownItem>
            <DropdownItem href="#" onClick={() => setIsSupportRequestOpen(true)}>
              <i className="ni ni-support-16" />
              <span>Support</span>
            </DropdownItem>
            <DashNavbarSupportModal open={isSupportRequestOpen} setOpen={setIsSupportRequestOpen} org={props.org} user={props.user} />
            <DropdownItem href="#" onClick={() => setAccountOpen(true)}>
              <i className="ni ni-lock-circle-open" />
              <span>Update Account</span>
            </DropdownItem>
            <AccountModal open={isAccountOpen} setOpen={setAccountOpen} />
            <DropdownItem divider />
            <DropdownItem
              href="#"
              onClick={e => {
                firebase.auth().signOut();
                props.history.push('/');
              }}
            >
              <i className="ni ni-user-run" />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    )
  );
};

interface IAdminNavbarProps extends RouteComponentProps {
  theme: 'dark' | 'light';
  sidenavOpen: boolean;
  toggleSidenav: () => void;
  brandText: string;
}

const AdminNavbar = (props: IAdminNavbarProps) => {
  const [user] = useUser();
  const orgId = useOrganization();

  const org = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`);

  return (
    <>
      <Navbar
        className={
          'navbar-top navbar-expand border-bottom' +
          (props.theme === 'dark' ? ' navbar-dark' : '') +
          (props.theme === 'light' ? ' navbar-light bg-secondary' : '')
          + ' bg-purple-darker'
        }
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={
                    'pr-3 sidenav-toggler' + (props.sidenavOpen ? ' active' : '') + (props.theme === 'dark' ? ' sidenav-toggler-dark' : '')
                  }
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            {user && org ? <UserNav org={org} user={user} {...props}></UserNav> : <div />}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
