import React, { useState, useRef, useEffect } from 'react';
import {
	Card as ReactstrapCard,
	CardBody,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Badge,
	Spinner,
	Form,
	FormGroup,
	Input,
	Collapse,
	Table
} from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import { RouteComponentProps } from 'react-router-dom';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
	ResponderProvided,
	DraggableStateSnapshot,
	DraggableProvided,
	DroppableProvided,
	DroppableStateSnapshot
} from 'react-beautiful-dnd';
import { useOrganization, useFirestoreCollection, useFirestoreDoc } from '../../util/firebase';
import { ICandidate, IOrgCandidate, ICandidateList } from '../../models/User.models';
import firebase from 'firebase/app';
import { WithID } from '../../models/Helpers.models';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { IPlacement, IPlacementResponse, ICandidateInvite } from '../../models/Interview.models';
import { EMAIL_REGEX } from '../../util/regex';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { EmptyNotice } from '../../components/Util/EmptyNotice';
import Comments from '../../components/Dash/Comments';
import * as yup from 'yup';
import { FormError } from '../../components/FormError';
import { RichEditor } from '../../components/RichTextEditor';
import Icon from '../../components/General/Icon';
import Button from '../../components/General/Buttons';
import Card from '../../components/General/Card';
import { ConfirmModal, default as Modal } from '../../components/General/Modal';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
	userSelect: 'none',
	transform: `translateX(45px) translateY(45px)`,
	...draggableStyle
});

interface ICandidatesListItemProps extends RouteComponentProps {
	listId: string;
	orgId: string;
	candidate: WithID<ICandidate>;
	orgCandidate: WithID<IOrgCandidate>;
	index: number;
	deleteList: () => void;
}

function CandidateListItem(props: ICandidatesListItemProps) {
	if (!props.candidate || !props.orgCandidate || !props.orgCandidate.placements) {
		return <div />;
	}

	const [preventRedirect, setPreventRedirect] = useState(false);
	const placementId = props.orgCandidate.placements[0];

	const placement = useFirestoreDoc<IPlacement>(`Organizations/${props.orgId}/Placements/${placementId}`);
	const orgPlacement = useFirestoreDoc<IPlacementResponse>(
		`Organizations/${props.orgId}/Candidates/${props.candidate.id}/Placements/${placementId}`
	);
	const score = orgPlacement ? orgPlacement.score : 0;

	return (
		<div key={props.orgCandidate.id}>
			<Draggable key={props.orgCandidate.id} draggableId={props.orgCandidate.id} index={props.index} type="candidate">
				{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
					>
						<div
							id={props.candidate.id}
							onClick={() => {
								if (preventRedirect)
									setPreventRedirect(false);
								else
									props.history.push(`/admin/candidates/${props.candidate.id}/${props.candidate.placement}`)
							}}
							style={{ display: 'inline-block', width: '100%' }}>
							<div className="contact-details contact-card-margin" style={{ marginLeft: 0 }}>
								<div className="contact-card">
									<div className="contact-inner">
										<div className="contact">
											<div className="contact-photo" style={{ backgroundImage: `url(${props.candidate.avatar})` }} />
											<div className="contact-text">
												<h3 className="name">{props.candidate.name}</h3>
												<div className="score-summary">
													<div className="score">
														{Math.round(score * 100)}%
													</div>
													{/* <p>Skills Score</p> */}
												</div>
											</div>
										</div>

										<div className="contact-card-divider" />

										<div className="contact-summary">
											<div className="position">
												<p>Position</p>
												<p>{placement ? placement.title : ''}</p>
											</div>
											<div className="status">
												<p>Status</p>
												<p className={'mr-1' + (orgPlacement && orgPlacement.status === 'started' ? ' fg-yellow' : ' fg-purple-darker')}>
													{orgPlacement && orgPlacement.status === 'started' ? 'Started' : 'Complete'}
												</p>
											</div>
											<Comments placementId={placementId} id={props.candidate.id} isSummarized={true} setPreventRedirect={setPreventRedirect} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Draggable>
		</div>
	);
}

interface ICandidateInviteLIstProps {
	orgId: string;
	candidateInvite: WithID<ICandidateInvite>;
	placement?: string;
	notifyInvited: (name: string) => void;
}

function CandidateInvitesListItem(props: ICandidateInviteLIstProps) {
	// const [isInviteCandidateOpen, setIsInviteCandidateOpen] = React.useState(false);

	const getStatusColor = (event: string) => {
		switch (event) {
			case 'delivered' || 'opened' || 'clicked':
				return 'green';
			case 'sending':
				return 'yellow';
			default:
				return 'red';
		}
	}

	const sendNewInvitation = async () => {
		const url = window.location.href;
		const arr = url.split('/');
		const result = arr[0] + '//' + arr[2];
		const shareUrl = `${result}/interview/${props.orgId}/${props.candidateInvite.placement}`;

		const data: ICandidateInvite = {
			name: props.candidateInvite.name,
			email: props.candidateInvite.email,
			placement: props.candidateInvite.placement,
			url: shareUrl,
			dateCreated: new Date()
		};

		await firebase
			.firestore()
			.collection(`Organizations/${props.orgId}/CandidateInvites`)
			.add(data);

		await firebase
			.firestore()
			.collection(`Organizations/${props.orgId}/CandidateInvites`)
			.doc(props.candidateInvite.id)
			.delete();

		props.notifyInvited(props.candidateInvite.name);
	}

	return (
		<div key={props.candidateInvite.id}>
			<div className="contact-details contact-card-margin" style={{ marginLeft: 0 }}>
				<div className="contact-card">
					<div className="contact-inner">
						<div className="contact">
							<div className="contact-text">
								<h2 className="name">{props.candidateInvite.name}</h2>
								<div className="list-email">{props.candidateInvite.email}</div>
							</div>
						</div>
						<div className="contact-summary">
							<div className="status top-margin">
								<p>Status</p>
								<p className={'mr-1 fg-' + getStatusColor(props.candidateInvite.emailEventData ? props.candidateInvite.emailEventData.event : 'sending')}>
									{props.candidateInvite.emailEventData ? props.candidateInvite.emailEventData.event : 'sending'}
								</p>
							</div>
							<div className="action">
								<p>Action</p>
								<p onClick={sendNewInvitation}>Resend Invitation</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

interface IEditListModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	selectedList: WithID<ICandidateList> | undefined;
	setSelectedList: (list: WithID<ICandidateList> | undefined) => void;
}

interface IEditListForm {
	name: string;
}

const editListFormValidator = yup.object<IEditListForm>({
	name: yup
		.string()
		.required('Please enter a name.')
		.min(3, 'Name must be at least 3 characters long.')
});

const EditListModal = (props: IEditListModalProps) => {
	const { register, handleSubmit, errors, setValue, getValues, clearError } = useForm<IEditListForm>({ validationSchema: editListFormValidator });
	const { name } = getValues();
	const orgId = useOrganization();

	const [lastSelectedList, setLastSelectedList] = React.useState(props.selectedList);

	//This useEffect will run once after first render, and after that, every time lastSelectedList is updated
	useEffect(() => {
		if ((lastSelectedList && !name) || (lastSelectedList && lastSelectedList.name !== name)) {
			setValue('name', lastSelectedList.name);
		}
	}, [lastSelectedList]);

	const initialEditorState =
		props.selectedList && props.selectedList.emailTemplate
			? EditorState.createWithContent(convertFromRaw(props.selectedList.emailTemplate))
			: EditorState.createEmpty();
	const [editorState, setEditorState] = React.useState<EditorState>(initialEditorState);

	const [editorSubsOpen, setEditorSubsOpen] = React.useState(false);
	const [richEditorWidthClass, setRichEditorWidthClass] = React.useState("no-substitutions");

	useEffect(() => {
		setRichEditorWidthClass(editorSubsOpen ? "with-substitutions" : "no-substitutions");
	}, [editorSubsOpen]);

	if (lastSelectedList !== props.selectedList) {
		setLastSelectedList(props.selectedList);
		setEditorState(initialEditorState);
	}

	const onListSave = (list: Partial<ICandidateList>) => {
		if (props.selectedList) {
			const rawEditorState = convertToRaw(editorState.getCurrentContent());

			firebase
				.firestore()
				.doc(`Organizations/${orgId}/Lists/${props.selectedList.id}`)
				.set({ ...list, emailTemplate: rawEditorState, emailTemplateHTML: draftToHtml(rawEditorState) }, { merge: true });
			//Order in which state is set to undefined matters
			props.setSelectedList(undefined);
			setLastSelectedList(undefined);
			props.setOpen(false);
		}
	};

	return (
		<Modal
			open={props.open}
			onClose={() => {
				//Order in which state is set to undefined matters
				props.setSelectedList(undefined);
				setLastSelectedList(undefined);
				clearError('name');
				props.setOpen(false);
			}}
			onSubmit={handleSubmit(onListSave)}
			customSubmitText="Save"
			title="Edit"
		>
			<Form onSubmit={handleSubmit(onListSave)}>
				<FormGroup>
					<label className="form-control-label" htmlFor="text">
						Name
					</label>

					<Input
						id="name"
						name="name"
						rows="8"
						innerRef={register({ required: true })}
						placeholder="Enter a title"
						type="text"
					/>
					{errors.name && <FormError>{errors.name.message}</FormError>}
				</FormGroup>

				<FormGroup>
					<label className="form-control-label" htmlFor="text">
						Email Template
					</label>
					<RichEditor editorState={editorState} setEditorState={setEditorState} maxWidthClass={richEditorWidthClass} />
					<div className="pt-3 pb-3">
						<a
							href="#"
							onClick={e => {
								e.preventDefault();
								setEditorSubsOpen(!editorSubsOpen);
							}}
							className="text-sm"
						>
							{editorSubsOpen ? 'Hide Merge Tags Cheat Sheet' : 'Show Merge Tags Cheat Sheet'}
						</a>
					</div>
					<Collapse isOpen={editorSubsOpen}>
						<ReactstrapCard>
							<CardBody>
								<Table>
									<thead>
										<tr>
											<th>Merge Tags</th>
											<th>Value</th>
											<th>Example</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{'{{name}}'}</td>
											<td>The candidate's name</td>
											<td>John Smith</td>
										</tr>
										<tr>
											<td>{'{{company-name}}'}</td>
											<td>The company's name</td>
											<td>Orbit Jobs</td>
										</tr>
										<tr>
											<td>{'{{job-name}}'}</td>
											<td>The interview name</td>
											<td>Web Developer</td>
										</tr>
									</tbody>
								</Table>
							</CardBody>
						</ReactstrapCard>
					</Collapse>
				</FormGroup>
			</Form>
		</Modal>
	);
};

interface IInviteCandidateModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	orgId: string;
	notifyInvited: (name: string) => void;
	name?: string;
	email?: string;
	resend?: boolean;
}

const candidateFormValidator = yup.object<ICandidateInvite>({
	name: yup
		.string()
		.required('Please enter a name.')
		.min(3, 'Name must be at least 3 characters long.'),
	email: yup
		.string()
		.required('Please enter an email.')
		.email('Please enter a valid email.'),
	placement: yup.string().required('Please enter an interview.'),
	url: yup.mixed().notRequired(),
	dateCreated: yup.date().notRequired()
});

const InviteCandidateModal = (props: IInviteCandidateModalProps) => {
	const candidateForm = useForm<ICandidateInvite>({ validationSchema: candidateFormValidator });
	const { setValue } = candidateForm;
	const [placements, loadedPlacements] = useFirestoreCollection<IPlacement>(`Organizations/${props.orgId}/Placements`, query =>
		query.where('status', '==', 'active')
	);
	const [saving, setSaving] = React.useState(false);
	const [interviewsJSX, setInterviewsJSX] = React.useState(<></>);

	const inviteCandidateToInterview = async (e: ICandidateInvite) => {
		setSaving(true);

		if (!props.resend) {
			const url = window.location.href;
			const arr = url.split('/');
			const result = arr[0] + '//' + arr[2];
			const shareUrl = `${result}/interview/${props.orgId}/${e.placement}`;

			const data: ICandidateInvite = {
				...e,
				url: shareUrl,
				dateCreated: new Date()
			};

			await firebase
				.firestore()
				.collection(`Organizations/${props.orgId}/CandidateInvites`)
				.add(data);
		}

		props.notifyInvited(e.name);
		setSaving(false);
		props.setOpen(false);
	};

	useEffect(() => {
		setValue('name', props.name);
		setValue('email', props.email);
	}, [props.email, props.name]);

	useEffect(() => {
		let innerJSX = <></>;

		if (!loadedPlacements)
			innerJSX = <Spinner color="primary" />;
		else if (loadedPlacements && placements && placements.length) {
			innerJSX =
				<Input type="select" name="placement" innerRef={candidateForm.register({ required: true })} defaultValue="You have no active interviews">
					{placements.map(placement => (
						<option key={placement.id} value={placement.id}>
							{placement && placement.title}
						</option>
					))}
				</Input>;

			candidateForm.clearError('placement');
		}
		else
			candidateForm.setError('placement', 'string', 'You have no active interviews');

		setInterviewsJSX(innerJSX);
	}, [loadedPlacements, placements]);

	return (
		<Modal
			customSubmitText="Send"
			submitProps={{ disabled: saving }}
			onSubmit={candidateForm.handleSubmit(inviteCandidateToInterview)}
			open={props.open}
			onClose={() => props.setOpen(false)}
			title="Invite Candidate"
		>
			<Form onSubmit={candidateForm.handleSubmit(inviteCandidateToInterview)}>
				<label className="form-control-labe" htmlFor="exampleFormControlSelect1">
					Candidate Name
				</label>
				<Input className="mb-3" id="name" name="name" type="text" innerRef={candidateForm.register({ required: true })} />
				{candidateForm.errors.name && <FormError>{candidateForm.errors.name.message}</FormError>}
				<label className="form-control-labe" htmlFor="exampleFormControlSelect1">
					Candidate Email
				</label>
				<Input
					className="mb-3"
					id="email"
					name="email"
					type="email"
					innerRef={candidateForm.register({ required: true, validate: value => !!value.match(EMAIL_REGEX) })}
				/>
				{candidateForm.errors.email && <FormError>{candidateForm.errors.email.message}</FormError>}
				<label className="form-control-label" htmlFor="exampleFormControlSelect1">
					Select Interview
				</label>
				{interviewsJSX}
				{candidateForm.errors.placement && <FormError>{candidateForm.errors.placement.message}</FormError>}
			</Form>
		</Modal>
	);
};

interface IHeaderProps extends RouteComponentProps {
	placement?: string;
	notifyInvited: (name: string) => void;
}

function Header(props: IHeaderProps) {
	const orgId = useOrganization();
	const [isInviteCandidateOpen, setIsInviteCandidateOpen] = React.useState(false);

	return (
		<div className="activity-header">
			<h6 className="activity-title">Candidates</h6>
			<div className="flex-horizontal justify-between button-container">
				{props.placement ? (
					<Button primary onClick={() => props.history.push(`/admin/candidates`)}>
						<Icon name="filter" />
						Clear Filter
					</Button>
				) : null}
				<Button
					primary
					onClick={(e: any) => {
						e.preventDefault();
						setIsInviteCandidateOpen(true);
					}}
				>
					<Icon name="plus" />
					Invite
				</Button>
				<Button
					primary
					onClick={async (e: any) => {
						e.preventDefault();
						const lists = await firebase
							.firestore()
							.collection(`Organizations/${orgId}/Lists`)
							.get();

						firebase
							.firestore()
							.collection(`Organizations/${orgId}/Lists`)
							.add({ name: 'New List', isDefault: lists.docs.length === 0, index: lists.docs.length - 1 });
					}}
				>
					<Icon name="plus" />
					New List
				</Button>
			</div>
			<InviteCandidateModal
				notifyInvited={props.notifyInvited}
				open={isInviteCandidateOpen}
				setOpen={setIsInviteCandidateOpen}
				orgId={orgId}
			/>
		</div>
	);
}

interface ICandidatesListsProps extends RouteComponentProps<{ placement?: string }> {
	deleteList: (list: WithID<ICandidateList>) => void;
	placement?: string;
	notifyInvited: (name: string) => void;
}

function CandidatesLists(props: ICandidatesListsProps) {
	const [selectedList, setSelectedList] = React.useState<WithID<ICandidateList> | undefined>();
	const [isEditListOpen, setIsEditListOpen] = React.useState(false);

	const qs = queryString.parse(props.location.search);
	const placement = qs.placement;

	const orgId = useOrganization();
	const [lists, loadedLists, overrideLists] = useFirestoreCollection<ICandidateList>(`Organizations/${orgId}/Lists`, q =>
		q.orderBy('index', 'asc')
	);
	let [candidates, loadedCandidates] = useFirestoreCollection<ICandidate>(
		`Candidates`,
		q => q.where('organizations', 'array-contains', orgId || ''),
		[orgId]
	);
	let [orgCandidates, loadedOrgCandidates, overrideOrgCandidates] = useFirestoreCollection<IOrgCandidate>(
		`Organizations/${orgId}/Candidates`,
		q => q.orderBy('index', 'asc')
	);
	let [invitedCandidates, loadedInvitedCandidates] = useFirestoreCollection<ICandidateInvite>(
		`Organizations/${orgId}/CandidateInvites`,
		q => q.orderBy('dateCreated', 'desc')
	);
	//Filter soft deleted candidates out because we cannot query on an element that might not exist
	//TODO: Make softDelete guaranteed to have a value and change this to a q.where call in the above query
	orgCandidates = orgCandidates.filter(orgCd => {
		const cd = candidates.find(can => can.id === orgCd.id);
		return cd && (!cd.hasOwnProperty('softDelete') || !!!cd!.softDelete);
	})
	candidates = candidates.filter(cd => !!!cd.softDelete)

	const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
		if (!result.destination) {
			return;
		}

		if (!orgId) {
			return;
		}

		if (result.type === 'candidate') {
			let sourceList: WithID<IOrgCandidate>[] = [];
			let destList: WithID<IOrgCandidate>[] = [];

			const newCandidates = orgCandidates.filter(
				c => c.listId !== result.source.droppableId && c.listId !== result.destination!.droppableId
			);
			if (result.source.droppableId !== result.destination!.droppableId) {
				sourceList = orgCandidates.filter(c => c.listId === result.source.droppableId);
				destList = orgCandidates.filter(c => c.listId === result.destination!.droppableId);
				const candidateToMove = sourceList[result.source.index];

				sourceList.splice(result.source.index, 1);
				destList.splice(result.destination!.index, 0, candidateToMove);

				for (let i = 0; i < sourceList.length; i++) {
					sourceList[i].index = i;
					sourceList[i].listId = result.source.droppableId;
					newCandidates.push(sourceList[i]);
				}
				for (let i = 0; i < destList.length; i++) {
					destList[i].index = i;
					destList[i].listId = result.destination!.droppableId;
					newCandidates.push(destList[i]);
				}
			} else {
				destList = orgCandidates.filter(c => c.listId === result.destination!.droppableId);
				const candidateToMove = destList[result.source.index];

				destList.splice(result.source.index, 1);
				destList.splice(result.destination!.index, 0, candidateToMove);
				for (let i = 0; i < destList.length; i++) {
					destList[i].index = i;
					newCandidates.push(destList[i]);
				}
			}

			overrideOrgCandidates(newCandidates);

			await firebase.firestore().runTransaction(async transaction => {
				if (result.source!.droppableId !== result.destination!.droppableId) {
					for (let i = 0; i < sourceList.length; i++) {
						await transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Candidates/${sourceList[i].id}`), {
							listId: sourceList[i].listId,
							index: i
						});
					}
					for (let i = 0; i < destList.length; i++) {
						await transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Candidates/${destList[i].id}`), {
							listId: destList[i].listId,
							index: i
						});
					}
				} else {
					for (let i = 0; i < destList.length; i++) {
						await transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Candidates/${destList[i].id}`), {
							listId: destList[i].listId,
							index: i
						});
					}
				}
			});
		} else if (result.type === 'list') {
			const movedObject = lists[result.source.index];

			lists.splice(result.source.index, 1);
			lists.splice(result.destination!.index, 0, movedObject);
			for (let i = 0; i < lists.length; i++) {
				lists[i].index = i;
			}

			overrideLists(lists);

			await firebase.firestore().runTransaction(async transaction => {
				for (let i = 0; i < lists.length; i++) {
					await transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Lists/${lists[i].id}`), {
						index: i
					});
				}
			});
		}
	};

	const setAsDefault = (id: string) =>
		firebase.firestore().runTransaction(async transaction => {
			for (const list of lists) {
				if (list.isDefault && list.id !== id) {
					transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Lists/${list.id}`), { isDefault: false });
				} else if (list.id === id) {
					transaction.update(firebase.firestore().doc(`Organizations/${orgId}/Lists/${list.id}`), { isDefault: true });
				}
			}
		});

	return (
		<div className="candidate-drag-container">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="lists" type="list" direction="horizontal">
					{(listProvided: DroppableProvided, listSnapshot: DroppableStateSnapshot) => (
						<div ref={listProvided.innerRef} {...listProvided.droppableProps}>
							<div className="horizontal-scroll-row">
								{!loadedLists ? (
									<div className="cards-spinner">
										<Spinner color="primary" />
									</div>
								) : null}
								{loadedLists && !lists.length ? (
									<EmptyNotice message="You have no lists set up! To create one, press the New List button in the top-right." />
								) : null}
								{lists.map((list, idx) => {
									if (list.isInvited) {
										return (
											<div className="col-xl-3 col-lg-3 col-md-4 col-sm-12" key={list.id}>
												{/* the above is a hack! we are just doing <Col xl="3"> but it works for react dnd */}
												<Card
													className="candidate-list"
													scrollBody
													headerTitle={() => (
														<>
															{list.name} {list.isDefault ? <Badge color="secondary">Default List</Badge> : null}
														</>
													)}
													renderDropdown={() => (
														<UncontrolledDropdown nav>
															<DropdownToggle className="btn-neutral" color="default" size="sm">
																<i className="fas fa-ellipsis-v" />
															</DropdownToggle>
															<DropdownMenu right>
																<DropdownItem
																	onClick={() => {
																		setSelectedList(list);
																		setIsEditListOpen(true);
																	}}
																>
																	<span>Edit</span>
																</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													)}
												>
													<div key={list.id}>
														<div className="list-container">
															{!loadedInvitedCandidates ? <Spinner color="primary" /> : null}
															{loadedInvitedCandidates &&
																invitedCandidates
																	.map(inviteCandidate => (
																		<CandidateInvitesListItem
																			key={inviteCandidate.id}
																			orgId={orgId}
																			candidateInvite={inviteCandidate}
																			placement={props.placement}
																			notifyInvited={props.notifyInvited}
																		/>
																	))}
														</div>
													</div>
												</Card>
											</div>
										)
									} else {
										return (
											<Draggable key={list.id} draggableId={list.id} index={idx}>
												{(provided, snapshot) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
														{/* the above is a hack! we are just doing <Col xl="3"> but it works for react dnd */}
														<Card
															className="candidate-list placement-card"
															scrollBody
															headerTitle={() => (
																<>
																	{list.name} {list.isDefault ? <Badge color="secondary">Default List</Badge> : null}
																</>
															)}
															renderDropdown={() => (
																<UncontrolledDropdown nav>
																	<DropdownToggle className="btn-neutral" color="default" size="sm">
																		<i className="fas fa-ellipsis-v" />
																	</DropdownToggle>
																	<DropdownMenu right>
																		<DropdownItem
																			onClick={() => {
																				setSelectedList(list);
																				setIsEditListOpen(true);
																			}}
																		>
																			<span>Edit</span>
																		</DropdownItem>

																		<DropdownItem onClick={() => setAsDefault(list.id)} disabled={list.isDefault}>
																			<span>Set As Default</span>
																		</DropdownItem>
																		<DropdownItem
																			disabled={!!orgCandidates.filter(c => c.listId === list.id).length || list.isDefault}
																			onClick={() => props.deleteList(list)}
																		>
																			<span>Delete</span>
																		</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															)}
														>
															<Droppable droppableId={list.id} type="candidate">
																{(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
																	<div className="list-container" ref={provided.innerRef} {...provided.droppableProps}>
																		{!loadedOrgCandidates || !loadedCandidates ? <Spinner color="primary" /> : null}
																		{loadedOrgCandidates &&
																			loadedCandidates &&
																			orgCandidates
																				.filter(c => c.listId === list.id)
																				.filter(c => !placement || (c.placements && c.placements.includes(placement as string)))
																				.map((orgCandidate, idx) => (
																					<CandidateListItem
																						{...props}
																						key={orgCandidate.id}
																						orgId={orgId}
																						deleteList={() => props.deleteList(list)}
																						listId={list.id}
																						candidate={candidates.find(c => c.id === orgCandidate.id) as WithID<ICandidate>}
																						orgCandidate={orgCandidate}
																						index={idx}
																					/>
																				))}
																		{provided.placeholder}
																	</div>
																)}
															</Droppable>
														</Card>
													</div>
												)}
											</Draggable>
										)
									}
								})}
								{listProvided.placeholder}
							</div>
						</div>
					)}
				</Droppable>
			</DragDropContext>

			<EditListModal open={isEditListOpen} setOpen={setIsEditListOpen} selectedList={selectedList} setSelectedList={setSelectedList} />
		</div>
	);
}

interface ICandidatesProps extends RouteComponentProps { }

export default function Candidates(props: ICandidatesProps) {
	const placement = (queryString.parse(props.location.search).placement as string) || '';
	const [confirmOpen, setConfirmOpen] = useState(false);
	const orgId = useOrganization();
	const [selectedList, setSelectedList] = useState<WithID<ICandidateList>>();
	const notificationRef = useRef<any>(undefined);
	const notifyInvited = (name: string) => {
		let options = {
			place: 'tc',
			message: (
				<div className="alert-text">
					<span className="alert-title" data-notify="title">
						{' '}
						Success!
					</span>
					<span data-notify="message">Your invite was sent and {name} should see it soon!</span>
				</div>
			),
			type: 'info',
			icon: 'ni ni-bell-55',
			autoDismiss: 7
		};
		if (notificationRef.current) {
			notificationRef.current.notificationAlert(options);
		}
	};
	return (
		<div className="activity-container flex-vertical dash-content">
			<div className="rna-wrapper">
				<NotificationAlert ref={notificationRef} />
			</div>
			<ConfirmModal
				open={confirmOpen}
				title="Are you sure?"
				onClose={() => setConfirmOpen(false)}
				onSubmit={async () => {
					firebase
						.firestore()
						.doc(`Organizations/${orgId}/Lists/${selectedList!.id}`)
						.delete();
					setConfirmOpen(false)
				}}
			>
				Delete List {selectedList ? selectedList!.name : ''}?
			</ConfirmModal>
			<Header notifyInvited={notifyInvited} placement={placement} {...props} />
			<div className="feed-container">
				<CandidatesLists
					{...props}
					deleteList={(list: WithID<ICandidateList>) => {
						setSelectedList(list);
						setConfirmOpen(true);
					}}
					placement={placement}
					notifyInvited={notifyInvited}
				/>
			</div>
		</div>
	);
}
