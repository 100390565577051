import React, { useState, useEffect, useRef, createRef } from 'react';
// import ReactDOM from 'react-dom';
import { FormError } from '../../components/FormError';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import Select, { ValueType, FormatOptionLabelMeta, ActionMeta } from 'react-select';
import { useTransition, animated } from 'react-spring';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import RatingButtons, { DefaultText } from './RatingButtons';
import Button from '../../components/General/Buttons';
import TagsInput from 'react-tagsinput';
import { GetSkills } from '../../api/skills';
import Drawer from 'rc-drawer';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { WithID } from '../../models/Helpers.models';
import { IPlacementQuestion, IPlacementQuestionType, IPlacement } from '../../models/Interview.models';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import InterviewRecorder from '../../components/InterviewRecorder';
import firebase from 'firebase/app';
import { useOrganization, useUser, useFirestoreDoc } from '../../util/firebase';
import { IUser } from '../../models/User.models';
import VideoPlayer from '../../components/Dash/VideoPlayer';
import NotificationAlert from 'react-notification-alert';
import { ConfirmModal } from '../General/Modal';
import { getMediaStream } from '../Util/MediastreamGetter';
import { EmptyNotice } from '../Util/EmptyNotice';

import 'rc-drawer/assets/index.css';

/*
Bit weird so this needs some explanation -

React DND expects stuff to be local to the page.
We do some DND in this component in placement edit modals.
Portals are a very niche feature of React we can use to properly align the DND.
*/
const portal: HTMLElement = document.createElement('div');
portal.classList.add('question-items');
document.body.appendChild(portal);

interface IVideoQuestion {
  url: string;
  type: string;
}

interface CreateEditInterviewProps {
  openDrawer: boolean;
  toggleDrawer: () => void;
  selectedInterview?: WithID<IPlacement>;
  setSelectedInterview?: React.Dispatch<React.SetStateAction<WithID<IPlacement> | undefined>>;
}

const CreateEditInterview = (props: CreateEditInterviewProps) => {
  const [skillsLoading, setSkillsLoading] = useState(false);
  const [skills, setSkills] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [interviewTitle, setInterviewTitle] = useState<string|undefined>("");
  const [interviewTitleError, setInterviewTitleError] = useState("");
  const [interviewExpiryDate, setInterviewExpirydate] = useState<Date|undefined>();
  const [expiryDateError, setExpiryDateError] = useState("");
  const [seletedQuestionType, setSelectedQuestionType] = useState<IQuestionTypeOption>(QuestionTypeOptions[0]);
  // const [questions, setQuestions] = useState<IPlacementQuestion[]>(props.selectedInterview ? props.selectedInterview.questions : []);
  const [questions, setQuestions] = useState<IPlacementQuestion[]>([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionTitleError, setQuestionTitleError] = useState("");
  const [questionWeight, setQuestionWeight] = useState<number>(1);
  // const [tags, setTags] = useState<string[]>(props.selectedInterview ? props.selectedInterview.tags : []);
  const [tags, setTags] = useState<string[]>([]);

  const [recording, setRecording] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [currentVideoQuestion, setCurrentVideoQuestion] = useState<IVideoQuestion|undefined>();
  const [videoQuestionPlaying, setVideoQuestionPlaying] = useState(false);
  const [videoError, setVideoError] = useState("");
  const [questionToEdit, setQuestionToEdit] = useState<IPlacementQuestion|undefined>();
  const [indexQuestionEdit, setIndexQuestionEdit] = useState(-1);
  const [modalDeleteQuestionOpen, setModalDeleteQuestionOpen] = useState(false);
  const [indxQuestionToDelete, setIndxQuestionToDelete] = useState<number|undefined>();
  const [noDeleteWhileEditingError, setNoDeleteWhileEditingError] = useState("");
  const orgId = useOrganization();
  const [user] = useUser();
  const orgUser = useFirestoreDoc<IUser>(`Organizations/${orgId}/Team/${user ? user.uid: ''}`);

  const [mediaStream, setMediaStream] = useState<MediaStream|undefined>();
  const [devicesNotFound, setDevicesNotFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayRecorder, setDisplayRecorder] = useState(false);
  const [forceFallback, setForceFallback] = useState(false);
  const [deniedPermissions, setDeniedPermissions] = useState(false);
  const [askForMediastream, setAskForMediastream] = useState(false);

  const scrollableDiv = createRef<HTMLDivElement>();

  const transitions = useTransition(currentStep, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 2 },
    leave: { opacity: 0 }
  });

  useEffect(() => {
    if(mediaStream || forceFallback)
      setDisplayRecorder(true);
    else
      setDisplayRecorder(false);
  }, [mediaStream, forceFallback]);

  useEffect(() => {
    if(!mediaStream && askForMediastream)
      getMediaStream(
        setAskForMediastream,
        setDevicesNotFound,
        setErrorMessage,
        mediaStream,
        setMediaStream,
        setForceFallback,
        setDeniedPermissions
      )
  }, [askForMediastream]);

  useEffect(() => {
    if(seletedQuestionType.value === 'video')
      setAskForMediastream(true);
    else
      setAskForMediastream(false);
  }, [seletedQuestionType]);

  useEffect(() => {
    if(props.selectedInterview){
      setInterviewTitle(props.selectedInterview.title);
      setTags(props.selectedInterview.tags);
      setInterviewExpirydate(props.selectedInterview.expiryDate? props.selectedInterview.expiryDate.toDate(): new Date());
      setQuestions(props.selectedInterview.questions);
      setCurrentStep(3);
    }
  }, [props.selectedInterview]);

  useEffect(() => {
    if(scrollableDiv.current && currentStep === 2)
      scrollableDiv.current.scrollIntoView({block: 'end', behavior: 'smooth'});
  }, [questions]);

  useEffect(() => {
    if(questionToEdit){
      if(questionToEdit.type === 'video')
        setCurrentVideoQuestion({ url: questionToEdit.videoUrl ? questionToEdit.videoUrl : '', type: questionToEdit.mimeType!});

      setQuestionTitle(questionToEdit.text);
      setSelectedQuestionType(QuestionTypeOptions.filter(question => question.value === questionToEdit.type)[0]);
      setQuestionWeight(questionToEdit.weight);

      if(scrollableDiv.current && currentStep === 2)
        scrollableDiv.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }, [questionToEdit]);

  const resetQuestionFields = () => {
    setQuestionTitle("");
    setQuestionWeight(1);
    setSelectedQuestionType(QuestionTypeOptions[0]);
    setCurrentVideoQuestion(undefined);
    setNoDeleteWhileEditingError("");
  }

  const getSkills = React.useCallback(async (query: string) => {
    if (query === '') {
      return [];
    }

    setSkillsLoading(true);
    const resp = await GetSkills(query);
    setSkills(resp.map(skill => skill.name));
    setSkillsLoading(false);
  }, []);

  const handleSkillsinput = (tags: string[]) => {
    setTags(tags);
  };

  //This function takes care of capturing the data across all steps
  const submitStep = async () => {
    let ok = true;
    switch (currentStep) {
      case 1:
        if(!interviewTitle) {
          ok = false;
          setInterviewTitleError("Please provide an interview title")
        }
        if(!interviewExpiryDate) {
          setExpiryDateError("Please select an expiry date");
          ok = false;
        }      
        break;
      case 2:
        if (questions.length == 0) 
          ok = false;
      default:
        break;
    }

    if(ok)
      setCurrentStep(currentStep + 1);
  }

  const stepperStep1 = async () => {
    // submitStep();
    setCurrentStep(1);
  }

  const stepperStep2 = async () => {
    submitStep();

    if(interviewExpiryDate && interviewTitle)
      setCurrentStep(2);
  }
  
  const stepperStep3 = async () =>{
    submitStep();

    if(interviewExpiryDate && interviewTitle && questions.length > 0)
      setCurrentStep(3);
  }

  const handleQuestionTypeChange = (option: ValueType<IQuestionTypeOption>, action: ActionMeta<IQuestionTypeOption>) => {
    let innerOption = option as IQuestionTypeOption;

    if(innerOption)
      setSelectedQuestionType(innerOption);
  };

  const handleRatingChange = (rating: number) => setQuestionWeight(rating);

  const handleInterviewTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setInterviewTitle(event.target.value);

    if(event.target.value)
      setInterviewTitleError("");
  }

  const handleQuestionTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setQuestionTitle(event.target.value);

    if(event.target.value)
      setQuestionTitleError("");
  }

  const addQuestion = () => {
    if(!questionTitle) {
      setQuestionTitleError("Please provide a question title");
      return;
    }

    let innerQuestions: IPlacementQuestion[] = questions.map(question => question);

    if(seletedQuestionType.value === 'video' && !currentVideoQuestion){
      setVideoError("Please record a video question");
      return;
    }

    if(currentVideoQuestion)
      innerQuestions.push({ 
        type: seletedQuestionType.value, 
        text: questionTitle, 
        weight: questionWeight, 
        mimeType: currentVideoQuestion.type,
        videoUrl: currentVideoQuestion.url
       });
    else
      innerQuestions.push({ type: seletedQuestionType.value, text: questionTitle, weight: questionWeight });

    setQuestions(innerQuestions);
    resetQuestionFields();
    genericNotification("Your question has been added");
  }

  const deleteQuestion = async (idx: number | undefined) => {
    if(idx !== undefined && idx >= 0) {
      setQuestions(questions.filter((q, i) => i !== idx));
      setIndxQuestionToDelete(undefined);

      genericNotification("Question has been deleted");
    }
  };

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.type === 'question') {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const newQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));
      const val = newQuestions[sourceIndex];
      newQuestions.splice(sourceIndex, 1);
      newQuestions.splice(destIndex, 0, val);

      setQuestions(newQuestions);
    }
  };

  const onRecordingDone = async (blob: Blob, timestamp: string, duration?: number) => {
    setRecording(false);
    setUpload(false);
    setUploadLoading(true);

    console.log('Uploading...');

    const placementId = '123';
    const n = questions.length.toString();

    const extension = blob.type === 'video/quicktime' ? 'mov' : 'webm';

    const r = firebase.storage().ref(`interview-questions/${orgId}/${placementId}/${questions.length}.${extension}`);
    await r.put(blob, {
      contentType: blob.type,
      customMetadata: {
        placementId: placementId,
        question: n,
        orgId: orgId,
        timestamp
      }
    });
    const videoUrl = await r.getDownloadURL();
    console.log('Uploaded.');
    setUploadLoading(false);

    setCurrentVideoQuestion({ url: videoUrl, type: blob.type });
    setVideoError("");
  };

  const editQuestion = (question: IPlacementQuestion, index: number) => {
    setQuestionToEdit(question);
    setIndexQuestionEdit(index);

    if(currentStep === 3)
      setCurrentStep(2);
  }

  const saveQuestionChanges = () => {
    if(!questionTitle) {
      setQuestionTitleError("Please provide a question title");
      return;
    }

    let innerQuestions: IPlacementQuestion[] = questions.map(question => question);
    
    if(questionToEdit && questionToEdit.type === 'video'){
      innerQuestions[indexQuestionEdit].videoUrl = currentVideoQuestion? currentVideoQuestion.url : '';
      innerQuestions[indexQuestionEdit].mimeType = currentVideoQuestion? currentVideoQuestion.type : '';
    } 

    innerQuestions[indexQuestionEdit].text = questionTitle;
    innerQuestions[indexQuestionEdit].weight = questionWeight;

    setQuestionToEdit(undefined);
    setIndexQuestionEdit(-1);
    resetQuestionFields();
    genericNotification("Your question has been updated");
  }

  const displayFinalLabel = (label: string, htmlFor: string) => (
    <div className="final-labels">
      <label className="formgroup-label" htmlFor={htmlFor}>
        {label}
      </label>
      <span className="fas fa-pencil-alt" onClick={() => setCurrentStep(1)} />
    </div>
  )

  const onSaveInterview = async () => {
    let innerPlacement: Partial<IPlacement> = {
      title: interviewTitle? interviewTitle : '',
      tags: tags,
      questions: questions,
      createdAt: new firebase.firestore.Timestamp(Math.round((new Date()).getTime() /1000), 0),
      expiryDate: new firebase.firestore.Timestamp(Math.round(interviewExpiryDate!.getTime() / 1000), 0)
    }

    const placementsRef = firebase.firestore().collection(`Organizations/${orgId}/Placements`);
    if (props.selectedInterview) {
      await placementsRef.doc(props.selectedInterview.id).set(innerPlacement, { merge: true });
    } else {
      innerPlacement.createdBy = orgUser!.name;
      await placementsRef.add({ ...innerPlacement, status: 'draft' });
    }

    if(props.selectedInterview && props.setSelectedInterview) {
      props.setSelectedInterview(undefined);
      setQuestions([]);
    }
    releaseMediastream();
    props.toggleDrawer();
  }

  const notificationRef = useRef<any>(undefined);

  const genericNotification = (message: string) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {' '}
            Success!
          </span>
          <span data-notify="message"> {message}</span>
        </div>
      ),
      type: 'info',
      icon: 'ni ni-bell-55',
      autoDismiss: 7
    };
    if (notificationRef.current) {
      notificationRef.current.notificationAlert(options);
    }
  };

  const toggleDeleteQuestion = () => {
    if(questionToEdit){
      setNoDeleteWhileEditingError("Please, finish editting the current question before deleting from the list")
      return;
    }

    setModalDeleteQuestionOpen(!modalDeleteQuestionOpen)
  };

  const releaseMediastream = () => {
    if(mediaStream)
      mediaStream.getTracks().forEach(t => t.stop());
  }

  return(
    <>
      <Drawer
        open={props.openDrawer}
        onClose={ () => {
          if(props.selectedInterview && props.setSelectedInterview)
            props.setSelectedInterview(undefined);
          releaseMediastream();
          props.toggleDrawer();
        }}
        placement="right"
        handler={false}
        level={null}
        width="700px">
        <div className="side-comments-container" ref={scrollableDiv}>
          {transitions.map(transition => (
            <div key={transition.key}>
              {currentStep === 1 && 
                <animated.div style={transition.props}>
                  <div className="comments-inner">
                    <h2>Create Interview</h2>
                    <div className="steps">
                      <div className="header"><span className="step-number">Step 1</span> - Interview Information</div>
                      <div className="steps-progress">
                        <i className="active" />
                        <i className="inactive" onClick={stepperStep2} /> 
                        <i className="inactive" onClick={stepperStep3} />
                      </div>
                    </div>
                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="title">
                        Name your Interview
                      </label>
                      <Input
                        id="title"
                        name="title"
                        rows="8"
                        value={interviewTitle}
                        onChange={handleInterviewTitleChange}
                        placeholder="Enter a title"
                        type="text"
                      />
                      {interviewTitleError && <FormError>{interviewTitleError}</FormError>}
                    </FormGroup>
            
                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="expiryDateTest">
                        Assign Interview Skills
                      </label>
                      <InputGroup className="borders">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="no-borders">
                            <span className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
              
                        <AsyncTypeahead
                          allowNew={true}
                          isLoading={skillsLoading}
                          multiple={false}
                          options={skills}
                          minLength={3}
                          onSearch={getSkills}
                          placeholder="Search for or create skill tags"
                          id="skill-selector"
                          renderMenuItemChildren={(option, props) => <div>{option}</div>}
                          selected={selectedTag}
                          selectHintOnEnter={true}
                          newSelectionPrefix=""
                          onInputChange={input => setSelectedTag([input])}
                          onChange={selected => {
                            if (selected[0]) {
                              if ((selected[0] as any).customOption) {
                                const label = (selected[0] as any).label;
                                const exist = tags.indexOf(label);
                                
                                if(label && exist === -1)
                                  setTags([label, ...tags]);
                              } else {
                                const exist = tags.indexOf(selected[0]);
                                if(selected[0] && exist === -1)
                                  setTags([...selected, ...tags]);
                              }
                              setSelectedTag([]);
                            }
                          }}
                          onKeyDown={
                            ((e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                
                                const val = (e.target as HTMLInputElement).value;
                                const exist = tags.indexOf(val);
              
                                if(val && exist === -1)
                                  setTags([val, ...tags]);
              
                                setSelectedTag([]);
                              }
                            }) as any
                          }
                        />
                      </InputGroup>
              
                      <TagsInput
                        onlyUnique
                        className="bootstrap-tagsinput"
                        onChange={handleSkillsinput}
                        value={tags}
                        tagProps={{ className: 'tag badge mr-1' }}
                        inputProps={{
                          className: '',
                          placeholder: ''
                        }}
                      />
                    </FormGroup>
            
                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="expiryDateTest">
                        Interview Expiry Date
                      </label><br/>
                      <InputGroup>
                        <ReactDatetime
                          key="expiryDate"
                          closeOnSelect={true}
                          value={interviewExpiryDate}
                          dateFormat="MMMM DD, YYYY"
                          inputProps={{
                            placeholder: "Set Interview Expiry Date"
                          }}
                          timeFormat={false}
                          onChange={date => {
                            setInterviewExpirydate(moment(date).toDate());
                            setExpiryDateError("");
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <span className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {expiryDateError && <FormError>{expiryDateError}</FormError>}
                    </FormGroup>
                    <div className="submit-button">
                      <button onClick={submitStep} className="step-submit">Continue To Step 2</button>
                    </div>
                  </div>
                </animated.div>
              }

              { currentStep === 2 &&
                <animated.div style={transition.props}>
                  <div className="comments-inner">
                    <h2>Create Interview</h2>
                    <div className="steps">
                      <div className="header"><span className="step-number">Step 2</span> - Interview Questions</div>
                      <div className="steps-progress">
                        <i className="inactive" onClick={stepperStep1} /> 
                        <i className="active" /> 
                        <i className="inactive" onClick={stepperStep3} />
                      </div>
                    </div>
                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="questionTitle">
                        Question Title
                      </label>
                      <Input
                        className="textarea-height"
                        id="questionTitle"
                        name="questionTitle"
                        rows="8"
                        placeholder="Enter Question title"
                        type="textarea"
                        value={questionTitle}
                        onChange={handleQuestionTitleChange}
                        height={2}
                      />
                      {questionTitleError && <FormError>{questionTitleError}</FormError>}
                    </FormGroup>

                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="question-title">
                        Question Type
                      </label>
                      <Select
                        className="orbit-select"
                        formatOptionLabel={formatOptionalLabel}
                        value={seletedQuestionType}
                        isSearchable={true}
                        options={QuestionTypeOptions}
                        onChange={handleQuestionTypeChange}
                        isDisabled={questionToEdit ? true : false}
                      />
                    </FormGroup>

                    { seletedQuestionType.value === 'video' &&
                      <>
                        <div className={"video-container" + (recording ? " recording" : "")}>
                          <div className="player-and-button">
                            {uploadLoading ? 
                              <div className="cards-spinner">
                                <Spinner color="primary" />
                              </div> : null}

                            {currentVideoQuestion && !recording && !upload && !uploadLoading &&
                              <div className="video-thumbnail">
                                <img className="inner-thumbnail" src={require('assets/img/brand/orbit-logo-light.png')} />
                                <div className="container-play-button">
                                  <div className="post-play" onClick={() => setVideoQuestionPlaying(!videoQuestionPlaying)}>
                                    {videoQuestionPlaying ? <i className="fa fa-stop" /> : <i className="ni ni-button-play" />}
                                  </div>
                                </div>
                              </div>
                            }
                            
                            {!deniedPermissions && !devicesNotFound && !recording && !upload && !uploadLoading && 
                              <div className="text-description">
                                <p>Record a video question for your candidates</p>
                                <Button onClick={() => setRecording(true)} className="comment-submit" primary>
                                  Record Your Video
                                </Button>
                              </div>
                            }
                            {devicesNotFound && <EmptyNotice message={errorMessage} />}
                            {deniedPermissions && <EmptyNotice message={errorMessage} />}
                          </div>
                          {displayRecorder && 
                            <div className={`record-video ${recording ? 'show fade-in' : 'hidden'}`}>
                              <InterviewRecorder 
                                onRecordingDone={onRecordingDone} 
                                record={recording} 
                                upload={upload} 
                                onDevicesNotFound={setDevicesNotFound}
                                setErrorMessage={setErrorMessage}
                                mediaStream={mediaStream}
                                setMediaStream={setMediaStream}
                                forceFallback={forceFallback}
                                deniedPermissions={deniedPermissions} />
                            </div>}
                        </div>
                        {videoError && <FormError>{videoError}</FormError>}

                        {currentVideoQuestion && videoQuestionPlaying &&
                          <div className="video-player-container">
                            <VideoPlayer playerOptions={
                              { controls: true,
                                fluid: true,
                                autoplay: true,
                                sources: [{
                                  src: currentVideoQuestion.url,
                                  type: 'video/mp4'
                                }],
                                poster: currentVideoQuestion.type
                              }
                            } />
                          </div>
                        }
                      </>
                    }

                    <FormGroup className="container-input">
                      <label className="formgroup-label" htmlFor="question-title">
                        Question Weight
                      </label>
                      <RatingButtons rated={questionWeight} onChange={handleRatingChange} />
                    </FormGroup>

                    <div className="card-divider container-input"></div>

                    <div className="submit-button">
                      {questionToEdit ? 
                        <button onClick={saveQuestionChanges} className="comment-submit">Save Changes</button> :
                        <button onClick={addQuestion} className="comment-submit">Save and Add Another</button>
                      }
                    </div>                  
                    
                    {noDeleteWhileEditingError && <FormError>{noDeleteWhileEditingError}</FormError>}

                    {questions && questions.length > 0
                      && <QuestionItems 
                            questions={questions} 
                            onDragEnd={onDragEnd} 
                            editQuestion={editQuestion} 
                            toggleDeleteQuestion={toggleDeleteQuestion}
                            setIndxQuestionToDelete={setIndxQuestionToDelete} />}

                    {questions && questions.length > 0
                      && <div className="submit-button">
                          <button onClick={submitStep} className="comment-submit">Continue To Step 3</button>
                        </div>}

                  </div>
                </animated.div>
              }

              { currentStep === 3 &&
                <animated.div style={transition.props}>
                  <div className="comments-inner">
                    <h2>Create Interview</h2>
                    <div className="steps">
                      <div className="header"><span className="step-number">Step 3</span> - Review</div>
                      <div className="steps-progress">
                        <i className="inactive" onClick={stepperStep1} /> 
                        <i className="inactive" onClick={stepperStep2} /> 
                        <i className="active" />
                      </div>
                    </div>

                    <FormGroup className="container-input">
                      {displayFinalLabel("Interview Name", "title")}
                      <Input id="title" rows="8" defaultValue={interviewTitle ? interviewTitle : ''} type="text" disabled={true} />
                    </FormGroup>

                    <FormGroup className="container-input">
                      {displayFinalLabel("Interview Skills", "skills")}
                      <TagsInput
                        key="skills"
                        onlyUnique
                        className="bootstrap-tagsinput"
                        onChange={handleSkillsinput}
                        value={tags}
                        tagProps={{ className: 'tag badge mr-1' }}
                        inputProps={{
                          className: '',
                          placeholder: ''
                        }}
                        disabled={true}
                        />
                    </FormGroup>
                    
                    <FormGroup className="container-input">
                      {displayFinalLabel("Interview Expiry Date", "expiryDate")}
                      <Input id="expiryDate" rows="8" defaultValue={interviewExpiryDate ? moment(interviewExpiryDate).format('MMMM d, YYYY') : ''} type="text" disabled={true} />
                    </FormGroup>

                    {questions && questions.length > 0
                      && <QuestionItems 
                            questions={questions} 
                            onDragEnd={onDragEnd} 
                            editQuestion={editQuestion} 
                            toggleDeleteQuestion={toggleDeleteQuestion}
                            setIndxQuestionToDelete={setIndxQuestionToDelete} />}

                    <div className="submit-button">
                      <button onClick={onSaveInterview} className="comment-submit">{props.selectedInterview ? "Save Changes" : "Create Interview"}</button>
                    </div>
                  </div>
                </animated.div>
              }
            </div>
          ))}     
        </div>
      </Drawer>
      <ConfirmModal
        open={modalDeleteQuestionOpen}
        title="Are you sure?"
        onClose={toggleDeleteQuestion}
        onSubmit={()=> {
          deleteQuestion(indxQuestionToDelete);
          toggleDeleteQuestion();
        }}
      >
        Delete Question {indxQuestionToDelete !== undefined && indxQuestionToDelete >= 0 ? questions[indxQuestionToDelete].text : ''}?
      </ConfirmModal>
      <div className="rna-wrapper" style={{zIndex: 10100}}>
        <NotificationAlert ref={notificationRef} />
      </div>
    </>
  )
}

interface IQuestionItems {
  questions: IPlacementQuestion[];
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  editQuestion: (question: IPlacementQuestion, index: number) => void;
  toggleDeleteQuestion: () => void;
  setIndxQuestionToDelete: React.Dispatch<React.SetStateAction<number | undefined>>
}
const QuestionItems = (props: IQuestionItems) => {
  return (
    <div className="container-input">
      <label htmlFor="" className="formgroup-label">
        Question Order
      </label>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="questions" type="question" >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="question-items">
                {props.questions.map((question, indx) => (
                  <Draggable key={`question-draggble${indx}`} draggableId={indx.toString()} index={indx} type="question">
                    {(provided, snapshot) => {
                      const child = (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className="question-item" key={`question-item-${indx}`} >
                            <div className="left-hand-side">
                              <span className="fa fa-ellipsis-v" />
                              <span className={questionDecoration[question.type]} />
                              <p>{question.text}</p>
                            </div>

                            <div className="right-hand-side">
                              <div className="weight">{DefaultText[question.weight]}</div>
                              <span className="fas fa-pencil-alt" onClick={() => props.editQuestion(question, indx)} />
                              <span className="fa fa-window-close delete" onClick={() => {
                                props.setIndxQuestionToDelete(indx);
                                props.toggleDeleteQuestion();
                              }} />
                            </div>
                          </div>
                        </div>
                      )
                      // if (!snapshot.isDragging) {
                      //   return child;
                      // }
                      return child;
                      // return ReactDOM.createPortal(child, mainPortal);
                    }}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

interface IQuestionTypeOption {
  value: IPlacementQuestionType;
  label: string;
  customLabel: string;
}

const questionDecoration = {
  text: "ni ni-caps-small",
  video: "ni ni-button-play",
  multipleChoice: "ni ni-bullet-list-67"
}

const QuestionTypeOptions: IQuestionTypeOption[] = [
  { value: "text", label: "Written Question", customLabel: questionDecoration["text"]},
  { value: "video", label: "Video Question", customLabel: questionDecoration["video"]}
  // { value: "multipleChoice", label: "Multiple Choice Question", customLabel: questionDecoration["multipleChoice"]}
]

const formatOptionalLabel = (option: ValueType<IQuestionTypeOption>, labelMeta: FormatOptionLabelMeta<IQuestionTypeOption>) => {
  let innerOption = option as IQuestionTypeOption;

  return (
    <div className="custom-select-labels">
      <span className={innerOption.customLabel} />
      <div>{innerOption.label}</div>
    </div>
  )
};

export default CreateEditInterview;