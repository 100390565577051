import React, { useState, useEffect } from 'react';
// react library for routing
import { Link } from 'react-router-dom';
// reactstrap components
import {
	UncontrolledCollapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
	Button as ReactstrapButton
} from 'reactstrap';
import { useUser, useOrganization, useFirestoreDoc } from '../../util/firebase';
import Button from '../General/Buttons';
import { IOrganization } from '../../models/User.models';

const LoginButton = () => {
	const [user, hasUser] = useUser();
	if (!hasUser) {
		return null;
	}

	return (
		<>
			{user && !user.isAnonymous ? (
				<NavItem className="d-lg-block ml-lg-4">
					<NavLink to="/admin/dashboard" tag={Link}>
						<ReactstrapButton className="btn-neutral btn-icon" color="default">
							<span className="btn-inner--icon">
								<i className="fas fa-lock mr-2" />
							</span>
							<span className="nav-link-inner--text">Account</span>
						</ReactstrapButton>
					</NavLink>
				</NavItem>
			) : (
				<NavItem className="d-lg-block ml-lg-4">
					<NavLink to="/auth/login" tag={Link}>
						<ReactstrapButton className="btn-neutral btn-icon" color="default">
							<span className="btn-inner--icon">
								<i className="fas fa-lock mr-2" />
							</span>
							<span className="nav-link-inner--text">Login</span>
						</ReactstrapButton>
					</NavLink>
				</NavItem>
			)}
		</>
	);
};

export class IndexNavbar extends React.Component {
	render() {
		return (
			<>
				<Navbar className="navbar-horizontal navbar-main navbar-dark navbar-transparent" expand="lg" id="navbar-main">
					<Container>
						<NavbarBrand to="/" tag={Link}>
							<img alt="..." src={require('assets/img/brand/orbit-logo-light.png')} />
						</NavbarBrand>
						<button
							aria-controls="navbar-collapse"
							aria-expanded={false}
							aria-label="Toggle navigation"
							className="navbar-toggler"
							data-target="#navbar-collapse"
							data-toggle="collapse"
							id="navbar-collapse"
							type="button"
						>
							<span className="navbar-toggler-icon" />
						</button>
						<UncontrolledCollapse className="navbar-custom-collapse" navbar toggler="#navbar-collapse">
							<div className="navbar-collapse-header">
								<Row>
									<Col className="collapse-brand" xs="6">
										<Link to="/admin/dashboard">
											<img alt="..." src={require('assets/img/brand/orbit-logo-dark.png')} />
										</Link>
									</Col>
									<Col className="collapse-close" xs="6">
										<button
											aria-controls="navbar-collapse"
											aria-expanded={false}
											aria-label="Toggle navigation"
											className="navbar-toggler"
											data-target="#navbar-collapse"
											data-toggle="collapse"
											id="navbar-collapse"
											type="button"
										>
											<span />
											<span />
										</button>
									</Col>
								</Row>
							</div>

							<hr className="d-lg-none" />
							<Nav className="align-items-lg-center ml-lg-auto" navbar>
								<NavItem>
									<NavLink className="nav-link-icon" href="https://www.facebook.com" id="tooltip601201423" target="_blank">
										<i className="fab fa-facebook-square" />
										<span className="nav-link-inner--text d-lg-none">Facebook</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip601201423">
										Like us on Facebook
									</UncontrolledTooltip>
								</NavItem>
								<NavItem>
									<NavLink className="nav-link-icon" href="https://www.instagram.com" id="tooltip871243015" target="_blank">
										<i className="fab fa-instagram" />
										<span className="nav-link-inner--text d-lg-none">Instagram</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip871243015">
										Follow us on Instagram
									</UncontrolledTooltip>
								</NavItem>
								<NavItem>
									<NavLink className="nav-link-icon" href="https://twitter.com/" id="tooltip366258619" target="_blank">
										<i className="fab fa-twitter-square" />
										<span className="nav-link-inner--text d-lg-none">Twitter</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip366258619">
										Follow us on Twitter
									</UncontrolledTooltip>
								</NavItem>
								<NavItem>
									<NavLink className="nav-link-icon" href="https://github.com/" id="tooltip931502898" target="_blank">
										<i className="fab fa-github" />
										<span className="nav-link-inner--text d-lg-none">Github</span>
									</NavLink>
									<UncontrolledTooltip delay={0} target="tooltip931502898">
										Star us on Github
									</UncontrolledTooltip>
								</NavItem>
								<LoginButton />
							</Nav>
						</UncontrolledCollapse>
					</Container>
				</Navbar>
			</>
		);
	}
}

function useWindowSize() {
	const isClient = typeof window === 'object';

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};
	}

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return;
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return windowSize;
}

interface LoginInterface {
	white?: Boolean;
}

const LoginButtonFlex = (props: LoginInterface) => {
	const [user, hasUser] = useUser();
	const orgId: any = useOrganization();
	const org = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`);

	const size = useWindowSize();
	if (!hasUser) {
		return null;
	}

	if (size.width && size.width < 800) {
		return (
			<>
				{user && !user.isAnonymous ? (
					<>
						<h2 className={`navbar-user ${props.white ? 'fg-grey' : ''}`}>{org && org.name}</h2>
						<Link to="/admin/dashboard">
							{user.photoURL ? (
								<img className="navbar-icon" src={user.photoURL} />
							) : (
								<div className="no-photo">
									<i className="fas fa-user" />
									<div className="navbar-icon" />
								</div>
							)}
						</Link>
					</>
				) : (
					<>
						<Link to="/auth/login">
							<div className="no-photo">
								<i className="fas fa-user" />
								<div className="navbar-icon bg-grey" />
							</div>
						</Link>
					</>
				)}
			</>
		);
	}

	return (
		<>
			{user && !user.isAnonymous ? (
				<>
					<Link to="/admin/dashboard">
						<h2 className={`navbar-user ${props.white ? 'fg-grey' : ''}`}>{org && org.name}</h2>
					</Link>
					<Link to="/admin/dashboard">
						{user.photoURL ? (
							<img className="navbar-icon" src={user.photoURL} />
						) : (
							<div className="no-photo">
								<i className="fas fa-user" />
								<div className="navbar-icon" />
							</div>
						)}
					</Link>
				</>
			) : (
				<>
					<div className="flex-horizontal justify-between">
						{/* <Link to="/auth/pricing">
              <Button basic className={props.white ? 'fg-grey' : ''}>
                Pricing
              </Button>
            </Link> */}
						<Link to="/auth/login">
							<Button white>Log In</Button>
						</Link>
						<Link to="/auth/demo">
							<Button primary>Request a Demo</Button>
						</Link>
					</div>
				</>
			)}
		</>
	);
};
interface IIndexNavbarFlex {
	title: string;
	className?: string;
	white?: Boolean;
	transparent?: Boolean;
}

const IndexNavbarFlex = (props: IIndexNavbarFlex) => {
	if (props.transparent) {
		return (
			<nav className={`orbit-navbar bg-transparent  fade-in-fast ${props.className || ''}`}>
				<div className="flex-horizontal flex-center">
					<Link to="/">
						<img className="navbar-brand" src={require('assets/img/brand/orbit-logo-light.png')} />
					</Link>
					<h1 className="navbar-title">{props.title}</h1>
				</div>
				<div className="flex-horizontal flex-center">
					<LoginButtonFlex white={props.white} />
				</div>
			</nav>
		);
	}

	if (props.white) {
		return (
			<nav className={`orbit-navbar bg-white ${props.className || ''}`}>
				<div className="flex-horizontal flex-center">
					<Link to="/">
						<img className="navbar-brand" src={require('assets/img/brand/orbit-logo-dark.png')} />
					</Link>
					<h1 className="navbar-title">{props.title}</h1>
				</div>
				<div className="flex-horizontal flex-center">
					<LoginButtonFlex white={props.white} />
				</div>
			</nav>
		);
	}

	return (
		<nav className={`orbit-navbar ${props.className || ''}`}>
			<div className="flex-horizontal flex-center">
				<Link to="/">
					<img className="navbar-brand" src={require('assets/img/brand/orbit-logo-light.png')} />
				</Link>
				<h1 className="navbar-title">{props.title}</h1>
			</div>
			<div className="flex-horizontal flex-center">
				<LoginButtonFlex white={props.white} />
			</div>
		</nav>
	);
};

export default IndexNavbarFlex;
