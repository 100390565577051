import React from 'react';
import {default as Button, IButtonProps} from './Buttons';

interface IModalProps extends React.HTMLAttributes<React.Component> {
  open: Boolean;
  title: string;
  onClose: () => void;
  onSubmit?: () => void;
  hideClose?: Boolean;
  hideSubmit?: Boolean;
  submitProps?: IButtonProps;
  closeProps?: IButtonProps;
  customCloseText?: string;
  customSubmitText?: string;
  customCloseClass?: string;
  customSubmitClass?: string;
}

const Modal = (props: IModalProps) => {
  const {
    open,
    title,
    onClose,
    onSubmit,
    hideClose,
    hideSubmit,
    customCloseClass,
    customSubmitClass,
    customCloseText,
    customSubmitText
  } = props;

  return (
    <>
      <div onClick={onClose} className={`orbit-modal-bg ${open ? "open" : "closed"}`} />
      <div className={`orbit-modal-container ${open ? "open" : "closed"}`}>
        <div className={`orbit-modal ${open ? "open" : "closed"} flex-vertical`}>
          <button onClick={onClose} className="close">
            ×
          </button>
          <div className="orbit-modal-header flex-horizontal justify-between">
            <h2>{title}</h2>
          </div>

          <div className="orbit-modal-body flex-horizontal justify-between">{props.children}</div>

          <div className="orbit-modal-footer flex-horizontal justify-between">
          {!hideClose && (
              <Button {...props.closeProps} onClick={onClose} className={customCloseClass} green>
                {customCloseText || 'Cancel'}
              </Button>
            )}
            {!hideSubmit && (
              <Button {...props.submitProps} onClick={() => {onSubmit && onSubmit();}} className={customSubmitClass} primary>
                {customSubmitText || 'Submit'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const ConfirmModal = (props: IModalProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose} title="Are you sure?" customSubmitText="Yes" customCloseText="No" {...props}>
      {props.children}
    </Modal>
  );
};

export default Modal;
