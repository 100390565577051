import React from 'react';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: Boolean;
  green?: Boolean;
  white?: Boolean;
  basic?: Boolean;
  danger?: Boolean;
  onClick?: (e: any) => any;
  className?: string;
  children?: any;
}

const Button = (props: IButtonProps) => {
  const { primary, danger, green, white, basic, className, type, disabled } = props;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={props.onClick}
      className={`orbit-button ${primary ? 'primary' : ''} ${danger ? 'danger' : ''} ${green ? 'green' : ''} ${white ? 'white' : ''} ${
        basic ? 'basic' : ''
      } ${className || ''}`}
    >
      {props.children}
    </button>
  );
};

export const PlayButton = (props: IButtonProps) => {
  return (
    <div className={`play-button-container ${props.className || ""}`}>
      <div className="post-play" onClick={props.onClick}>
        <i className="ni ni-button-play" />
      </div>
    </div>
  );
};


export default Button;
