import { IFaceResult, IPlacementStatus } from './Interview.models';
import { RawDraftContentState } from 'draft-js';
import * as PersonalityInsightsV3 from 'ibm-watson/personality-insights/v3';

type IUserRole = 'admin' | 'reviewer' | 'editor';

export const UserRoleToString = (role: IUserRole): string => {
  switch (role) {
    case 'admin':
    default:
      return 'Admin';
    case 'reviewer':
      return 'Reviewer';
    case 'editor':
      return 'Editor';
  }
};

export interface IUser {
  name?: string;
  email: string;
  role: IUserRole;
  department?: string;
  avatar?: string;
  orgOwner: boolean;
}

export interface IFacialFeatures {
  [key: string]: boolean;
}

export interface IResumeKeyword {
  word: string;
  score: number;
  type: string;
}

export const GetResumeKeywordStrapColor = (key: IResumeKeyword) => {
  switch (key.type) {
    case 'OTHER':
    default:
      return '#C1E1DC';
    case 'PERSON':
      return '#FFCCAC';
    case 'WORK_OF_ART':
      return '#FFEB94';
    case 'LOCATION':
      return '#FDD475';
    case 'CONSUMER_GOOD':
      return '#CCB8C0';
  }
};

export interface ICandidate {
  avatar?: string;
  name: string;
  email: string;
  phone?: string;
  skills?: string;
  resume?: string;
  resumeUrl?: string;
  resumeText?: string[];
  resumeKeywords?: IResumeKeyword[];
  face?: IFaceResult;
  facialFeatures?: IFacialFeatures;
  links?: {
    type: string;
    url: string;
  }[];
  organizations: string[];
  extractionSuccess?: Boolean;
  orgId?: string;
  placement?: string;
  softDelete?: Boolean;
  education?: string;
  experience?: string;
  objective: string;
  projects: string;
  summary: string;
  certification: string;
  personality: PersonalityInsightsV3.Profile;
}

export interface IOrgCandidate {
  listId: string;
  index: number;
  name: string;
  placements: string[];
}

export interface ICandidateList {
  name: string;
  index: number;
  emailTemplate?: RawDraftContentState;
  emailTemplateHTML?: string;
  isDefault?: boolean;
  isInvited?: boolean;
  listId?: string;
}

export interface IOrgSettings {
  primaryColor: string;
  secondaryColor: string;
  notifications: boolean;
}

export type IPremiumTier = 'free' | 'starter' | 'enterprise' | 'premium' | 'business';

export interface IAddress {
  line1: string;
  line2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
}

export interface ISMTPConfig {
  host: string;
  port?: number;
  username: string;
  password: string;
}

export interface ISlackAccess {
  access_token?: string;
  error?: string;
  ok: Boolean;
  scope?: string;
  team_name?: string;
  authed_user?: { id?: string; access_token: string };
  bot_user_id?: string;
  team?: { id?: string; name?: string };
  team_id?: string;
  app_id?: string;
  enterprise_id?: string;
  incoming_webhook?: {
    url?: string;
    channel: string;
    channel_id: string;
    configuration_url?: string;
  };
}

export interface IIndeedAcces {
  ok: boolean;
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token: string;
}

export interface IOrganization {
  name: string;
  avatarUrl: string;
  address: Partial<IAddress>;
  phone: string;
  placeId?: string;
  location: { lat: number; lng: number };
  stripePaymentMethod?: string;
  stripeCustomer?: string;
  stripeSubscription?: string;
  premium: IPremiumTier;
  smtp?: ISMTPConfig;
  slackAccess?: ISlackAccess;
  indeedAccess?: IIndeedAcces;
  googlePlace?: google.maps.places.PlaceResult;
  userId: string;
  rating?: number;
}

export const PremiumToText = (premium: IPremiumTier) => {
  switch (premium) {
    case 'free':
      return 'Free';
    case 'starter':
      return 'Starter';
    case 'enterprise':
      return 'Enterprise';
    case 'premium':
      return 'Premium';
    case 'business':
      return 'Business';
  }

  return '';
};

export interface ICandidatePlacement {
  face?: IFaceResult;
  createdAt?: Date;
  score?: number;
  updatedAt?: Date;
  status?: IPlacementStatus;
}

export interface IPlacementRating {
  rating: number;
  responseId: string;
  user: IUserPlacementRating;
}

export interface IUserPlacementRating {
  userId: string;
}

export interface IPlacementHistory {
  updatedAt: Date;
  list: ICandidateList;
}
