import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IRoute } from '../../routes';
// import { useFirestoreDoc, useOrganization } from '../../util/firebase'; // Uncomment once payment functionality is working
// import { IOrganization, PremiumToText } from '../../models/User.models'; // Uncomment once payment functionality is working
import Icon from '../General/Icon';

interface ISidebarProps extends RouteComponentProps {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
  doneAnimating: boolean;
  routes: IRoute[];
  logo: {
    innerLink?: string;
    outterLink?: string;
    imgSrc: string;
    imgAlt: string;
  };
}

const Sidebar = (props: ISidebarProps) => {
  // const orgId = useOrganization(); // Uncomment once payment functionality is working
  // const org = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`); // Uncomment once payment functionality is working

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  const createLinks = (r: IRoute[], open: Boolean) => {
    return r.map((prop, key) => {
      if (prop.redirect || prop.hideInNav) {
        return null;
      }
      return (
        <div key={key} className={`orbit-dash-link ${activeRoute(prop.path) ? 'active' : ''}`}>
          <Link to={prop.path}>
            {prop.icon !== undefined ? (
              <>
                <Icon name={prop.icon} />
                {open && <span className="nav-link-text">{prop.name}</span>}
              </>
            ) : (
              prop.name
            )}
          </Link>
        </div>
      );
    });
  };

  const { sidenavOpen, doneAnimating } = props;

  //const { logo, sidenavOpen, doneAnimating } = props;
  // let navbarBrandProps;
  // if (logo && logo.innerLink) {
  //   navbarBrandProps = {
  //     to: logo.innerLink,
  //     tag: Link
  //   };
  // } else if (logo && logo.outterLink) {
  //   navbarBrandProps = {
  //     href: logo.outterLink,
  //     target: '_blank'
  //   };
  // }

  const scrollBarInner = (
    <div className={`flex-vertical orbit-sidebar fade-in-fast ${sidenavOpen ? 'open' : 'closed'}`}>
      <div className="flex-horizontal sidebar-header">
        <Icon onClick={props.toggleSidenav} name="menu" className="toggle-sidenav" />
        {sidenavOpen && doneAnimating && <img src={require('assets/img/brand/orbit-logo-dark.png')} />}
      </div>

      <div className="sidebar-links">{createLinks(props.routes, sidenavOpen && doneAnimating)}</div>

      {/*Uncomment beloww code after the payment functionality is sorted out */}
      {/* {sidenavOpen && doneAnimating && (
        <div className="sidebar-account">
          <h5>Account</h5>
          <div className="plan">
            <Icon name="plan" />
            <p>Your Plan{org ? ` (${PremiumToText(org.premium)})` : null}</p>
          </div>
        </div>
      )} */}
    </div>
  );

  return navigator.platform.indexOf('Win') > -1 ? <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar> : scrollBarInner;
};

export default Sidebar;
