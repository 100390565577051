import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/analytics';
import { useState, useEffect } from 'react';
import { FirebaseConfig } from '../config/config';
import { WithID } from '../models/Helpers.models';

firebase.initializeApp(FirebaseConfig);
firebase.analytics();

export const getOrganization = async (user: firebase.User) => {
  const snapshot = await firebase
    .firestore()
    .doc(`OrganizationUsers/${user.uid}`)
    .get();

  if (!snapshot || !snapshot.exists) {
    return;
  }

  return snapshot.data()!.organizationId;
};

export const useOrganization = () => {
  const [user] = useUser();
  const doc = useFirestoreDoc<{ organizationId: string }>(`OrganizationUsers/${user ? user.uid : ''}`);
  return doc ? doc.organizationId : '';
};

export const useFirestoreCollection = <T>(
  path: string,
  query: (query: firebase.firestore.Query) => firebase.firestore.Query = q => q,
  mutable: any[] = []
): [WithID<T>[], boolean, (val: WithID<T>[]) => void] => {
  const [collection, setCollection] = useState<WithID<T>[]>([]);
  const [loaded, setLoaded] = useState(false);

  const setOverride = (val: WithID<T>[]) => setCollection(val);

  useEffect(() => {
    if (path.indexOf('//') > -1) {
      setCollection([]);
      return;
    }

    let ref: firebase.firestore.Query = firebase.firestore().collection(path);

    ref = query(ref);

    let unloaded = false;
    const cb = ref.onSnapshot(data => {
      if (!unloaded) {
        setLoaded(true);
        setCollection(data.docs.map(d => ({ ...(d.data() as T), id: d.id })));
      }
    });

    return () => {
      unloaded = true;
      cb();
    };
  }, [path, ...mutable]);

  return [collection, loaded, setOverride];
};

export const useFirestoreDoc = <T>(path: string) => {
  const [doc, setDoc] = useState<WithID<T> | undefined>(undefined);

  useEffect(() => {
    if (path.indexOf('//') > -1 || path.endsWith('/')) {
      setDoc(undefined);
      return;
    }

    let unloaded = false;
    const ref = firebase.firestore().doc(path);
    const cb = ref.onSnapshot(data => {
      if (!unloaded) {
        setDoc({ ...(data.data() as T), id: data.id });
      }
    });

    return () => {
      unloaded = true;
      cb();
    };
  }, [path]);

  return doc;
};

export const useUser = (): [firebase.User | null, boolean] => {
  const [user, setUser] = useState<firebase.User | null>(firebase.auth().currentUser);
  const [loadedUser, setLoadedUser] = useState(false);
  useEffect(() => {
    let unloaded = false;
    const cb = firebase.auth().onIdTokenChanged(user => {
      if (!unloaded) {
        setLoadedUser(true);
        setUser(user);
      }
    });

    return () => {
      unloaded = true;
      cb();
    };
  }, []);

  return [user, loadedUser];
};
