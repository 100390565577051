import React, { PropsWithChildren } from 'react';
import { Alert } from 'reactstrap';

interface IFormErrorProps {}

export const FormError = (props: PropsWithChildren<IFormErrorProps>) => {
  return (
    <Alert color="danger" className="form-error">
      {props.children}
    </Alert>
  );
};
