import React, { useState, useRef } from 'react';
// core components
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import * as firebase from 'firebase/app';
import { IOrganization, ICandidateList, IAddress } from '../../models/User.models';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { GoogleMapsResponseToAddress } from '../../util/gmaps';
import Button from '../../components/General/Buttons';

interface IRegisterFormProps extends RouteComponentProps {}

interface IRegisterForm {
  email: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
}

const registerFormValidator = yup.object<IRegisterForm>({
  email: yup
    .string()
    .required('Please enter an email.')
    .email('Please enter a valid email.'),
  password: yup
    .string()
    .required('Please enter a password.')
    .min(6, 'Password must be at least 6 characters long.'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  agree: yup
    .bool()
    .required()
    .oneOf([true], 'Please agree to the Privacy Policy.')
});

const Register = (props: IRegisterFormProps) => {
  //const [focusedName, setFocusedName] = useState<boolean>();
  const [focusedEmail, setFocusedEmail] = useState<boolean>();
  const [focusedPassword, setFocusedPassword] = useState<boolean>();
  const [Loading, SetLoading] = useState<boolean>();
  const [place, setPlace] = React.useState<google.maps.places.PlaceResult>();
  const [parsedAddress, setParsedAddress] = React.useState<Partial<IAddress>>({});
  const form = useForm<IRegisterForm>({
    validationSchema: registerFormValidator,
    submitFocusError: true,
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      agree: false
    }
  });

  const notificationRef = useRef<typeof NotificationAlert>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = async (data: IRegisterForm) => {

    SetLoading(true);
    let credentials: firebase.auth.UserCredential;
    try {
      credentials = await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
    //   debugger;
    //   await credentials.user!.updateProfile({
    //     displayName: place ? place.name : '',
    // })
    } catch (error) {

      debugger;

      SetLoading(false);

      let options = {
        place: 'tc',
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {' '}
              Error
            </span>
            <span data-notify="message">{error.message}</span>
          </div>
        ),
        type: 'danger',
        icon: 'ni ni-bell-55',
        autoDismiss: 7
      };
      if (notificationRef.current) {
        notificationRef.current.notificationAlert(options);
      }
      return;
    }

    SetLoading(false);

    if (credentials && credentials.user) {
      //const user: IUser = { email: credentials.user.email!, role: 'admin' };

      debugger;
      console.log(place);

      const newOrg: IOrganization = {
        name: place ? place.name : '',
        avatarUrl: '',
        address: parsedAddress,
        phone: '',
        placeId: place ? place.place_id : '',
        location: { lat: 0, lng: 0 },
        premium: 'free',
        userId: credentials.user.uid
      };

      await firebase.firestore().runTransaction(async trans => {
        const org = await firebase
          .firestore()
          .collection('Organizations')
          .add(newOrg);

        const orgId = org.id;

        //await trans.set(firebase.firestore().doc(`Organizations/${orgId}/Team/${credentials.user!.uid}`), user);

        const newLists: ICandidateList[] = [
          { index: 0,
            name: 'Invited',
            isInvited: true},
          { index: 1,
            name: 'Applicants',
            isDefault: true}
        ];

        newLists.forEach(async list => {
          await firebase
          .firestore()
          .collection(`Organizations/${orgId}/Lists`)
          .add(list);
        })

        // this is also performed by the function `createTeamUser`
        // we do it here too because we need it immediately when the user registers and goes to /admin.
        await trans.set(firebase.firestore().doc(`OrganizationUsers/${credentials.user!.uid}`), { organizationId: orgId });
      });

      props.history.push('/admin/dashboard');
    }
  };

  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | undefined>(undefined);

  if (inputRef.current) {
    if (!autocomplete) {
      const ac = new google.maps.places.Autocomplete(inputRef.current, {
        types: ['establishment']
      });
      setAutocomplete(ac);
      ac.addListener('place_changed', () => {
        debugger;
        setPlace(ac.getPlace());
        setParsedAddress(GoogleMapsResponseToAddress(ac.getPlace()));
      });
    }
  }

  return (
    <>
      <div className="login-container flex-horizontal fade-in-fast">
        <div className="left-img">
          <a href="/">
            <img src={require('assets/img/brand/orbit-logo-dark.png')} />
          </a>
        </div>

        <div className="flex-vertical right-side flex-center">
          <h1>Revolutionize the way you find new talent for your business.</h1>

          <form onSubmit={form.handleSubmit(onSubmit)}>
            {/* <div>
              <input
                className={`login-input ${form.errors.name ? 'invalid' : ''} ${focusedName ? 'focused' : ''}`}
                ref={form.register}
                onFocus={() => setFocusedName(true)}
                onBlur={() => setFocusedName(false)}
                type="text"
                name="name"
                id="name"
                placeholder="Company Name"
              />
              {form.errors.name && <>{form.errors.name.message}</>}
            </div> */}
            <div>
              <input className={`login-input`} ref={inputRef} type="text" name="place" id="place" placeholder="Company Name" />
            </div>
            <div>
              <input
                ref={form.register}
                onFocus={() => setFocusedEmail(true)}
                onBlur={() => setFocusedEmail(false)}
                type="email"
                name="email"
                id="email"
                className={`login-input ${form.errors.email ? 'invalid' : ''} ${focusedEmail ? 'focused' : ''}`}
                placeholder="Email"
              />
              {form.errors.email && <>{form.errors.email.message}</>}
            </div>
            <div>
              <input
                className={`login-input ${form.errors.password ? 'invalid' : ''} ${focusedPassword ? 'focused' : ''}`}
                ref={form.register}
                onFocus={() => setFocusedPassword(true)}
                onBlur={() => setFocusedPassword(false)}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
              />
              {form.errors.password && <>{form.errors.password.message}</>}
            </div>
            <div>
              <input
                className={`login-input ${form.errors.confirmPassword ? 'invalid' : ''}`}
                ref={form.register}
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
              />
              {form.errors.confirmPassword && <>{form.errors.confirmPassword.message}</>}
            </div>
            <label className="checkbox">
              <input
                onChange={e => form.setValue('agree', e.target.checked, true)}
                ref={form.register}
                type="checkbox"
                name="agree"
                id="agree"
              />
              <span>
                I agree to the <Link to="/auth/privacy">Privacy Policy</Link>
              </span>
            </label>
            <Button
              // disabled={Loading || !!Object.keys(form.errors).length || !form.formState.dirtyFields.has('agree') || !place} //Place will be optional for the momment
              disabled={Loading || !!Object.keys(form.errors).length || !form.formState.dirtyFields.has('agree')}
              type="submit"
              primary
            >
              Create Account
            </Button>
          </form>
          <Link to="/auth/login" className="existing-account">Already Have An Account?</Link>
        </div>
      </div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
    </>
  );
};

export default Register;
