import * as React from 'react';
import { WithID } from '../../models/Helpers.models';
import { IOrganization, IAddress } from '../../models/User.models';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import * as firebase from 'firebase/app';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Input, ModalFooter, Button, Row, Col } from 'reactstrap';
import { GoogleMapsResponseToAddress, AddressToString } from '../../util/gmaps';
import * as yup from 'yup';
import { FormError } from '../FormError';
import { useOrganization, useFirestoreDoc } from '../../util/firebase';

interface IOrgEditModalProps {
  org: WithID<IOrganization>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface IOrganizationForm {
  name: string;
  avatar: FileList;
  phone?: string;
}

const editOrgFormValidator = yup.object<IOrganizationForm>({
  avatar: yup.mixed().notRequired(),
  name: yup
    .string()
    .required('Please enter a name.')
    .min(3, 'Name must be at least 3 characters long.'),
  phone: yup.string().notRequired()
});

export const OrgEditModal = (props: IOrgEditModalProps) => {
  // const [user] = useUser(); // Uncomment and add useUser to last import once the 'Resend Email' button is uncommented
  const [savingOrg, setSavingOrg] = React.useState(false);
  const [avatar, setAvatarHook] = React.useState<FileList | undefined>(undefined);
  const [tempAvatar, setTempAvatar] = React.useState<string | undefined>(undefined);
  const setAvatar = (files: FileList) => {
    setAvatarHook(files);
    setTempAvatar(URL.createObjectURL(files[0]));
  };
  const [place, setPlace] = React.useState<string | undefined>(props.org.placeId);
  const [parsedAddress, setParsedAddress] = React.useState<Partial<IAddress>>(props.org.address);
  const [formattedPhone, setFormattedPhone] = React.useState<string>(props.org.phone);
  const editOrg = useForm<IOrganizationForm>({ defaultValues: props.org, validationSchema: editOrgFormValidator });
  const orgId = useOrganization();
  const org = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`);

  const closeOrganizationBtn = (
    <button className="close" onClick={() => props.setOpen(false)}>
      &times;
    </button>
  );

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | undefined>(undefined);

  if (inputRef.current) {
    if (!autocomplete) {
      const ac = new google.maps.places.Autocomplete(inputRef.current, {
        types: ['establishment']
      });
      setAutocomplete(ac);
      ac.addListener('place_changed', () => {
        const place = ac.getPlace();
        if (place.place_id) {
          setPlace(place.place_id);
        }
        setParsedAddress(GoogleMapsResponseToAddress(place));
      });
    }
  }

  const onOrganizationSave = async (data: IOrganizationForm) => {
    debugger;
    setSavingOrg(true);
    const org: Partial<IOrganization> = {
      name: data.name,
      placeId: place,
      address: parsedAddress,
      //this line gets rid of all the extra characters provided by InputMask
      phone: data.phone? data.phone.replace(/[^\d]/g, ''): data.phone
    };

    if (data.avatar && data.avatar.length) {
      const fileRef = firebase.storage().ref(`Organizations/${props.org.id}`);
      await fileRef.put(data.avatar[0], { contentType: data.avatar[0].type });
      const url: string = await fileRef.getDownloadURL();
      org.avatarUrl = url;
    }

    await firebase
      .firestore()
      .doc(`Organizations/${props.org.id}`)
      .update(org);

    setSavingOrg(false);
    props.setOpen(false);
  };
  
  const handletPhoneNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setFormattedPhone(e.target.value);
  }

  let avatarUrl;
  if (tempAvatar) avatarUrl = tempAvatar;
  else if (org && org.avatarUrl) avatarUrl = org.avatarUrl;
  else avatarUrl = require('assets/img/theme/user-default.png');

  return (
    <Modal size="lg" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
      <Form onSubmit={editOrg.handleSubmit(onOrganizationSave)}>
        <ModalHeader toggle={() => props.setOpen(!props.open)} close={closeOrganizationBtn}>
          Edit Company Details
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Row>
              <Col md="2" className="edit-avatar-container">
                <span className="avatar edit-avatar rounded-circle">
                  <img alt="avatar" src={avatarUrl} />
                </span>
              </Col>
              <Col>
                <label className="form-control-label" htmlFor="text">
                  Avatar
                </label>

                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileLang"
                    name="avatar"
                    lang="en"
                    type="file"
                    ref={editOrg.register({ required: false })}
                    onChange={e => setAvatar(editOrg.getValues().avatar)}
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    {avatar && avatar[0] ? avatar[0].name : 'Select file'}
                  </label>
                </div>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Company Name
            </label>

            <Input
              id="name"
              name="name"
              rows="8"
              innerRef={editOrg.register({ required: true })}
              placeholder="Enter a company name"
              type="text"
            />
          </FormGroup>
          {editOrg.errors.name && <FormError>{editOrg.errors.name.message}</FormError>}
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Address
            </label>

            <Input
              name="address"
              innerRef={inputRef}
              placeholder="Enter a company address"
              type="text"
              defaultValue={props.org && props.org.address && AddressToString(props.org.address)}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Phone number
            </label>

            <InputMask mask="(999) 999-9999" value={props.org && props.org.phone && formattedPhone} onChange={handletPhoneNumberChange} >
              <Input
                id="phone"
                name="phone"
                rows="8"
                innerRef={editOrg.register({ required: true })}
                placeholder="Enter a company phone number"
                type="text"
              />
            </InputMask>
          </FormGroup>
          {/*Bellow Code should be uncommented once we decide on how to proceed with unvalidate accounts*/}
          {/* {user && !user!.emailVerified ? (
            <div>
              <label className="form-control-label" htmlFor="text">
                Unverified
              </label>
              <br/>
              <Button
                color="primary"
                onClick={async (e: any) => {
                  e.preventDefault();
                  user.sendEmailVerification();
                }}
              >
                  Resend Email
                </Button>
            </div>
          ) : null} */}
          {editOrg.errors.phone && <FormError>{editOrg.errors.phone.message}</FormError>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={savingOrg}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={() => props.setOpen(!props.open)} disabled={savingOrg}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
