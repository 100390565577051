import React, { useState, useEffect, useRef, createRef } from 'react';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { ICandidate, IFacialFeatures, IPlacementRating, IOrgCandidate } from '../../models/User.models';
import { Trait } from 'ibm-watson/personality-insights/v3';
import { useOrganization, useFirestoreCollection, useFirestoreDoc, useUser } from '../../util/firebase';
import {
  IPlacementResponse,
  IInterviewQuestionResponse,
  IPlacement,
  IPlacementQuestion
} from '../../models/Interview.models';
import Select, { ValueType, ActionMeta } from 'react-select';
import RatingButtons from '../../components/Dash/RatingButtons';
import Comments from '../../components/Dash/Comments';
import PersonalityTraits from '../../components/Dash/PersonalityTraits';
import VideoPlayer from '../../components/Dash/VideoPlayer';
import '../../assets/scss/custom/candidate.scss';
import { sendInvite } from '../../components/Util/CandidateInfo';
import { EmptyNotice } from '../../components/Util/EmptyNotice';
import NotificationAlert from 'react-notification-alert';
import Moment from 'react-moment';
import { Tooltip } from 'reactstrap';

import { WithID } from '../../models/Helpers.models';
// import { CandidateDetailsResponseModal } from './CandidateDetailsResponseModal';
// import firebase from 'firebase/app';
// import { default as OrbitButton } from '../../components/General/Buttons';
// import { ConfirmModal } from '../../components/General/Modal';
interface ICandidateProps extends RouteComponentProps<{ id: string, placementId: string }> {}

interface ISelectOptions {
  value: string,
  label: string
}

const Candidate = (props: ICandidateProps) => {
  const [jobPostings, setJobPostings] = useState<ISelectOptions[]>([]);
  const [selectedJobPosting, setSelectedJobPosting] = useState<ISelectOptions>({value: "no-selection", label: "Select a Job"});
  const [candidatesForJob, setCandidatesForJob] = useState<ISelectOptions[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<ISelectOptions>({value: "no-selection", label: "Select a Job"});
  const [score, setScore] = useState(0);
  const size = useWindowSize();
  const orgId = useOrganization();
  const [user] = useUser();
  const id = props.match.params.id;
  const [candidateId, setCandidateId] = useState(id);
  const [placementId, setPlacementId] = useState(props.match.params.placementId);
  const [tempPlacementId, setTempPlacementId] = useState(props.match.params.placementId);
  const candidate = useFirestoreDoc<ICandidate>(`Candidates/${id}`);
  const candidateSelectRef = createRef<Select>();
  const [filteredAlternatePlacements, setFilteredAlternatePlacements] = useState<WithID<IPlacement>[]>([]);

  const [placements, loadedPlacements] = useFirestoreCollection<IPlacement>(
    `Organizations/${orgId}/Placements`,
    q => q.where('status', '==', 'active')
  );

  const placementResponse = useFirestoreDoc<IPlacementResponse>(
    `Organizations/${orgId}/Candidates/${id}/Placements/${placementId}`);
  
  const [orgCandidates, loadedOrgCandidates] = useFirestoreCollection<IOrgCandidate>(
    `Organizations/${orgId}/Candidates`,
    q => q.where('placements', 'array-contains', tempPlacementId), [tempPlacementId]
  );

  useEffect(() => {
    if(loadedPlacements && placements) {
      const tempPostings = placements.map(placement => {
        return {value: placement.id, label: placement.title}
        })
        setJobPostings(tempPostings);
      }
    }, [placements]);//useEffect will run once, and then, everytime 'placements' array changes

  useEffect(() => {
    if(placementResponse)
      setScore(placementResponse.score);
  }, [placementResponse]);

  useEffect(() => {
    if(jobPostings){
      let selectedJob = jobPostings.find(posting => posting.value === placementId);

      if(selectedJob)
        setSelectedJobPosting(selectedJob);
    }
  }, [jobPostings]);

  useEffect(() => {
    if(loadedOrgCandidates && orgCandidates && orgCandidates.length) {
      let tempOptionsCandidates = orgCandidates.map(tempCandidate => ({value: tempCandidate.id, label: tempCandidate.name}));

      setCandidatesForJob(tempOptionsCandidates);
    }
  }, [orgCandidates, selectedJobPosting]);

  useEffect(() => {
    if(candidatesForJob){
      let innerCandidate = candidatesForJob.find(candidateForJob => candidateForJob.value === candidateId);

      if(innerCandidate)
        setSelectedCandidate(innerCandidate);
    }
  }, [candidatesForJob]);

  useEffect(() => {
    let innerFilteredPlacements = placements.filter(placement => placement.id !== placementId);

    setFilteredAlternatePlacements(innerFilteredPlacements);
  }, [placements, loadedPlacements]);

  const handleJobChange = (option: ValueType<ISelectOptions>, action: ActionMeta<ISelectOptions>) => {
    let innerOption = option as ISelectOptions;

    if(innerOption){
      setTempPlacementId(innerOption.value);
      setSelectedJobPosting(innerOption);

      candidateSelectRef.current!.select.focus();
      candidateSelectRef.current!.select.openMenu("first");
    }
  }

  const handleCandidateChange = (option: ValueType<ISelectOptions>, action: ActionMeta<ISelectOptions>) => {
    let innerOption = option as ISelectOptions;

    if(innerOption) {
      props.history.push(`/admin/candidates/${innerOption.value}/${selectedJobPosting.value}`);
      setSelectedCandidate(innerOption);
      setPlacementId(selectedJobPosting.value);
      setCandidateId(innerOption.value);
    }
  }

  if (!candidate) {
    return <Loading />;
  }

  if (candidate && candidate.hasOwnProperty('softDelete') && candidate.softDelete === true) {
    return <Deleted />;
  }

  const displaySelects = () => {
    return (
      <>
        <div>
          <p>Job Posting</p>
          <Select
            className="orbit-select"
            styles={customSelectStyles}
            value={selectedJobPosting}
            onChange={handleJobChange}
            options={jobPostings}
            isSearchable={true}
          />
        </div>
        <div>
          <p>Candidate</p>
          <Select
            className="orbit-select"
            styles={customSelectStyles}
            value={selectedCandidate}
            options={candidatesForJob}
            isSearchable={true}
            onChange={handleCandidateChange}
            ref={candidateSelectRef}
          />
        </div>
      </>
    )
  }
 
  const displayPlacementResponses = () => {
    if(placementResponse)
        return <CandidatePlacement 
          placementId={placementId}
          orgId={orgId} 
          candidateId={candidateId} 
          key={`answer-${placementResponse.id}`} 
          userId={user? user.uid : ''} />
  }

  if (size.width && size.width < 1610) {
    return (
      <>
        <div className="contact-details-container">
          <div className="contact-details-filter-row">
            {displaySelects()}
          </div>
          <div className="contact-details-inner">
            <div className="contact-details">
              <h2>Candidate</h2>
              <CandidateCard
                score={score} 
                {...props} />
            </div>
            <div className="candidate-answers">
              {displayPlacementResponses()}
            </div>
            <div className="contact-details">
              <h2>Candidate Analysis</h2>
              <CandidateAnalysis 
                {...props}
                placements={filteredAlternatePlacements}
               />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="contact-details-container">
        <div className="contact-details-filter-row">
          {displaySelects()}
        </div>
        <div className="contact-details-inner">
          <div className="candidate-answers">
            {displayPlacementResponses()}
          </div>
          <div className="contact-details">
            <CandidateCard 
              score={score}
              {...props} />
            <CandidateAnalysis 
              {...props}
              placements={filteredAlternatePlacements}
               />
          </div>
        </div>
      </div>
    </>
  );
};

interface ICandidatePlacementProps {
  placementId: string;
  orgId: string;
  candidateId: string;
  userId: string;
}

const CandidatePlacement = (props: ICandidatePlacementProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<ISelectedAnswer>();
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedPlacementRatingId, setSelectedPlacementRatingId] = useState("");
  const [selectedResponseNum, setSelectedResponeNum] = useState(-1);
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const [selectedPlacement, setSelectedPlacement] = useState(props.placementId);
  const [loading, setLoading] = useState(true);

  const orgPlacement = useFirestoreDoc<IPlacement>(`Organizations/${props.orgId}/Placements/${selectedPlacement}`);
  const [candidatePlacementResponses, loadedCandidatePlacementResponses] =  useFirestoreCollection<IInterviewQuestionResponse>(
    `Organizations/${props.orgId}/Candidates/${props.candidateId}/Placements/${selectedPlacement}/Responses`);

  const [ratings, loadedRatings] = useFirestoreCollection<IPlacementRating>(
    `Organizations/${props.orgId}/Candidates/${props.candidateId}/Placements/${selectedPlacement}/Ratings`,
    q => q.where('user.userId', '==', props.userId).orderBy('responseId', 'asc')
    );

  useEffect(() => {
    if(props.placementId){
      // setLoading(true);
      setSelectedPlacement(props.placementId);
    }
  }, [props.placementId]);

  useEffect(() => {
    setLoading(false);
  }, [candidatePlacementResponses]);

  useEffect(() => {
    setLoading(true)
  }, [selectedPlacement]);

  const openModal = (selectedAnswer: ISelectedAnswer, selectRating: string, selectPlacementRating: string, responseNumber: number) => {
    setSelectedRating(selectRating);
    setSelectedPlacementRatingId(selectPlacementRating);
    setSelectedAnswer(selectedAnswer);
    setSelectedResponeNum(responseNumber);
    setIsVideoOpen(true);
  };

  const closeModal = () => {
    setSelectedAnswer(undefined);
    setSelectedRating("");
    setSelectedPlacementRatingId("");
    setSelectedResponeNum(-1);
    setIsVideoOpen(false);
  };

  return (
    <>
      {!loading && loadedCandidatePlacementResponses && candidatePlacementResponses && candidatePlacementResponses.length ?
        <h2>{orgPlacement? orgPlacement.title : ''} Answers</h2> : null
      }
      { !loading && loadedCandidatePlacementResponses &&
          candidatePlacementResponses && !candidatePlacementResponses.length &&
          <EmptyNotice message={"Candidate has no completed inteview for " + (orgPlacement? orgPlacement.title : '') + ", yet"} />}
      { !loading && loadedCandidatePlacementResponses &&
        candidatePlacementResponses.map((placementResponse, indx) => {
          const selectedAnswer: ISelectedAnswer = {
            thumbNail: placementResponse.thumbNail,
            videoUrl: placementResponse.videoUrl,
            response: placementResponse.transcript && placementResponse.transcript.transcript,
            question: orgPlacement!.questions[indx],
            score: placementResponse.score
          };

          let innerRating = loadedRatings && ratings && ratings.length && ratings.find(rating => rating.responseId === indx.toString())

          return <Answer 
            onClick={openModal} 
            key={`answer-${selectedAnswer.score}-${indx}`} 
            selectedAnswer={selectedAnswer} 
            number={indx}
            placementId={props.placementId}
            candidateId={props.candidateId}
            rated={innerRating? innerRating.rating : 0}
            placementRatingId={innerRating? innerRating.id : ""} />
        })}
        <CandidateAnswerModal 
          onClose={closeModal} 
          open={isVideoOpen} 
          answer={selectedAnswer}
          placementRatingId={selectedPlacementRatingId}
          candidateId={props.candidateId}
          rated={selectedRating}
          placementId={props.placementId}
          responseId={selectedResponseNum.toString()}
           />
    </>
  )
}

export interface ISelectedAnswer {
  videoUrl: string;
  thumbNail?: string;
  videoMime?: string;
  response: string;
  question: IPlacementQuestion;
  score: number;
}

export const RenderQuestion = (question: IPlacementQuestion) =>
  question.type === 'video' ? (
    <video className="video" controls>
      <source src={question!.text} type={question!.mimeType!} />
      Your browser does not support the video tag.
    </video>
  ) : (
    <>{question.text}</>
  );

export const Deleted = () => (
  <div className="activity-container flex-vertical dash-content">
    <div className="activity-header">
      <h6 className="activity-title">Invalid Candidate</h6>
      <div className="button-container flex-horizontal justify-between" />
    </div>
    <div className="feed-container">
      <div className="soft-delete-card">
        <i className="fas fa-times" />
        <h3>We're sorry for the inconvenience! This candidate does not exist!</h3>
      </div>
    </div>
  </div>
);

export const Loading = () => (
  <div className="activity-container flex-vertical dash-content">
    <div className="activity-header">
      <h6 className="activity-title">Loading Candiate...</h6>
      <div className="button-container flex-horizontal justify-between" />
    </div>
    <div className="feed-container">
      <div className="cards-spinner">
        <Spinner color="primary" />
      </div>
    </div>
  </div>
);

export interface IAnswerProps {
  selectedAnswer: ISelectedAnswer;
  number: number;
  candidateId: string;
  placementId: string;
  rated: number;
  placementRatingId: string;
  onClick: (selectedAnswer: ISelectedAnswer, selectRating: string, selectPlacementRating: string, responseNumber: number) => void;
}

export const Answer = (props: IAnswerProps) => {
  return (
    <div className="interview-answer-container">
      <div className="answer-inner">
        <div className="answer-thumbnail">
          <div className="thumbnail" style={{ backgroundImage: `url(${props.selectedAnswer.thumbNail})` }} />
          <div className="play-button-container">
            <div className="post-play" onClick={() => props.onClick(props.selectedAnswer, props.rated.toString(), props.placementRatingId, props.number)}>
              <i className="ni ni-button-play" />
            </div>
          </div>
        </div>
        <div className="answer-details">
          <div className="title">
            <h3 className="bold">Question {props.number + 1}</h3>
            <h3>{props.selectedAnswer.question.text}</h3>
            <p>{props.selectedAnswer.response}</p>
          </div>

        </div>
      </div>
      <div className="answer-inner">
      <RatingRow 
            rating={props.selectedAnswer.score} 
            placementId={props.placementId} 
            candidateId={props.candidateId} 
            responseId={props.number.toString()}
            rated={props.rated}
            placementRatingId={props.placementRatingId}
            candidatePicture={props.selectedAnswer.thumbNail} />
                </div>
    </div>
  );
};

interface RatingRowProps {
  rating: number;
  candidateId: string;
  placementId: string;
  responseId: string;
  rated: number;
  placementRatingId: string;
  candidatePicture?: string;
}
const RatingRow = (props: RatingRowProps) => {
  //const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  //const [filteredRatings, setFilteredRatings] = useState<IPlacementRating[]>([]);
  const orgId = useOrganization();
  const [user] = useUser();

  const [teamRatings, loadedTeamRatings] = useFirestoreCollection<IPlacementRating>(
    `Organizations/${orgId}/Candidates/${props.candidateId}/Placements/${props.placementId}/Ratings`,
    q => q.where("responseId", "==", props.responseId)
  );

  useEffect(() => {
    //let innerRatings = teamRatings.filter(teamRating => teamRating.user.userId !== user!.uid)

    //setFilteredRatings(innerRatings);
  }, [teamRatings, loadedTeamRatings, user]);

 // const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="rating-container">
      <div className="rating-top-row">
        <p>Rate Candidate Answer</p>
        <div className="rating">{(props.rating * 100).toFixed(0) + '%'}</div>
        <div className="open-rating-average" id={`${props.placementId}-${props.responseId}`}>View Rating Average</div>
        {/* <Tooltip
          placement="bottom" 
          isOpen={tooltipOpen} 
          autohide={false} 
          target={`${props.placementId}-${props.responseId}`} 
          toggle={toggle} 
          className="rating-tooltip"
          innerClassName="tooltip-answer-ratings" >

            {filteredRatings.map((rating, indx) => (
                <RatingsTooltipRow 
                  placementRating={rating} 
                  displayDivider={filteredRatings.length > 1 && indx < filteredRatings.length - 1} />
              ))
            }
            {filteredRatings.length === 0 &&
              <div className="tooltip-answer-inner">
                No ratings from other team members yet
              </div> }
        </Tooltip> */}
      </div>
      <RatingButtons 
        candidateId={props.candidateId} 
        placementId={props.placementId} 
        responseId={props.responseId}
        rated={props.rated}
        placementRatingId={props.placementRatingId} />
    </div>
  );
};

// interface IRatingsTooltipRowProps {
//   placementRating: IPlacementRating;
//   displayDivider: boolean;
// }

// const RatingsTooltipRow = (props: IRatingsTooltipRowProps) => {
//   const orgId = useOrganization();

//   const userOwner = useFirestoreDoc<IUser>(`Organizations/${orgId}/Team/${props.placementRating.user.userId}`);

//   return (
//     <>
//       <div className="tooltip-answer-inner">
//         <div className="author-rating">
//           <div className="author-photo" style={{ backgroundImage: `url(${userOwner ? userOwner.avatar : ''})` }} />
//           <p>{userOwner ? userOwner.name : ""}</p>
//           <div className="score">{DefaultText[props.placementRating.rating]}</div>
//         </div>
//       </div>
//       {props.displayDivider && <div className="tooltip-divider"/>}
//     </>
//   )
// }

export interface ICandidateCardProps extends RouteComponentProps <{ id: string, placementId: string }> {
  score: number;
}

export const CandidateCard = (props: ICandidateCardProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>(props.match.params.id);
  const candidate = useFirestoreDoc<ICandidate>(`Candidates/${selectedCandidateId}`);

  useEffect(() => {
    setSelectedCandidateId(props.match.params.id);
  }, [props.match.params.id]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div className="contact-card">
        <div className="contact-inner">
          <div className="contact">
            <div className="contact-photo" style={{ backgroundImage: `url(${candidate ? candidate.avatar : ''})` }} />
            <div className="contact-text">
              <h2 className="name">{candidate ? candidate.name : ''}</h2>
              <h5 className="email">{candidate ? candidate.email : ''}</h5>
            </div>
          </div>

          <div className="contact-card-divider" />

          <div className="contact-summary">
            <div className="overall-score">
              <div className="score">{(props.score * 100).toFixed(0)}%</div>
              <p>Overall Interview Score</p>
              <p id="learn-more-candidate">Learn More</p>
              <Tooltip placement="bottom" isOpen={tooltipOpen} autohide={false} target="learn-more-candidate" toggle={toggle} innerClassName="tooltip-candidate" >
                {text}
              </Tooltip>
            </div>
            <Comments id={props.match.params.id} placementId={props.match.params.placementId} />
            {candidate && (
              <Button className="download-resume" block href={candidate.resumeUrl} target="_blank" rel="noopener">Download Resume</Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export interface ICandidateAnalysisProps extends RouteComponentProps<{ id: string, placementId: string }> {
  placements: WithID<IPlacement>[];
  mobile?: boolean;
  width?: number;
}

export const CandidateAnalysis = (props: ICandidateAnalysisProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>(props.match.params.id);
  const [selectedPlacementId, setSelectedPlacementId] = useState<string>(props.match.params.placementId)
  const [personalityTraits, setPersonalityTraits] = useState<Trait[]>([]);
  const [facialFeatures, setFacialFeatures] = useState<IFacialFeatures | undefined>();
  const orgId = useOrganization();
  const candidate = useFirestoreDoc<ICandidate>(`Candidates/${selectedCandidateId}`);

  const [history, loadedHistory] = useFirestoreCollection<any>(
    `Organizations/${orgId}/Candidates/${selectedCandidateId}/Placements/${selectedPlacementId}/History`,
    q => q.orderBy('updatedAt', 'desc').limit(5)
    );

  useEffect(() => {
    setSelectedCandidateId(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    setSelectedPlacementId(props.match.params.placementId);
  }, [props.match.params.placementId]);

  useEffect(() => {

    if(candidate) {
      setFacialFeatures(candidate.facialFeatures);
      setPersonalityTraits(candidate.personality? candidate.personality.personality : []);
    }
  }, [candidate]);

  const features = () => { 
    if(facialFeatures){
      return <>
          <div className="visual-characteristics">
            <h2>Visual Characteristics</h2>
            <Container>
              <Row>
                {facialFeatures && Object.keys(facialFeatures).map(key => {
                    if(facialFeatures[key]){
                      return (
                        <Col key={key} md="4" sm="6" xs="12" >
                          <div className="char-row" key={`char-${key}`}>
                            <p className="val">{key}</p>
                            {/* <p className="char">yes</p> */}
                          </div>
                        </Col>
                      )
                    }
                  })
                }
              </Row>
            </Container>
          </div>
          <div className="contact-card-divider" />
        </>
    }

    return null;
  }

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const notificationRef = useRef<any>(undefined);

  const notifyInvited = (name: string) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {' '}
            Success!
          </span>
          <span data-notify="message">Your invite was sent and {name} should see it soon!</span>
        </div>
      ),
      type: 'info',
      icon: 'ni ni-bell-55',
      autoDismiss: 7
    };
    if (notificationRef.current) {
      notificationRef.current.notificationAlert(options);
    }
  };

  const sendInterview = async (placementId: string) => {
    const url = window.location.href;

    await sendInvite(url, placementId, candidate!.name, candidate!.email, orgId);

    notifyInvited(candidate!.name);
  }
  
  let maxBarWidth = 217;
  if (props.mobile && props.width) maxBarWidth = props.width / 3;
  return (
    <div className="candidate-analysis">
      <div className="analysis-inner">
        {personalityTraits.length > 0 &&
          <>
            <PersonalityTraits
              personalityTraits={personalityTraits}
              maxBarWidth={maxBarWidth}
              title="Personality Traits"
              tooltipPlacement="left"
              />
            <div className="contact-card-divider" />
          </>
          }

        <div className="keyword-rating">
          <h2>Keyword Rating</h2>
          <div className="keyword-rating-row">
            <div className="score">93%</div>
            <p>Overall Interview Score</p>
            <p id="keyword-learn-more">Learn More</p>
            <Tooltip placement="bottom" isOpen={tooltipOpen} autohide={false} target="keyword-learn-more" toggle={toggle} innerClassName="tooltip-candidate" >
              {text}
            </Tooltip>
          </div>
        </div>
        <div className="contact-card-divider" />

        {features()}

        {loadedHistory && history && history.length > 0 &&
          <>
            <div className="candidate-progression">
              <h2>Candidate Status</h2>
              {history.map((record, idx) => (
                <div className="char-row" key={`char-${record.id}-${idx}}`}>
                  <Moment fromNow className="val" date={record.updatedAt.toDate()} />
                  <p className="char">{record.list.name}</p>
                </div>
              ))}
            </div>
            <div className="contact-card-divider" />
          </>
        }

        {props.placements && props.placements.length > 0 &&
          <div className="alternate-careers">
            <h2>Alternate Career Suggestions</h2>
            {props.placements && props.placements.length &&
              props.placements.map((placement, indx) => (
              <div className="alt-career" key={`career-${placement.title}-${indx}`}>
                <p className="career">{placement.title}</p>
                <p className="send" onClick={() => sendInterview(placement.id)}>Send Interview</p>
              </div>
              ))}
          </div>
        }
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationRef} />
        </div>
      </div>
    </div>
  );
};

// const getSecondsMinutes = (time: number) => {
//   let minutes = Math.floor(time / 60) as number;
//   let seconds = Math.floor(time - minutes * 60) as number;
//   let minuteValue;
//   let secondValue;

//   if (minutes < 10) {
//     minuteValue = `0${minutes}`;
//   } else {
//     minuteValue = minutes;
//   }

//   if (seconds < 10) {
//     secondValue = `0${seconds}`;
//   } else {
//     secondValue = seconds;
//   }
//   return { minutes: minuteValue, seconds: secondValue };
// };

export interface ICandidateAnswerModalProps {
  answer?: ISelectedAnswer;
  open?: Boolean;
  onClose: () => void;
  candidateId: string;
  placementId: string;
  responseId: string;
  rated: string;
  placementRatingId: string;
}

export const CandidateAnswerModal = (props: ICandidateAnswerModalProps) => {
  let { answer, open } = props;
  // const [playing, setPlaying] = useState(true);
  // const [time, setTime] = useState("");
  // const [totalTime, setTotalTime] = useState("");
  const [closing, setClosing] = useState(false);
  const onClose = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      props.onClose();
    }, 285);
  };

  // const videoRef = createRef<HTMLVideoElement>();
  // const playPause = () => {
  //   if (videoRef && videoRef.current!.paused) {
  //     videoRef.current!.play();
  //   } else {
  //     videoRef.current!.pause();
  //   }
  //   setPlaying(videoRef.current!.paused);
  // };
  // const stop = () => {
  //   videoRef.current!.pause();
  //   videoRef.current!.currentTime = 0;
  //   setPlaying(false);
  // };
  // const volume = () => {
  //   console.log('volume');
  // };
  // const settings = () => {
  //   console.log('settings');
  // };
  // const fullscreen = () => {
  //   console.log('fullscreen');
  // };
  // const updateTime = () => {
  //   let currentTime = getSecondsMinutes(videoRef.current!.currentTime);
  //   // let totalTime = getSecondsMinutes(videoRef.current!.duration);
  //   setTime(`${currentTime.minutes}:${currentTime.seconds}`);
  //   // setTotalTime(`${totalTime.minutes}:${totalTime.seconds}`);
  // };

  if (!open || !answer) return <React.Fragment />;
  return (
    <div className={`candidate-video-answer-modal-container ${closing ? 'closing' : ''}`} onClick={onClose}>
      <div className="candidate-video-answer-modal" onClick={e => e.stopPropagation()}>
        <div className="video-container">
          <VideoPlayer playerOptions={
              { controls: true,
                fluid: true, autoplay: true,
                sources: [{
                  src: answer.videoUrl,
                  type: 'video/mp4'
                }],
                poster: answer.thumbNail
              }
            } />
          {/* <video ref={videoRef} onTimeUpdate={updateTime} src={answer.videoUrl} autoPlay controls />
          <div className="video-controls">
            <div className="left">
              {playing ? <i className="fas fa-pause" onClick={playPause} /> : <i className="fas fa-play" onClick={playPause} />}
              <i className="fas fa-stop" onClick={stop} />
              <div>
                {time || '0:00'}
              </div>
            </div>
            <div className="right">
              <i className="fas fa-volume-up" onClick={volume} />
              <i className="fas fa-cog" onClick={settings} />
              <i className="fas fa-expand" onClick={fullscreen} />
            </div>
          </div> */}
        </div>
        
        <div className="question-container">
          <h2>{answer.question.text}</h2>
          <p>{answer.response}</p>
        </div>
        <RatingRow 
          rating={answer.score}
          placementId={props.placementId} 
          candidateId={props.candidateId} 
          responseId={props.responseId.toString()}
          rated={Number(props.rated)}
          placementRatingId={props.placementRatingId} />
      </div>
    </div>
  );
};

const customSelectStyles = {
  control: (prov: any, state: any) => ({
    ...prov,
    borderRadius: 1,
    borderColor: '#ddd7d1'
  })
};

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default Candidate;

//DUMMY DATA
const text = "Lorem ipsum dolor sit amet consectetur adipisicing elit. A doloremque molestias harum distinctio culpa? Alias debitis ex, ad ea obcaecati, quae nisi aperiam eos ipsum neque labore quo aliquam et!"

//DUMMY DATA