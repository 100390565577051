import React, { useState, useEffect } from 'react';
import { useOrganization, useUser } from '../../util/firebase';
import { rateCandidate } from '../Util/CandidateInfo';

interface RatingText {
  [name: number]: string;
}

interface IRatingButtons {
  onChange?: (rating: number) => void;
  ratingScale?: number[];
  text?: Record<number, string>;
  placementId?: string;
  candidateId?: string;
  responseId?: string;
  rated: number;
  placementRatingId?: string | undefined;
}

const DefaultText: RatingText = {
  1: 'Very Weak',
  2: 'Somewhat Weak',
  3: 'Weak',
  4: 'Somewhat Strong',
  5: 'Strong',
  6: 'Very Strong'
};

const DefaultRatingScale: number[] = [1, 2, 3, 4, 5, 6];

const RatingButtons = ({ onChange, ratingScale, text, placementId, candidateId, responseId, rated, placementRatingId }: IRatingButtons) => {
  const [user] = useUser();
  const orgId = useOrganization();

  let [rating, setRating] = useState(rated);
  let scale = ratingScale ? ratingScale : DefaultRatingScale;
  let btnText = text ? text : DefaultText;
  const onClick = async (rtng: number) => {
    if (onChange) onChange(rtng);
    
    if(user && candidateId && placementId && responseId)
      await rateCandidate(orgId, candidateId, placementId, rtng, responseId, user, placementRatingId);
      
    setRating(rtng);
  };

  useEffect(() => {
    setRating(rated);
  }, [rated]);//Change the rating at mount, and everytime the 'rated' property changes

  return (
    <div className="rating-buttons-container">
      {scale.map((rtng, idx) => (
        <button
          onClick={() => onClick(rtng)}
          className={`rating-button ${rating === rtng ? 'active' : ''}`}
          key={`rating-button-${rating}-${idx}`}
        >
          {btnText[rtng]}
        </button>
      ))}
    </div>
  );
};

export {DefaultRatingScale, DefaultText, RatingButtons}
export default RatingButtons