import React from 'react';

interface ICardProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  headerTitle?: () => any;
  renderDropdown?: () => React.ReactElement;
  children?: any;
  scrollBody?: Boolean;
  noHeader?: Boolean;
  noDivider?: Boolean;
}

const Card = (props: ICardProps) => {
  if (props.scrollBody) {
    return (
      <div className={`placement-card scroll-body flex-vertical ${props.className || ''}`}>
        <div className="card-header">
          {!props.noHeader && (
            <div className={`placement-card-header flex-horizontal ${props.headerClassName || ''}`}>
              <h5 className="h3 mb-0">{props.headerTitle && props.headerTitle()}</h5>
              {props.renderDropdown && props.renderDropdown()}
            </div>
          )}
        </div>
        <div className="card-body vertical-scroll">
          <div className={`placement-card-body ${props.bodyClassName || ''}`}>{props.children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`placement-card flex-vertical ${props.className || ''}`}>
      {!props.noHeader && (
        <div className={`placement-card-header flex-horizontal ${props.headerClassName || ''}`}>
          <h5 className="h3 mb-0">{props.headerTitle && props.headerTitle()}</h5>
          {props.renderDropdown && props.renderDropdown()}
        </div>
      )}
      {!props.noDivider && <div className="card-divider" />}
      <div className={`placement-card-body ${props.bodyClassName || ''}`}>{props.children}</div>
    </div>
  );
};

export default Card;
