import React from 'react';

// core components
import Navbar from '../../components/Landing/LandingNavbar';
import { RouteComponentProps, Link } from 'react-router-dom';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { FROM_EMAIL, STRIPE_KEY } from '../../config/config';
import PaymentModal from './Payment';
import DowngradeModal from './Downgrade';
import { useOrganization, useFirestoreDoc, useUser } from '../../util/firebase';
import { IOrganization } from '../../models/User.models';
import '../../assets/scss/custom/pricing.scss';
import Button from '../../components/General/Buttons';

interface IPricingProps extends RouteComponentProps { }

interface IPricingTier {
	key: string;
	title: string;
	amount: Number;
	subtitle: string;
	bullets: Array<string>;
}

const PricingTiers: Array<IPricingTier> = [
	{
		key: 'free',
		title: 'Free',
		amount: 0,
		subtitle: 'forever!',
		bullets: ['Custom interview setup', '1 custom interview sharable link', '10 video recordings', 'A.I. interview coordinator']
	},
	{
		key: 'starter',
		title: 'Starter',
		amount: 99,
		subtitle: 'per month / billed yearly',
		bullets: ['Custom interview setup', '5 custom interview sharable link', '125 video recordings', 'Personalized A.I. hiring assistant']
	},
	{
		key: 'enterprise',
		title: 'Enterprise',
		amount: 499,
		subtitle: 'per month / billed yearly',
		bullets: [
			'Custom interview setups',
			'100 custom interview sharable link',
			'5000 video recordings',
			'Personalized A.I. hiring assistant',
			'Data analytics'
		]
	},
	{
		key: 'premium',
		title: 'Premium',
		amount: -1,
		subtitle: '',
		bullets: [
			'Unlimited interview setups',
			'Unlimited custom interview link',
			'Unlimited video recordings',
			'Personalized A.I. hiring assistant',
			'Data analytics'
		]
	}
];

interface ITestimonial {
	key: string;
	text: string;
	author: string;
	department: string;
	img: string;
}

const Testimonials: Array<ITestimonial> = [
	{
		key: 'one',
		text:
			'In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus. Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.',
		author: 'Bink Kunze',
		department: 'Nuclear Power Engineer',
		img: require('assets/img/pricing/team1.png')
	},
	{
		key: 'two',
		text:
			'Sed ante. Vivamus tortor. Duis mattis egestas metus. Aenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh. Quisque id justo sit amet sapien dignissim vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est.',
		author: 'Foss Arndell',
		department: 'Developer I',
		img: require('assets/img/pricing/team2.png')
	},
	{
		key: 'three',
		text:
			'Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui. Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti. Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris.',
		author: 'Lonnard Gres',
		department: 'Cost Accountant',
		img: require('assets/img/pricing/team3.png')
	}
];

const Pricing = (props: IPricingProps) => {
	const [open, setOpen] = React.useState(false);
	const [downgradeOpen, setDowngradeOpen] = React.useState(false);

	const [user] = useUser();
	const orgId = useOrganization();
	const organization = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`);
	const [currentTestimonialIdx, setTestIdx] = React.useState(0);
	const [animatingOutTestimonial, setAnimatingOutTestimonial] = React.useState(false);
	const [animatingInTestimonial, setAnimatingInTestimonial] = React.useState(false);
	const testimonial = Testimonials[currentTestimonialIdx];

	const changeTestIdx = (idx: any) => {
		setAnimatingOutTestimonial(true);

		setTimeout(() => {
			setAnimatingOutTestimonial(false);
			setAnimatingInTestimonial(true);
			setTestIdx(idx);

			setTimeout(() => {
				setAnimatingInTestimonial(false);
			}, 300);
		}, 300);
	};

	const renderBtn = (key: string) => {
		switch (key) {
			case 'free':
				if (user && organization && organization.premium === 'free') {
					return (
						<>
							<p className="current-tier">You are currently on the Free plan.</p>
							<Button primary className="w-100 price-button" disabled>
								Selected
							</Button>
						</>
					);
				}
				if (user && organization && (organization.premium === 'starter' || organization.premium === 'enterprise')) {
					return (
						<Button
							onClick={e => {
								e.preventDefault();
								setDowngradeOpen(true);
							}}
							green
							className="w-100 price-button"
						>
							Downgrade Subscription
						</Button>
					);
				}
				return (
					<Link to="/auth/register">
						<Button primary className="w-100 price-button">
							Start Now
						</Button>
					</Link>
				);
			case 'starter':
				if (user && organization && organization.premium === 'starter') {
					return (
						<>
							<p className="current-tier">You are currently on the Starter plan.</p>
							<Button primary className="w-100 price-button" disabled>
								Selected
							</Button>
						</>
					);
				}
				if (user && organization && organization.premium === 'enterprise') {
					return (
						<Button
							onClick={e => {
								e.preventDefault();
								setDowngradeOpen(true);
							}}
							green
							className="w-100 price-button"
						>
							Downgrade Subscription
						</Button>
					);
				}
				return (
					<Button
						onClick={e => {
							e.preventDefault();
							setOpen(true);
						}}
						primary
						className="w-100 price-button"
					>
						Try for Free
					</Button>
				);
			case 'enterprise':
				if (user && organization && organization.premium === 'enterprise') {
					return (
						<>
							<p className="current-tier">You are currently on the Enterprise plan.</p>
							<Button primary className="w-100 price-button" disabled>
								Selected
							</Button>
						</>
					);
				}

				return (
					<a href={'mailto:' + FROM_EMAIL}>
						<Button primary className="w-100 price-button">
							Contact Us
						</Button>
					</a>
				);
			case 'premium':
				if (user && organization && organization.premium === 'premium') {
					return (
						<>
							<p className="current-tier">You are currently on the Premium plan.</p>
							<Button primary className="w-100 price-button" disabled>
								Selected
							</Button>
						</>
					);
				}
				return (
					<a href={'mailto:' + FROM_EMAIL}>
						<Button className="w-100 price-button" primary>
							Contact Us
						</Button>
					</a>
				);
		}
	};

	return (
		<>
			<Navbar title="Pricing" white className="pricing-navbar fade-in-fast" />

			<div className="working-for-you flex-vertical flex-center fade-in-fast">
				<h1>Get Orbit Working For You.</h1>
				<h3>Choose the best plan for your business.</h3>

				<div className="pricing-cards flex-horizontal">
					{PricingTiers.map((tier, idx) => {
						if (tier.key === 'starter') {
							return (
								<div key={`${idx}-${tier.title}`} className="best-value flex-vertical">
									<h5>BEST VALUE</h5>
									<div className="pricing-card">
										<h4>{tier.title}</h4>
										<hr />
										{tier.amount >= 0 && <h1>${tier.amount}</h1>}
										{tier.amount < 0 && <h1 className="contact">Contact Sales</h1>}
										{tier.subtitle !== '' && <p>{tier.subtitle}</p>}

										<ul className="bullets">
											{tier.bullets.map((blt, idx2) => {
												return (
													<li key={`${idx2}-${blt}`} className="bullet">
														{blt}
													</li>
												);
											})}
										</ul>
										{renderBtn(tier.key)}
									</div>
								</div>
							);
						}

						return (
							<div key={`${idx}-${tier.title}`} className="pricing-card flex-vertical">
								<h4>{tier.title}</h4>
								<hr />
								{tier.amount >= 0 && <h1>${tier.amount}</h1>}
								{tier.amount < 0 && <h1 className="contact">Contact Sales</h1>}
								{tier.subtitle !== '' && <p>{tier.subtitle}</p>}

								<ul className="bullets">
									{tier.bullets.map((blt, idx2) => {
										return (
											<li key={`${idx2}-${blt}`} className="bullet">
												{blt}
											</li>
										);
									})}
								</ul>
								{renderBtn(tier.key)}
							</div>
						);
					})}
				</div>
			</div>

			<div className="flex-vertical flex-center the-team">
				<div
					className={`testimonial${animatingOutTestimonial ? ' animating-out' : ''}${animatingInTestimonial ? ' animating-in' : ''}`}
					key={`text-${testimonial.key}`}
				>
					<p>“{testimonial.text}”</p>
					<h4>{testimonial.author}</h4>
					<h4>{testimonial.department}</h4>
				</div>
				<div className="flex-horizontal flex-center justify-around">
					{Testimonials.map((testimonial, idx) => (
						<img className={currentTestimonialIdx === idx ? 'selected' : undefined} onClick={() => changeTestIdx(idx)} key={`img-${testimonial.key}`} src={testimonial.img} />
					))}
				</div>
			</div>

			<div className="revolutionize-container alt">
				<div className="flex-vertical flex-center">
					<p>Revolutionize the way you find new talent for your business.</p>
					<Link to="/auth/demo">
						<Button primary className="m-t-30">
							Request a Demo
						</Button>
					</Link>
				</div>
			</div>

			<StripeProvider apiKey={STRIPE_KEY}>
				<Elements>
					<>
						<PaymentModal open={open} setOpen={setOpen} orgId={orgId} />
						<DowngradeModal open={downgradeOpen} setOpen={setDowngradeOpen} />
					</>
				</Elements>
			</StripeProvider>
		</>
	);
};

export default Pricing;
