import React from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import LandingFooter from '../components/Landing/LandingFooter';
import { InterviewPage } from '../views/Landing/Interview';

interface IInterviewProps extends RouteComponentProps {}

class InterviewLayout extends React.Component<IInterviewProps> {
  private mainContent: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    document.body.classList.add('bg-default');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }
  componentDidUpdate(props: IInterviewProps) {
    if (props.history.location.pathname !== props.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement!.scrollTop = 0;
      if (this.mainContent.current) {
        this.mainContent.current.scrollTop = 0;
      }
    }
  }

  render() {
    return (
      <>
        <div className="main-content" ref={this.mainContent}>
          <Switch>
            <Route path="/interview/:org/:placement" component={InterviewPage} />
            <Redirect path="*" to="/" />
          </Switch>
        </div>
        <LandingFooter {...this.props} />
      </>
    );
  }
}

export default InterviewLayout;
