import * as React from 'react';
import { WithID } from '../../models/Helpers.models';
import { IOrganization } from '../../models/User.models';
import * as firebase from 'firebase/app';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import OrbitButton from '../General/Buttons';
import Icon from '../General/Icon';
import { useOrganization, useFirestoreDoc } from '../../util/firebase';

interface IOrgIntegrationModalProps {
  org: WithID<IOrganization>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const OrgIntegrationModal = (props: IOrgIntegrationModalProps) => {
  const orgId = useOrganization();
  const org = useFirestoreDoc<IOrganization>(`Organizations/${orgId}`);
  const slackConnected = !!org && !!org.slackAccess && !!org.slackAccess.incoming_webhook && !!org.slackAccess.incoming_webhook.url;
  const indeedConnected = !!org && !!org.indeedAccess && !!org.indeedAccess.access_token;



  const disconnectSlack = () => {
    if (org) {
      org.slackAccess = {
        ok: false
      };
      firebase
        .firestore()
        .doc(`Organizations/${orgId}`)
        .update(org);
    }
  };

  const disconnectIndeed = () => {
    // if (org) {
    //   org.indeedAccess = {
    //     ok: false
    //   };
    //   firebase
    //     .firestore()
    //     .doc(`Organizations/${orgId}`)
    //     .update(org);
    // }
  };


  const closeOrganizationBtn = (
    <button className="close" onClick={() => props.setOpen(false)}>
      &times;
    </button>
  );

  return (
    <>
      <Modal size="lg" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
        <ModalHeader toggle={() => props.setOpen(!props.open)} close={closeOrganizationBtn}>
          Edit Company Integrations
        </ModalHeader>
        <ModalBody>
          <div className="flex-vertical justify-between">
            <div className="flex-horizontal justify-between integration-row">
              <img className="integration-img" src={require('assets/img/integrations/slack.png')} />

              <OrbitButton
                primary={!slackConnected}
                green={slackConnected}
                onClick={
                  slackConnected
                    ? disconnectSlack
                    : () =>
                        (window.location.href =
                          `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=chat:write,incoming-webhook,remote_files:read,remote_files:share,remote_files:write,files:read,files:write&user_scope=channels:read,chat:write,im:history,im:read,remote_files:read,remote_files:share,remote_files:write,users:read,users:read.email,files:read,files:write&redirect_uri=${process.env.REACT_APP_FUNCTIONS_HOST}/slackAuth&state=${orgId}`)
                        }

                       >
                <Icon name="slack" className={slackConnected ? 'connected' : ''} />
                {slackConnected ? 'Disconnect' : 'Connect to Slack'}
              </OrbitButton>
            </div>
            {/* <div className="flex-horizontal justify-between integration-row">
              <img className="integration-img" src={require('assets/img/integrations/linkedin.png')} />

              <OrbitButton primary>Connect to LinkedIn</OrbitButton>
            </div> */}
            <div className="flex-horizontal justify-between integration-row">
              <img className="integration-img wide" src={require('assets/img/integrations/indeed.png')} />
              <OrbitButton
                primary={!indeedConnected}
                green={indeedConnected}
                onClick={
                  indeedConnected
                    ? disconnectIndeed
                    : () =>
                        (
                          
                          window.location.href =
                          `https://secure.indeed.com/account/oauth?client_id=${process.env.REACT_APP_INDEED_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUNCTIONS_HOST}/indeedAuth&state=${orgId}`)
                        }

                       >
                <Icon name="slack" className={indeedConnected ? 'connected' : ''} />
                {indeedConnected ? 'Disconnect' : 'Connect to Indeed'}
              </OrbitButton>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => props.setOpen(!props.open)} disabled={false}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
