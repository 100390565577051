import * as React from 'react';
import { WithID } from '../../models/Helpers.models';
import { IOrganization } from '../../models/User.models';
import { Modal, Form, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button } from 'reactstrap';
import * as firebase from 'firebase/app';
import { ISupportTicket } from '../../models/Support.models';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FormError } from '../FormError';

interface IDashNavbarSupportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  org: WithID<IOrganization>;
  user: firebase.User | undefined;
}

const ticketFormValidator = yup.object<ISupportTicket>({
  userId: yup.mixed().notRequired(), // attached after form submission
  subject: yup
    .string()
    .required('Please enter a subject.')
    .min(3, 'Subject must be at least 3 characters long.'),
  message: yup
    .string()
    .required('Please enter a message.')
    .min(20, 'Message must be at least 20 characters long.')
});

export const DashNavbarSupportModal = (props: IDashNavbarSupportModalProps) => {
  const ticket = useForm<ISupportTicket>({ validationSchema: ticketFormValidator });
  const [sendingSupport, setSendingReport] = React.useState(false);
  const onSupportRequestSave = async (data: ISupportTicket) => {
    if (props.user) {
      data.userId = props.user.uid;
    }
    setSendingReport(true);
    await firebase
      .firestore()
      .collection(`SupportTickets`)
      .add(data);
    setSendingReport(false);
    props.setOpen(false);
  };

  const closeSupportBtn = (
    <button className="close" onClick={() => props.setOpen(false)}>
      &times;
    </button>
  );

  return (
    <Modal size="lg" isOpen={props.open} toggle={() => props.setOpen(!props.open)}>
      <Form onSubmit={ticket.handleSubmit(onSupportRequestSave)}>
        <ModalHeader toggle={() => props.setOpen(!props.open)} close={closeSupportBtn}>
          Create a support request
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Subject
            </label>

            <Input
              id="subject"
              name="subject"
              rows="8"
              defaultValue={''}
              innerRef={ticket.register({ required: true })}
              placeholder="Enter a subject"
              type="text"
            />
          </FormGroup>
          {ticket.errors.subject && <FormError>{ticket.errors.subject.message}</FormError>}
          <FormGroup>
            <label className="form-control-label" htmlFor="text">
              Message
            </label>

            <Input
              id="message"
              name="message"
              rows="8"
              defaultValue={''}
              innerRef={ticket.register({ required: true })}
              placeholder="Enter a message"
              type="textarea"
            />
          </FormGroup>
          {ticket.errors.message && <FormError>{ticket.errors.message.message}</FormError>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={sendingSupport}>
            Send
          </Button>{' '}
          <Button color="secondary" onClick={() => props.setOpen(!props.open)} disabled={sendingSupport}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
