import React from 'react';
import LandingNavbar from '../../components/Landing/LandingNavbar';
import { RouteComponentProps } from 'react-router';
import { useFirestoreCollection } from '../../util/firebase';
import AuthFooter from '../../components/Landing/LandingFooter';
import { IPlacement } from '../../models/Interview.models';
import { IOrganization } from '../../models/User.models';
import { PlacementCard } from '../Dash/Placements';
import { Container, Row, Col } from 'reactstrap';
import Loading from 'react-fullscreen-loading';
// import Button from '../../components/General/Buttons';

const Loader = <Loading loading background="rgb(131, 83, 225)" loaderColor="#FFFFFF" />;

interface IOrganizationInteviewProps extends RouteComponentProps{
  orgId: string;
  orgName: string;
}

export const LandingInterviews = (props: RouteComponentProps) => {
  const [organizations, loadedOrganization] = useFirestoreCollection<IOrganization>('Organizations');

  if (!organizations) {
    return Loader;
  }

  return(
    <div className="main-content">
      <LandingNavbar title="Interviews" />
      <div className="centered-layout">
        <div className="interviews-pad centered-content">
          <Container fluid>
            {loadedOrganization && organizations.map(organization => {
              return(
                <OrganizationInterviews orgId={organization.id} orgName={organization.name} key={organization.id} {...props} />
              );
            })}
          </Container>
        </div>
      </div>
      <AuthFooter {...props}/>
    </div>
  );
};

const OrganizationInterviews = (props: IOrganizationInteviewProps) => {
  let today = new Date();
  const [placements, loadedPlacements] = useFirestoreCollection<IPlacement>(
    `Organizations/${props.orgId}/Placements`, 
    q => q.where('status', '==', 'active').where('expiryDate', '>=', today));

  return(
    <>
      {loadedPlacements && (
        <>
          {placements && placements.length > 0 && 
            <Row>
              <Col>
                <div className="activity-header">
                  <h2 className="section-header">{props.orgName}</h2>
                </div>
              </Col>
            </Row>
          }
          <Row>
            {loadedPlacements && placements.map(placement => {
              return (
                <PlacementCard
                  placement={placement}
                  key={placement.id}
                  // candidates={candidates}
                  orgId={props.orgId}
                  history={props.history}
                  // displayedOnDashboard={true}
                  bodyLinksRemoved={true}
                  className="interview-dimentions"
                  displayedOnInterviews
                />
              )
            })}
          </Row>
        </>
      )}
    </>
  );
};

{/* <Button className="primary" onClick={() => props.history.push(`/interview/${props.orgId}/${placement.id}`)}>
                        Take Interview
                      </Button> */}