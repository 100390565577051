import * as firebase from 'firebase/app';
import { IFeedComment } from '../../models/Feed.models';
import { IPlacementRating } from '../../models/User.models';
import { ICandidateInvite } from '../../models/Interview.models';

export const postComment = async (
  userId: string,
  content: string,
  likes: [],
  orgId: string,
  postId: string
) => {
  const newComment: IFeedComment = {
    userId: userId,
    content: content,
    likes: likes,
    date: new firebase.firestore.Timestamp(Math.round(new Date().getTime() / 1000), 0)
  };

  await firebase
    .firestore()
    .collection(`Organizations/${orgId}/Feed/${postId}/Comments`)
    .add(newComment);
}

export const rateCandidate = async(
  orgId: string,
  candidateId: string,
  placementId: string,
  ratingValue: number,
  responseId: string,
  user: firebase.User,
  placementRatingId: string|undefined
) => {
  if(placementRatingId) {
    await firebase
      .firestore()
      .collection('Organizations')
      .doc(orgId)
      .collection('Candidates')
      .doc(candidateId)
      .collection('Placements')
      .doc(placementId)
      .collection('Ratings')
      .doc(placementRatingId)
      .set({
        rating: ratingValue,
        responseId: responseId,
        user: {
          userId: user.uid
        }
      } as IPlacementRating);
  }else {
    await firebase
      .firestore()
      .collection('Organizations')
      .doc(orgId)
      .collection('Candidates')
      .doc(candidateId)
      .collection('Placements')
      .doc(placementId)
      .collection('Ratings')
      .add({
        rating: ratingValue,
        responseId: responseId,
        user: {
          userId: user.uid
        }
      } as IPlacementRating);
  }
}

export const sendInvite = async (
  url: string,
  placementId: string,
  name: string,
  email: string,
  orgId: string
) => {
  const arr = url.split('/');
  const result = arr[0] + '//' + arr[2];
  const shareUrl = `${result}/interview/${orgId}/${placementId}`;

  const data: ICandidateInvite = {
    name: name,
    email: email,
    placement: placementId,
    url: shareUrl,
    dateCreated: new Date()
  };

  await firebase
        .firestore()
        .collection(`Organizations/${orgId}/CandidateInvites`)
        .add(data)
}