import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useOrganization, useFirestoreCollection, useFirestoreDoc } from '../../util/firebase';
import { IPlacement, IPlacementResponse } from '../../models/Interview.models';
import { WithID } from '../../models/Helpers.models';
import { IOrgCandidate, ICandidate } from '../../models/User.models';
import { PlacementCard } from './Placements';
import { Container, Row, Col, Spinner } from 'reactstrap';
// import Select from 'react-select';
import PersonalityTraits from '../../components/Dash/PersonalityTraits';
import { Trait } from 'ibm-watson/personality-insights/v3';
import { EmptyNotice } from '../../components/Util/EmptyNotice';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IDashboardProps extends RouteComponentProps{};

interface ISectionHeaderProps extends RouteComponentProps {
  sectionName: string;
  secondSectionName?: string;
  sectionLinkName?: string;
  sectionLink?: string;
}

const SectionHeader = (props: ISectionHeaderProps) => {
  return (
    <div className="activity-header">
      <h2 className="section-header">{props.sectionName}</h2>
      <div className="section-header-link flex-horizontal justify-between">
        <p className="link" onClick={() => props.history.push(props.sectionLink? props.sectionLink: '#')}>{props.sectionLinkName}</p>
      </div>
    </div>
  );
};

interface ICandidateItemProps {
  orgCandidate: WithID<IOrgCandidate>;
}

function CandidateItem(props: ICandidateItemProps) {
  const orgId = useOrganization();
  const candidate = useFirestoreDoc<ICandidate>(`Candidates/${props.orgCandidate.id}`);
  const placementId = props.orgCandidate.placements[0];
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateAvatar, setCandidateAvatar] = useState<string | undefined>("");

  const placement = useFirestoreDoc<IPlacement>(`Organizations/${orgId}/Placements/${placementId}`);
  const orgPlacement = useFirestoreDoc<IPlacementResponse>(
    `Organizations/${orgId}/Candidates/${props.orgCandidate.id}/Placements/${placementId}`
  );

  useEffect(() => {
    if(candidate) {
      setCandidateName(candidate.name);
      setCandidateEmail(candidate.email);
      setCandidateAvatar(candidate.avatar);
    }
  }, [candidate]);

  return (
    <div key={props.orgCandidate.id}>
      <div className="contact-details contact-card-margin" style={{marginLeft: 0 }}>
          <div className="contact-card">
            <div className="contact-inner">
              <div className="contact">
                <div className="contact-photo" style={{ backgroundImage: `url(${candidateAvatar})` }} />
                <div className="contact-text">
                  <h3 className="name">{candidateName}</h3>
                  <h4 className="list-email">{candidateEmail}</h4>
                </div>
              </div>

              <div className="contact-card-divider" />

              <div className="contact-summary">
                <div className="position">
                  <p>Position</p>
                  <p>{placement? placement.title : ''}</p>
                </div>
                <div className="status">
                  <p>Status</p>
                  <p className={'mr-1' + (orgPlacement && orgPlacement.status === 'started' ? ' fg-yellow' : ' fg-purple-darker')}>
                    {orgPlacement && orgPlacement.status === 'started' ? 'Started' : 'Complete'}
                  </p>
                </div>
                <Link 
                  to={`/admin/candidates/${props.orgCandidate.id}/${placementId}`} 
                  className="stand-alone-link">View Interview</Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

interface popularityBoardItem {
  picture?: string;
  title: string;
  subtitle: string;
  lastNumber: number;
}

interface PopularityBoardProps extends RouteComponentProps {
  popularityItems: popularityBoardItem[];
  displayPicture: boolean;
  numberBackground: "yellow" | "grey";
}

const PopularityBoard = (props: PopularityBoardProps) => {
  return (
    <div className="board-popular-items">
      {props.popularityItems && props.popularityItems.length &&
        props.popularityItems.map((popularItem, indx) => (
          <div className="board-item">
            <div className={"item-number " + props.numberBackground}>{indx + 1}</div>
            {props.displayPicture ? <div className="picture"></div> : <></>}
            <div className="title">{popularItem.title}</div>
            <div className="subtitle">{popularItem.subtitle}</div>
            <div className={"rated-item " + props.numberBackground}>{popularItem.lastNumber}</div>
          </div>
        ))
      }
      <div className="card-divider" />

      <span className="board-footer">
        <p className="board-link" onClick={() => props.history.push("candidates")} >View All Applicants</p>
      </span>
    </div>
  )
}

const Dashboard = (props: IDashboardProps) => {
  const orgId = useOrganization();
  const [candidates, loadedCandidates] = useFirestoreCollection<IOrgCandidate>(`Organizations/${orgId}/Candidates`);
  const [mainCandidates, loadedMainCandidates] = useFirestoreCollection<ICandidate>('Candidates',
    q => q.where('orgId', '==', orgId), [orgId]);
  const [recentCandidates, setRecentCandidates] = useState<WithID<IOrgCandidate>[]>();
  // const [candidatesPopularityItems, setCandidatesPopularityItems] = useState<popularityBoardItem[]>();
  const [jobPostingsPopularityItems, setJobPostingsPopularityItems] = useState<popularityBoardItem[]>();
  const [collectivePersonalityTraits, setCollectivePersonalityTraits] = useState<Trait[]>([]);
  const [totalCandidatesWithPersonality, setTotalCandidatesWitPersonality] = useState(0);

  const [placementView, setPlacementView] = useState(<div className="cards-spinner">
      <Spinner color="primary" />
    </div>);

  const [candidatesView, setCandidatesView] = useState(<div className="cards-spinner">
      <Spinner color="primary" />
    </div>);
  
  const [collectivePersonalitiesView, setCollectivePersonalitiesView] = useState(<></>);

  // const [candidatesPopularityBoard, setCandidatesPopularityBoard] = useState(<div className="cards-spinner">
  //     <Spinner color="primary" />
  //   </div>);

  const [jobPostingsPopularityBoard, setJobPostingsPopularityBoard] = useState(<></>);

  let today = new Date();
  const [activePlacements, loadedActivePlacements] = useFirestoreCollection<IPlacement>(
    `Organizations/${orgId}/Placements`,
    q => q.where('status', '==', 'active').where('expiryDate', '>=', today).orderBy("expiryDate", "desc").orderBy("createdAt", "desc").limit(4)
  );

  useEffect(() => {
    if(loadedActivePlacements && activePlacements) {
      let innerJSX = activePlacements.length ?
        <>
          <Row>
            <Col>
              <SectionHeader 
                sectionName="Active Interviews"
                sectionLinkName="view all interviews"
                sectionLink="interviews"
                {...props} />
            </Col>
          </Row>
          <Row>
          {activePlacements.map(placement => (
              <PlacementCard
                placement={placement}
                key={placement.id}
                candidates={candidates}
                orgId={orgId}
                history={props.history}
                displayedOnDashboard={true}
                bodyLinksRemoved={true}
                className="placement-dimentions"
              />
            ))}
          </Row>
        </> :
        <></>;

      setPlacementView(innerJSX);
    }
  }, [loadedActivePlacements, activePlacements]);

  useEffect(() => {
    if(loadedCandidates && candidates && candidates.length)
    setRecentCandidates(candidates.slice(0,4));
  }, [loadedCandidates, candidates]);

  useEffect(() => {
    let innerJSX = recentCandidates && recentCandidates.length ?
      <>
        <Row>
          <Col>
            <SectionHeader 
              sectionName="Recent Applicants"
              sectionLinkName="view all applicants"
              sectionLink="candidates"
              {...props} />
          </Col>
        </Row> 
        <Row>
          {recentCandidates.map((candidate, indx) => (
            <Col className="col-xxxl-1 col-xxl-2" xl="3" lg="4" md="6" sm="12" key={indx}>
              <CandidateItem orgCandidate={candidate} />
            </Col>
          ))}
        </Row>
      </> :
      <></>;
      
      setCandidatesView(innerJSX);
  }, [recentCandidates]);

  useEffect(() => {
    let candidatesWithPersonality = mainCandidates.filter(candidate => candidate.personality);
    
    if(candidatesWithPersonality && candidatesWithPersonality.length){
      let finalPersonalities: Trait[] = [];

      //Next line gives me all personality traits in a one dimensional array
      let innerPersonalitiesFlats = (candidatesWithPersonality.map(candidate => candidate.personality.personality)).flat(1);
      let uniquePersonalities = innerPersonalitiesFlats.length / candidatesWithPersonality.length;

      innerPersonalitiesFlats.map((personality, indx) => {
        let index = finalPersonalities.findIndex(innerPersonality => innerPersonality.trait_id === personality.trait_id);

        if(index === -1){
          finalPersonalities.push({
            trait_id: personality.trait_id, 
            name: personality.name, 
            category: personality.category, 
            percentile: personality.percentile, 
            raw_score: personality.raw_score});
        } else {
          let score = finalPersonalities[index].raw_score ? finalPersonalities[index].raw_score : 0.0;
          let score2 = personality.raw_score ? personality.raw_score : 0.0;

          if(indx < innerPersonalitiesFlats.length - uniquePersonalities)
            finalPersonalities[index].raw_score = (score ? score : 0.0) + score2;
          else
            finalPersonalities[index].raw_score = ((score ? score : 0.0) + score2) / candidatesWithPersonality.length;
        }
      });

      setCollectivePersonalityTraits(finalPersonalities);
      setTotalCandidatesWitPersonality(candidatesWithPersonality.length);
    }
  }, [mainCandidates, loadedMainCandidates]);
  
  // useEffect(() => {
  //   if(candidatesTest && candidatesTest.length) {
  //     let innerBoardItems = candidatesTest.map(candidate => ({
  //       number: candidate.number,
  //       title: candidate.title,
  //       subtitle: candidate.subtitle,
  //       lastNumber: (candidate.rating * 100).toFixed(0) + '%',
  //       picture: ""
  //     } as popularityBoardItem));

  //     if(innerBoardItems)
  //       setCandidatesPopularityItems(innerBoardItems);
  //   }
  // }, [candidatesTest]);

  useEffect(() => {
    if(loadedActivePlacements && activePlacements) {
      let innerBoardItems = activePlacements.map((jobPosting) => {
        const candidatesNum = candidates.filter(c => {
          if (c && c.placements && c.placements.includes(jobPosting.id)) {
            return c.placements.includes(jobPosting.id);
          }
          return false;
        }).length;

        return ({
        title: jobPosting.title,
        subtitle: jobPosting.expiryDate? moment(jobPosting.expiryDate.toDate()).format("MMMM DD, YYYY") : moment(new Date()).format("MMMM DD, YYYY"),
        lastNumber: candidatesNum
      } as popularityBoardItem)});

      if(innerBoardItems) {
        innerBoardItems.sort((previous, current) => current.lastNumber - previous.lastNumber);
        setJobPostingsPopularityItems(innerBoardItems);
      }
    }
  }, [loadedActivePlacements, activePlacements]);

  // useEffect(() => {
  //   if(candidatesPopularityItems && candidatesPopularityItems.length){
  //     let innerJSX = 
  //       <PopularityBoard
  //         popularityItems={candidatesPopularityItems} 
  //         displayPicture={true}
  //         numberBackground="yellow"
  //         {...props} />

  //     setCandidatesPopularityBoard(innerJSX);
  //   }
  // }, [candidatesPopularityItems]);

  useEffect(() => {
    let innerJSX = <></>;

    if(jobPostingsPopularityItems && jobPostingsPopularityItems.length){
      innerJSX = 
        <Col>
          <SectionHeader sectionName="Most Popular Job Posting" {...props} />
          <PopularityBoard
            popularityItems={jobPostingsPopularityItems} 
            displayPicture={false}
            numberBackground="grey"
            {...props} />          
        </Col>;
    }

    setJobPostingsPopularityBoard(innerJSX);
  }, [jobPostingsPopularityItems]);

  useEffect(() => {
    let innerJSX = <></>;

    if(collectivePersonalityTraits && collectivePersonalityTraits.length){
      innerJSX = 
        <Col>
          <SectionHeader sectionName="Colective Applicant Personality Traits" {...props} />
          <div className="candidate-analysis">
            <div className="analysis-inner">
              <PersonalityTraits
                personalityTraits={collectivePersonalityTraits}
                maxBarWidth={maxBarWidth}
                tooltipPlacement="bottom"
                totalApplicants={totalCandidatesWithPersonality}
              />
            </div>
          </div>
        </Col>;
    }

    setCollectivePersonalitiesView(innerJSX);
  })

  let maxBarWidth = 230;

  return (
    <div className="flex-vertical dash-content">
      <div className="item-list">
        <Container fluid>
          {/* <Row>
            <Col xl="2" lg="3" md="3">
              <div>
                <p>Job Posting</p>
                <Select
                  className="orbit-select"
                  isSearchable={true}
                />
              </div>
            </Col>
            <Col xl="2" lg="3" md="3">
              <div>
                <p>Candidate</p>
                <Select
                  className="orbit-select"
                  isSearchable={true}
                />
              </div>
            </Col>
          </Row> */}

          {placementView}
          
          {candidatesView}

          {loadedActivePlacements && loadedCandidates && loadedMainCandidates
            && !activePlacements.length && !candidates.length && !mainCandidates.length &&
            <Row>
              <Col><EmptyNotice message="There is nothing to display! Once you have active job placements, they will be visible here along with any applicant." /></Col>
            </Row>}

          <Row lg="2" md="1">
            
            {/* <Col>
              <SectionHeader sectionName="Colective Applicant Keyword Rating" {...props} />
              <div className="candidate-analysis">
                <div className="analysis-inner">
                  <PersonalityTraits
                    personalityTraits={dummyPersonalityTraits}
                    maxBarWidth={maxBarWidth}
                    tooltipPlacement="bottom"
                    totalApplicants={380}
                  />
                </div>
              </div>
            </Col> */}
            {collectivePersonalitiesView}
            
            {jobPostingsPopularityBoard}
          </Row>
          <Row>
            {/* <Col>
              <SectionHeader sectionName="Applicant Leaderboard" {...props} />
              {candidatesPopularityBoard}
            </Col>
            <Col>
              <SectionHeader sectionName="Most Popular Job Posting" {...props} />
              {jobPostingsPopularityBoard}
            </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  )
};

export default Dashboard;

//Dummy Data//
// const dummyPersonalityTraits: Trait[] = [
//   { category: "personality", name: "Openness", trait_id: "big5_openness", percentile: 0.6534343, raw_score: 0.823123},
//   { category: "personality", name: "Conscientiousness", trait_id: "big5_conscientiousness", percentile: 0.4534343, raw_score: 0.753123},
//   { category: "personality", name: "Extraversion", trait_id: "big5_extraversion", percentile: 0.3534343, raw_score: 0.633123},
//   { category: "personality", name: "Agreeableness", trait_id: "big5_agreeableness", percentile: 0.5534343, raw_score: 0.793123},
//   { category: "personality", name: "Emotional range", trait_id: "big5_neuroticism", percentile: 0.43534343, raw_score: 0.673123}
// ]

// interface popularityBoardTest {
//   number: number;
//   title: string;
//   subtitle: string;
//   rating: number;
// }

// const candidatesTest: popularityBoardTest[] = [
//   { number: 1, title: "Denz Dalio", subtitle: "Developer", rating: 0.769 },
//   { number: 2, title: "Jane Smith", subtitle: "Project Manager", rating: 0.769 },
//   { number: 3, title: "Mohnish Pabrai", subtitle: "Consultant", rating: 0.769 },
//   { number: 4, title: "Elizabeth Thatcher", subtitle: "Developer", rating: 0.769 }
// ]

// const jobPostingsTest: popularityBoardTest[] = [
//   { number: 1, title: "Front End Developer", subtitle: "September 24, 2020", rating: 300 },
//   { number: 2, title: "Database Developer", subtitle: "December 31, 2020", rating: 100 },
//   { number: 3, title: "Project Manager", subtitle: "October 20, 2020", rating: 150 },
//   { number: 4, title: "QA Analyst", subtitle: "November 14, 2020", rating: 175 }
// ]
//End of Dummy Data//