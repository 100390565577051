import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import * as Popper from 'popper.js';
import { Trait } from 'ibm-watson/personality-insights/v3';

interface IPersonalityTraitRowProps {
  personalityTrait: Trait;
  index: number;
  maxBarWidth: number;
  tooltipPlacement: Popper.Placement;
}

export const PersonalityTraitRow = (props: IPersonalityTraitRowProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [currPersonality, setCurrPersonality] = useState<personalityTraitDefinitionsType | undefined>();
  const [personalityDefinition, setPersonalityDefinition] = useState("");

  const toggle = () => setTooltipOpen(!tooltipOpen);
  
  useEffect(() => {
    let innerPersonality = personalityTraitDefinitions.find(personalityTrait => personalityTrait.name === props.personalityTrait.name);

    setCurrPersonality(innerPersonality);
  }, [props.personalityTrait]);

  useEffect(() => {
    setPersonalityDefinition(currPersonality ? currPersonality.description : "");
  }, [currPersonality]);

  return (
    <div className="trait-row">
      <p className="trait" id={`${props.personalityTrait.trait_id}`}>{props.personalityTrait.name}</p>
      <Tooltip placement={props.tooltipPlacement} isOpen={tooltipOpen} autohide={false} target={`${props.personalityTrait.trait_id}`} toggle={toggle} innerClassName="tooltip-candidate" >
        {personalityDefinition}
      </Tooltip>
      <div className="bar" style={{ width: `${props.maxBarWidth * (props.personalityTrait.raw_score ? props.personalityTrait.raw_score : 0)}px` }}>
        {((props.personalityTrait.raw_score? props.personalityTrait.raw_score : 0) * 100).toFixed(0) + '%'}
      </div>
    </div>
  )
}

interface PersonalityTraitsProps {
  personalityTraits: Trait[];
  maxBarWidth: number;
  title?: string;
  totalApplicants?: number;
  tooltipPlacement: Popper.Placement;
}

const PersonalityTraits = (props: PersonalityTraitsProps) => {
  const [header, setHeader] = useState(<></>);
  const [footer, setFooter] = useState(<></>);

  useEffect(() => {
    if(props.title)
      setHeader(<h2>{props.title}</h2>)
  }, [props.title]);

  useEffect(() => {
    if(props.totalApplicants)
      setFooter(
        <>
          <div className="card-divider" />
          <div className="personality-footer">
            <p>Results are based on data from <span className="applicants">{props.totalApplicants} applicants</span></p>
          </div>
        </>
      );
    else
      setFooter(<></>);
  }, [props.totalApplicants]);

  return (
    <div className="personality-traits">
      {header}
      {props.personalityTraits && props.personalityTraits.length ?
        props.personalityTraits.map((personalityTrait, indx) => (
          <PersonalityTraitRow 
          key={`trait-${personalityTrait.trait_id}-${indx}`}
          personalityTrait={personalityTrait} 
          index={indx} 
          maxBarWidth={props.maxBarWidth}
          tooltipPlacement={props.tooltipPlacement} />
        )) : null
        }
      {footer}
    </div>
  );
};

export default PersonalityTraits;

//Dummy Data//

interface personalityTraitDefinitionsType {
  name: string;
  description: string;
}
const personalityTraitDefinitions: personalityTraitDefinitionsType[] = [
  { name: "Openness", description: "Openness to experience refers to a person’s willingness to try new things. A person with a high level of openness tends to have a big imagination, preference for variety, and loves to learn new things. A person low in openness tends to prefer routine and stick to their comfort zone"},
  { name: "Conscientiousness", description: "Conscientiousness refers to how a person gets something done. A person with a high level of conscientiousness spends time preparing, finishes tasks right away, pays attention to details, and prefers to plan ahead to do a task dutifully. A person with a low level of conscientiousness prefers spontaneity and dislikes schedules. They tend to be messy and procrastinate important tasks."},
  { name: "Extraversion", description: "Extroversion refers to how a person draws their energy and interacts with others. A highly extroverted person feels energized from socializing, likes to start conversations, has many friends, and find it easy to meet new people. Meanwhile, those low on the extroversion scale prefer solitude and feel exhausted from socializing too much. They also tend to dislike small talk and find it difficult to start conversations."},
  { name: "Agreeableness", description: "Agreeableness means the way a person interacts with others and whether they have a tendency to cooperate and feel compassionate toward people. A highly agreeable person feels empathy toward other people and enjoys helping. A person low in this trait doesn’t care as much about others feelings and tends to disagree a lot."},
  { name: "Emotional range", description: "Emotional range refers to a person's ability to remain stable and balanced. Those high in neuroticism tend to experience a lot of anxiety and worry about things easily. Someone low in neuroticism tends to be emotionally stable and doesn’t worry often."}
]

//End of Dummy Data//