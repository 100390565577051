import React from 'react';
import { EditorState, Editor, RichUtils } from 'draft-js';

interface IStyleButtonProps {
  onToggle: any;
  style: any;
  label: any;
  active: boolean;
}

class StyleButton extends React.Component<IStyleButtonProps> {
  constructor(props: IStyleButtonProps) {
    super(props);
  }

  onToggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

interface IBlockStyleControlsProps {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' }
];

const BlockStyleControls = (props: IBlockStyleControlsProps) => {
  const selection = props.editorState.getSelection();
  const blockType = props.editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={() => props.setEditorState(RichUtils.toggleBlockType(props.editorState, type.style))}
          style={type.style}
        />
      ))}
    </div>
  );
};

interface IInlineStyleControls {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
}

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' }
];

const InlineStyleControls = (props: IInlineStyleControls) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={() => props.setEditorState(RichUtils.toggleInlineStyle(props.editorState, type.style))}
          style={type.style}
        />
      ))}
    </div>
  );
};

interface IRichEditorProps {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  maxWidthClass: string;
}

export const RichEditor = (props: IRichEditorProps) => {
  const rootClassName = "RichEditor-root";
  const [completeClassName, setCompleteClassName] = React.useState(rootClassName + " " + props.maxWidthClass);

  React.useEffect(() => {
    setCompleteClassName(rootClassName + " " + props.maxWidthClass);
  }, [props.maxWidthClass]);
  
  return (
    <div className={completeClassName}>
      <BlockStyleControls editorState={props.editorState} setEditorState={props.setEditorState} />
      <InlineStyleControls editorState={props.editorState} setEditorState={props.setEditorState} />
      <Editor editorState={props.editorState} onChange={props.setEditorState} placeholder="Enter some text below..." spellCheck={true} />
    </div>
  );
};
