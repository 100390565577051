import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button as ReactstrapButton,
  // Card,
  // CardHeader,
  // CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  // InputGroupText,
  Spinner,
  Button as ReactButton
  // ButtonGroup
} from 'reactstrap';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from 'firebase/app';
import { useState } from 'react';
// import { useForm } from 'react-hook-form';
import { useOrganization, useFirestoreCollection } from '../../util/firebase';
import {
  IPlacement, PlacementStatusClass, PlacementStatusText
  // IPlacementQuestion,
  // ISampleQuestion,
  // IPlacementQuestionType
} from '../../models/Interview.models';
import { WithID } from '../../models/Helpers.models';
import { SocialButton } from '../../components/SocialButton';
// import { GetSkills } from '../../api/skills';
// import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
// import TagsInput from 'react-tagsinput';
import { RouteComponentProps } from 'react-router';
import { IOrgCandidate } from '../../models/User.models';
// import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { EmptyNotice } from '../../components/Util/EmptyNotice';
// import * as yup from 'yup';
// import { FormError } from '../../components/FormError';
import Icon from '../../components/General/Icon';
import Button from '../../components/General/Buttons';
import { ConfirmModal } from '../../components/General/Modal';
// import InterviewRecorder from '../../components/InterviewRecorder';
import CreateEditInterview from '../../components/Dash/CreateEditInterview';
import Moment from 'react-moment';

/*
Bit weird so this needs some explanation -

React DND expects stuff to be local to the page.
We do some DND in this component in placement edit modals.
Portals are a very niche feature of React we can use to properly align the DND.
*/
const portal: HTMLElement = document.createElement('div');
portal.classList.add('placement-question-dnd-portal');
document.body.appendChild(portal);

interface IPlacementCardProps {
  orgId: string;
  placement: WithID<IPlacement>;
  candidates?: WithID<IOrgCandidate>[];
  openPlacement?: () => void;
  openShare?: () => void;
  deletePlacement?: () => void;
  history: RouteComponentProps['history'];
  displayedOnDashboard?: boolean;
  displayedOnInterviews?: boolean;
  bodyLinksRemoved?: boolean;
  className: string;
}

export const PlacementCard = (props: IPlacementCardProps) => {

  const candidatesNum = props.candidates ? props.candidates.filter(c => {
    if (c && c.placements && c.placements.includes(props.placement.id)) {
      return c.placements.includes(props.placement.id);
    }
    return false;
  }).length : 0;

  return (
      <Col className="col-xxxl-1 col-xxl-2" xl="3" lg="4" md="6" sm="12">
        <div className="placement-card flex-vertical">
          <div className="placement-card-header flex-horizontal">
            <h5 className="h3 mb-0">{props.placement.title}</h5>
            {!props.displayedOnDashboard && !props.displayedOnInterviews ? 
              <UncontrolledDropdown nav>
                <DropdownToggle className="btn-neutral" color="default" size="sm">
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={props.openPlacement}>
                    <span>Edit</span>
                  </DropdownItem>
                  <DropdownItem onClick={props.openShare} disabled={props.placement.status !== 'active'}>
                    <span>Share</span>
                  </DropdownItem>
                  {props.placement.status === 'draft' ? (
                    <DropdownItem
                      onClick={() => {
                        firebase
                          .firestore()
                          .doc(`Organizations/${props.orgId}/Placements/${props.placement.id}`)
                          .update({ status: 'active' });
                      }}
                    >
                      <span>Set Active</span>
                    </DropdownItem>
                  ) : null}
                  {props.placement.status === 'active' ? (
                    <DropdownItem
                      onClick={() => {
                        firebase
                          .firestore()
                          .doc(`Organizations/${props.orgId}/Placements/${props.placement.id}`)
                          .update({ status: 'archived' });
                      }}
                    >
                      <span>Archive</span>
                    </DropdownItem>
                  ) : null}
                  {props.placement.status === 'archived' ? (
                    <DropdownItem
                      onClick={() => {
                        firebase
                          .firestore()
                          .doc(`Organizations/${props.orgId}/Placements/${props.placement.id}`)
                          .update({ status: 'active' });
                      }}
                    >
                      <span>Unarchive</span>
                    </DropdownItem>
                  ) : null}
                  <DropdownItem onClick={() => props.history.push(`/admin/candidates?placement=${props.placement.id}`)}>
                    <span>View Candidates</span>
                  </DropdownItem>
                  <DropdownItem onClick={props.deletePlacement}>
                    <span>Delete</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              : 
              <div className="top-right">
                {!props.displayedOnInterviews ? 
                  <div className="link" onClick={() => props.history.push("interviews")} >View</div> 
                  : null
                }
              </div>
            }
          </div>
          <div className="card-divider" />
          <div className="placement-card-body">
            {/* <div className="play-button-container" onClick={() => props.history.push(`/admin/candidates?placement=${props.placement.id}`)}>
              <div className="post-play">
                <i className="ni ni-button-play" />
              </div>
            </div> */}
            <div>
              <div className="placement-card-body flex-horizontal description-row">
                <div className="total-for-row">{props.placement && props.placement.questions && props.placement.questions.length}</div>
                <p>Question{props.placement && props.placement.questions && props.placement.questions.length !== 1 ? 's' : ''}</p>
                {props.bodyLinksRemoved ? <p></p> : <p onClick={props.openPlacement}>Edit Questions</p>}
              </div>
              <div className="placement-card-body flex-horizontal description-row">
                {!props.displayedOnInterviews ? 
                  <>
                    <div className="total-for-row">{candidatesNum}</div>
                    <p>Candidates</p>
                  </> :
                  <>
                    <div className="total-for-row">
                      <span className={'mr-1 ' + PlacementStatusClass(props.placement.status)}>●</span>
                    </div>
                    <p>{PlacementStatusText(props.placement.status)}</p>
                  </>
                }
                {props.bodyLinksRemoved ? <p></p> : <p onClick={() => props.history.push(`/admin/candidates`)}>View</p>}
              </div>
              {/* <p className="text-sm text-muted mb-0">
                {props.placement && props.placement.questions && props.placement.questions.length} Question
                {props.placement && props.placement.questions && props.placement.questions.length !== 1 ? 's' : ''}
              </p>
              <span className={'mr-1 ' + PlacementStatusClass(props.placement.status)}>●</span>
              <small>{PlacementStatusText(props.placement.status)}</small> */}
            </div>
          </div>
          {!props.displayedOnDashboard ? 
            <>
              <div className="card-divider" />
              <div className="placement-card-footer">
                {props.displayedOnInterviews ?
                  <ReactButton
                    color="primary"
                    onClick={() => props.history.push(`/interview/${props.orgId}/${props.placement.id}`)}
                  >
                    Apply
                  </ReactButton> :
                  <>
                    <p>Expires on {props.placement.expiryDate ? <Moment format="MMMM Do, YYYY" date={props.placement.expiryDate!.toDate()}></Moment>: null}</p>
                    <p>Created by <span className="employee-name">{props.placement.createdBy}</span></p>
                  </>
                }
              </div>
            </>
            : null
          }
        </div>
      </Col>
  );
};

interface IPlacementListProps extends RouteComponentProps {
  orgId: string;
  loadedPlacements: boolean;
  placements: WithID<IPlacement>[];
  openPlacement: (placement: WithID<IPlacement>) => void;
  openShare: (placement: WithID<IPlacement>) => void;
  deletePlacement: (placement: WithID<IPlacement>) => void;
}

const PlacementList = (props: IPlacementListProps) => {
  const [candidates, loadedCandidates] = useFirestoreCollection<IOrgCandidate>(`Organizations/${props.orgId}/Candidates`);

  const displayPlacements = (placementsToDisplay: WithID<IPlacement>[]) => {
    return placementsToDisplay.map(placement => (
      <PlacementCard
        placement={placement}
        key={placement.id}
        candidates={candidates}
        openPlacement={() => props.openPlacement(placement)}
        openShare={() => props.openShare(placement)}
        deletePlacement={() => props.deletePlacement(placement)}
        orgId={props.orgId}
        history={props.history}
        className="placement-dimentions"
      />
    ))
  }

  return (
    <div className="item-list">
      {!props.loadedPlacements || !loadedCandidates ? (
        <div className="cards-spinner">
          <Spinner color="primary" />
        </div>
      ) : null}
      {!props.placements.length && props.loadedPlacements ? (
        <EmptyNotice message="You have no interviews set up! To create one, press the Create Interview button in the top-right." />
      ) : null}
      {props.loadedPlacements && props.placements && 
          (<Container fluid>
            <Row>
              {displayPlacements(props.placements)}
            </Row>
          </Container>)
      }
    </div>
  );
};

interface IHeaderProps {
  onNewPlacement: () => void;
  selectedInterview: WithID<IPlacement> | undefined;
  setSelectedInterview: React.Dispatch<React.SetStateAction<WithID<IPlacement> | undefined>>;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEditOpen: boolean;
}

const Header = (props: IHeaderProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
    props.setIsEditOpen(!props.isEditOpen);
  }

  useEffect(() => {
    if(props.selectedInterview && props.isEditOpen)
      setOpenDrawer(true);
  }, [props.selectedInterview]);

  return (
    <div className="activity-header">
      <h1 className="section-header">Active Interviews</h1>
      <div className="button-container flex-horizontal justify-between">
        {/* <Button
          primary
          onClick={(e: any) => {
            e.preventDefault();
            props.onNewPlacement();
          }}
        >
          <Icon name="plus" />
          Create Interview
        </Button> */}

        <Button primary onClick={toggleDrawer}>
          <Icon name="plus" />
          Create Interview
        </Button>
      </div>
      {openDrawer && 
        <CreateEditInterview 
          openDrawer={openDrawer} 
          toggleDrawer={toggleDrawer} 
          selectedInterview={props.selectedInterview}
          setSelectedInterview={props.setSelectedInterview} />}
    </div>
  );
};

/*
interface IInterviewEditModalProps {
  orgId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedInterview?: WithID<IPlacement>;
  setSelectedInterview: (placement: WithID<IPlacement> | undefined) => void;
}

interface IInterviewEditTitleForm {
  title: string;
}

const interviewEditFormValidator = yup.object<IInterviewEditTitleForm>({
  title: yup
    .string()
    .required('Please enter a title.')
    .min(3, 'Title must be at least 3 characters long.')
});

const InterviewEditModal = (props: IInterviewEditModalProps) => {
  const { register, handleSubmit, errors } = useForm<IInterviewEditTitleForm>({ validationSchema: interviewEditFormValidator });

  const [tags, setTags] = useState<string[]>(props.selectedInterview ? props.selectedInterview.tags : []);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  const [questions, setQuestions] = useState<IPlacementQuestion[]>(props.selectedInterview ? props.selectedInterview.questions : []);

  const [lastSelectedInterview, setLastSelectedInterview] = useState(props.selectedInterview);

  const [savingInterview, setSavingInterview] = useState(false);

  const [recording, setRecording] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [selectedQuestionType, setSelectedQuestionType] = useState<IPlacementQuestionType>('text');

  const [skillsLoading, setSkillsLoading] = useState(false);
  const [skills, setSkills] = useState<string[]>([]);
  const [question, setQuestion] = useState<string[]>([]);

  const [editingQuestions, setEditingQuestions] = useState<{ [question: number]: boolean }>({});
  const [editingQuestionsText, setEditingQuestionsText] = useState<{ [question: number]: string }>({});
  const [expiryDate, setExpiryDate] = useState<Date | null>(new Date());
  const [user] = useUser();
  const orgId = useOrganization();
  const orgUser = useFirestoreDoc<IUser>(`Organizations/${orgId}/Team/${user ? user.uid: ''}`);

  const getSkills = React.useCallback(async (query: string) => {
    if (query === '') {
      return [];
    }

    setSkillsLoading(true);
    const resp = await GetSkills(query);
    setSkills(resp.map(skill => skill.name));
    setSkillsLoading(false);
  }, []);

  const [sampleQuestions] = useFirestoreCollection<ISampleQuestion>(`QuestionPool`);

  if (props.selectedInterview !== lastSelectedInterview) {
    setLastSelectedInterview(props.selectedInterview);
    setTags(props.selectedInterview ? props.selectedInterview.tags : []);
    setQuestions(props.selectedInterview ? props.selectedInterview.questions : []);
  }

  const handleSkillsinput = (tags: string[]) => {
    setTags(tags);
  };

  const toggleEditModal = () => props.setOpen(!props.open);

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.type === 'question') {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const newQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));
      const val = newQuestions[sourceIndex];
      newQuestions.splice(sourceIndex, 1);
      newQuestions.splice(destIndex, 0, val);

      setQuestions(newQuestions);
    }
  };

  const deleteQuestion = async (idx: number) => {
    setQuestions(questions.filter((q, i) => i !== idx));
  };

  const editQuestion = async (idx: number) => {
    setEditingQuestionsText({ ...editingQuestionsText, [idx]: questions[idx].text });
    setEditingQuestions({ ...editingQuestions, [idx]: !editingQuestions[idx] });
  };

  const saveEditedQuestion = async (idx: number) => {
    const newQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));
    newQuestions[idx].text = editingQuestionsText[idx];
    setQuestions(newQuestions);
    setEditingQuestions({ ...editingQuestions, [idx]: false });
  };

  const setRange = (questionIdx: number, value: number) => {
    const newQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));
    newQuestions[questionIdx].weight = value;
    setQuestions(newQuestions);
  };

  const onInterviewSave = async (placement: Partial<WithID<IPlacement>>) => {
    setSavingInterview(true);

    placement.tags = tags;
    placement.questions = questions;
    if(expiryDate)
      placement.expiryDate = new firebase.firestore.Timestamp(Math.round(expiryDate.getTime() / 1000), 0);
    delete placement.id;

    const placementsRef = firebase.firestore().collection(`Organizations/${props.orgId}/Placements`);
    if (props.selectedInterview) {
      await placementsRef.doc(props.selectedInterview.id).set(placement, { merge: true });
    } else {
      placement.createdBy = orgUser!.name;
      await placementsRef.add({ ...placement, status: 'draft' });
    }

    props.setOpen(false);
    setSavingInterview(false);
    setLastSelectedInterview(undefined);
    props.setSelectedInterview(undefined);
    setExpiryDate(new Date());
  };

  const addQuestion = (question: IPlacementQuestion) => {
    const tempQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));
    const exist = tempQuestions.find(innerQuestion => innerQuestion.text === question.text);

    if(question.text && !exist){
      tempQuestions.push(question);
      setQuestions(tempQuestions);
    }
    setQuestion([]);
  };

  const addTextQuestion = (question: string) => {
    addQuestion({ text: question, weight: 50, type: 'text' });
  };

  const addVideoQuestion = (uploadUrl: string, mimeType: string) => {
    addQuestion({ text: uploadUrl, weight: 50, type: 'video', mimeType });
  };

  const onRecordingDone = async (blob: Blob, timestamp: string, duration?: number) => {
    setRecording(false);
    setUpload(false);
    setUploadLoading(true);

    console.log('Uploading...');

    const orgId = props.orgId;
    const placementId = '123';
    const n = questions.length.toString();

    const extension = blob.type === 'video/quicktime' ? 'mov' : 'webm';

    const r = firebase.storage().ref(`interview-questions/${orgId}/${placementId}/${questions.length}.${extension}`);
    await r.put(blob, {
      contentType: blob.type,
      customMetadata: {
        placementId: placementId,
        question: n,
        orgId: orgId,
        timestamp
      }
    });
    const videoUrl = await r.getDownloadURL();
    console.log('Uploaded.');
    setUploadLoading(false);

    addVideoQuestion(videoUrl, blob.type);
  };

  const DateCustomInput = (event: any) => (
    <Input rows="8" placeholder="Select an expiry date" defaultValue={event.value} onClick={event.onClick} contentEditable={false} />
  );

  const handleDatePickerChange = (date: Date) => {
    setExpiryDate(date);
  }

  useEffect(() => {
    if(lastSelectedInterview && lastSelectedInterview.expiryDate)
      setExpiryDate(lastSelectedInterview.expiryDate.toDate());
    else
      setExpiryDate(new Date());
  }, [lastSelectedInterview]);

  return (
    <Modal size="lg" isOpen={props.open} toggle={toggleEditModal}>
      <ModalHeader toggle={toggleEditModal}>
        {props.selectedInterview ? `Edit ${props.selectedInterview.title}` : 'Create new Interview'}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <label className="form-control-label" htmlFor="text">
            Title
          </label>
          <Input
            id="title"
            name="title"
            rows="8"
            defaultValue={props.selectedInterview ? props.selectedInterview.title : ''}
            innerRef={register({ required: true })}
            placeholder="Enter a title"
            type="text"
          />
          {errors.title && <FormError>{errors.title.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <label className="form-control-label" htmlFor="expiryDate">
            Expiry Date
          </label><br/>
          <DatePicker 
            id="expiryDate" 
            onChange={handleDatePickerChange} 
            minDate={new Date()}
            selected={expiryDate}
            customInput={<DateCustomInput />}
             />
        </FormGroup>

        <Card>
          <CardHeader>
            <h5 className="h3 mb-0"> Skills</h5>
          </CardHeader>
          <CardHeader className="py-0">
            <InputGroup className="input-group-lg input-group-flush">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>

              <AsyncTypeahead
                allowNew={true}
                isLoading={skillsLoading}
                multiple={false}
                options={skills}
                minLength={3}
                onSearch={getSkills}
                placeholder="Start typing a skill"
                id="skill-selector"
                renderMenuItemChildren={(option, props) => <div>{option}</div>}
                selected={selectedTag}
                selectHintOnEnter={true}
                newSelectionPrefix=""
                onInputChange={input => setSelectedTag([input])}
                onChange={selected => {
                  if (selected[0]) {
                    if ((selected[0] as any).customOption) {
                      const label = (selected[0] as any).label;
                      const exist = tags.indexOf(label);
                      
                      if(label && exist === -1)
                        setTags([label, ...tags]);
                    } else {
                      const exist = tags.indexOf(selected[0]);
                      if(selected[0] && exist === -1)
                        setTags([...selected, ...tags]);
                    }
                    setSelectedTag([]);
                  }
                }}
                onKeyDown={
                  ((e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      
                      const val = (e.target as HTMLInputElement).value;
                      const exist = tags.indexOf(val);

                      if(val && exist === -1)
                        setTags([val, ...tags]);

                      setSelectedTag([]);
                    }
                  }) as any
                }
              />
            </InputGroup>
          </CardHeader>
          <CardBody>
            <TagsInput
              onlyUnique
              className="bootstrap-tagsinput"
              onChange={handleSkillsinput}
              value={tags}
              tagProps={{ className: 'tag badge mr-1' }}
              inputProps={{
                className: '',
                placeholder: ''
              }}
            />
          </CardBody>
        </Card>

        <DragDropContext onDragEnd={onDragEnd}>
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0"> Interview Questions {(questions && questions.length > 0 && '(' + questions.length + ')') || ''} </h5>
              <ButtonGroup>
                <ReactstrapButton
                  color="primary"
                  active={selectedQuestionType === 'text'}
                  onClick={(e: any) => {
                    setSelectedQuestionType('text');
                  }}
                >
                  Text
                </ReactstrapButton>
                <ReactstrapButton
                  color="primary"
                  active={selectedQuestionType === 'video'}
                  onClick={(e: any) => {
                    setSelectedQuestionType('video');
                  }}
                >
                  Video
                </ReactstrapButton>
              </ButtonGroup>
            </CardHeader>
            <CardHeader className="py-0">
              {selectedQuestionType === 'text' ? (
                <InputGroup className="input-group-lg input-group-flush">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <span className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Typeahead
                    allowNew={true}
                    multiple={false}
                    options={sampleQuestions.map(q => q.question)}
                    minLength={1}
                    placeholder="Start typing a question"
                    id="question-selector"
                    renderMenuItemChildren={(option, props) => <div>{option}</div>}
                    selected={question}
                    onInputChange={input => setQuestion([input])}
                    onChange={selected => {
                      if (selected[0]) {
                        const tempQuestions: IPlacementQuestion[] = JSON.parse(JSON.stringify(questions));

                        if ((selected[0] as any).customOption) {
                          const label = (selected[0] as any).label;
                          const exist = tempQuestions.find(question => question.text === label);
                          
                          if(label && !exist)
                            tempQuestions.push({ text: label, weight: 50, type: 'text' });
                        } else {
                          const label = selected[0];
                          const exist = tempQuestions.find(question => question.text === label);

                          if(selected[0] && !exist)
                            tempQuestions.push({ text: selected[0], weight: 50, type: 'text' });
                        }

                        setQuestions(tempQuestions);
                        setQuestion([]);
                      }
                    }}
                    onKeyDown={
                      ((e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          addTextQuestion((e.target as HTMLInputElement).value);
                        }
                      }) as any
                    }
                  />
                  {question.length && question[0] ? (
                    <InputGroupAddon addonType="append">
                      <ReactstrapButton
                        color="primary"
                        onClick={e => {
                          e.preventDefault();
                          addTextQuestion(question[0]);
                        }}
                      >
                        <i className="fas fa-check" />
                      </ReactstrapButton>
                    </InputGroupAddon>
                  ) : null}
                </InputGroup>
              ) : null}
              {selectedQuestionType === 'video' ? (
                <div className={`flex-vertical flex-center recording-card ${recording ? 'recording' : ''}`}>
                  <div className={!recording ? 'show fade-in' : 'hidden'}>
                    <div className="flex-vertical flex-center">
                      {uploadLoading ? <Spinner /> : null}
                      {!recording && !upload && !uploadLoading && (
                        <Button onClick={() => setRecording(true)} className="begin-recording" primary>
                          Begin Recording
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className={`record-container ${recording ? 'show fade-in' : 'hidden'}`}>
                    <InterviewRecorder onRecordingDone={onRecordingDone} record={recording} upload={upload} />
                  </div>
                </div>
              ) : null}
            </CardHeader>
            <CardBody>
              <Droppable droppableId="questions" type="question">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {questions &&
                      questions.map((q, idx) => (
                        <Draggable key={idx} draggableId={idx.toString()} index={idx} type="question">
                          {(provided, snapshot) => {
                            const child = (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Card style={{ display: 'inline-block', width: '100%' }}>
                                  <CardHeader>
                                    <Row className="align-items-center">
                                      <Col xs="8">
                                        {q.type === 'text' || !q.type ? (
                                          editingQuestions[idx] ? (
                                            <Input
                                              type="textarea"
                                              defaultValue={q.text}
                                              onChange={e => {
                                                setEditingQuestionsText({ ...editingQuestionsText, [idx]: e.target.value });
                                              }}
                                            />
                                          ) : (
                                            <h5 className="h3 mb-0">{q.text}</h5>
                                          )
                                        ) : null}
                                        {q.type === 'video' ? (
                                          <video className="video" controls>
                                            <source src={q.text} type={q.mimeType!} />
                                            Your browser does not support the video tag.
                                          </video>
                                        ) : null}
                                      </Col>
                                      <Col className="text-right" xs="4">
                                        {editingQuestions[idx] ? (
                                          <ReactstrapButton
                                            className="btn-neutral"
                                            color="default"
                                            onClick={() => saveEditedQuestion(idx)}
                                            size="sm"
                                          >
                                            <i className="fas fa-check" />
                                          </ReactstrapButton>
                                        ) : (
                                          <>
                                            {(questions[idx].type === 'text' || !questions[idx].type) ? (
                                              <ReactstrapButton
                                                className="btn-neutral"
                                                color="default"
                                                onClick={() => editQuestion(idx)}
                                                size="sm"
                                              >
                                                <i className="fas fa-pencil-alt" />
                                              </ReactstrapButton>
                                            ) : null}
                                            <ReactstrapButton color="danger" onClick={() => deleteQuestion(idx)} size="sm">
                                              <i className="fas fa-trash" />
                                            </ReactstrapButton>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                  </CardHeader>
                                  <CardBody>
                                    <Input
                                      className="orbit-input-range"
                                      defaultValue={q.weight}
                                      key={q.text}
                                      type="range"
                                      min="0"
                                      max="100"
                                      onChange={e => {
                                        setRange(idx, parseFloat(e.currentTarget.value));
                                      }}
                                    />
                                    Question weight: {q.weight || 0}
                                  </CardBody>
                                </Card>
                              </div>
                            );

                            if (!snapshot.isDragging) {
                              return child;
                            }
                            return ReactDOM.createPortal(child, portal);
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </CardBody>
          </Card>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <ReactstrapButton color="primary" type="submit" onClick={handleSubmit(onInterviewSave)} disabled={savingInterview}>
          Save
        </ReactstrapButton>{' '}
        <ReactstrapButton
          color="secondary"
          disabled={savingInterview}
          onClick={(e: any) => {
            props.setOpen(false);
            setLastSelectedInterview(undefined);
            props.setSelectedInterview(undefined);
          }}
        >
          Cancel
        </ReactstrapButton>
      </ModalFooter>
    </Modal>
  );
};
*/
interface IInterviewShareModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedInterview?: WithID<IPlacement>;
  setSelectedInterview: React.Dispatch<React.SetStateAction<WithID<IPlacement> | undefined>>;
  shareUrl: string;
  embedUrl: string;
}

const InterviewShareModal = (props: IInterviewShareModalProps) => {
  const toggleShareModal = () => {
    props.setSelectedInterview(undefined);
    props.setOpen(!props.open);
  }

  const shareUrlRef = React.useRef<HTMLInputElement>(null);
  // const embedUrlRef = React.useRef<HTMLInputElement>(null); //Uncomment once Embed HTML is enabled

  return (
    <Modal isOpen={props.open} toggle={toggleShareModal}>
      <Form
        onSubmit={e => {
          e.preventDefault;
        }}
      >
        <ModalHeader toggle={toggleShareModal}>
          Share
        </ModalHeader>
        {props.selectedInterview && (
          <ModalBody>
            <FormGroup>
              <label className="form-control-label" htmlFor="text">
                Share URL
              </label>

              <InputGroup>
                <Input
                  id="title"
                  name="title"
                  rows="8"
                  value={`${props.shareUrl}/${props.selectedInterview.id}`}
                  readOnly
                  type="text"
                  innerRef={shareUrlRef}
                />
                <InputGroupAddon addonType="append">
                  <ReactstrapButton
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (shareUrlRef.current) {
                        shareUrlRef.current.select();
                        shareUrlRef.current.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                      }
                    }}
                  >
                    Copy
                  </ReactstrapButton>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {/* Uncomment once Embed HTML is enabled
            <FormGroup>
              <label className="form-control-label" htmlFor="text">
                Embed HTML
              </label>

              <InputGroup>
                <Input
                  id="embed"
                  name="embed"
                  rows="8"
                  value={`<iframe src="${props.embedUrl}/${props.selectedInterview.id}"></iframe>`}
                  readOnly
                  type="text"
                  innerRef={embedUrlRef}
                />
                <InputGroupAddon addonType="append">
                  <ReactstrapButton
                    onClick={e => {
                      e.preventDefault();
                      if (embedUrlRef.current) {
                        embedUrlRef.current.select();
                        embedUrlRef.current.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                      }
                    }}
                  >
                    Copy
                  </ReactstrapButton>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            */}
            <FormGroup>
              <label className="form-control-label" htmlFor="text">
                Share on social:
              </label>
              <Row>
                <Col xs="2">
                  <SocialButton
                    network="email"
                    url={`${props.shareUrl}/${props.selectedInterview.id}`}
                    message="We're hiring!"
                    header={`Interview opportunity: ${props.selectedInterview.title}`}
                  />
                </Col>
                <Col xs="2">
                  <SocialButton network="facebook" url={`${props.shareUrl}/${props.selectedInterview.id}`} message="We're hiring!" />
                </Col>
                <Col xs="2">
                  <SocialButton network="linkedin" url={`${props.shareUrl}/${props.selectedInterview.id}`} message="We're hiring!" />
                </Col>
                <Col xs="2">
                  <SocialButton network="twitter" url={`${props.shareUrl}/${props.selectedInterview.id}`} message="We're hiring!" />
                </Col>
              </Row>
              <br />
              <FormGroup>
                <label className="form-control-label" htmlFor="text">
                  Auto post to job boards:
                </label>
                <Row>
                  <Col>
                    <ReactstrapButton color="primary" outline type="button">
                      Coming Soon..
                    </ReactstrapButton>
                  </Col>
                </Row>
              </FormGroup>
            </FormGroup>
          </ModalBody>
        )}
        <ModalFooter>
          <ReactstrapButton color="secondary" onClick={() => props.setOpen(!props.open)}>
            Cancel
          </ReactstrapButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

interface IInterviewsProps extends RouteComponentProps {}

function Interviews(props: IInterviewsProps) {
  const [selectedInterview, setSelectedInterview] = useState<WithID<IPlacement>>();

  const orgId = useOrganization();

  const url = window.location.href;
  const arr = url.split('/');
  const result = arr[0] + '//' + arr[2];
  let today = new Date();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [activePlacements, loadedActivePlacements] = useFirestoreCollection<IPlacement>(
    `Organizations/${orgId}/Placements`,
    q => q.where('expiryDate', '>=', today)
  );
  const [expiredView, setExpiredView] = useState(<></>);
  const [expiredPlacements, loadedExpiredPlacements] = useFirestoreCollection<IPlacement>(
    `Organizations/${orgId}/Placements`,
    q => q.where('expiryDate', '<', today)
  );
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    if(loadedExpiredPlacements && expiredPlacements && expiredPlacements.length){
      let innerExpired = (
        <>
          <h1 className="section-header">Expired Interviews</h1>
          <PlacementList
            orgId={orgId}
            placements={expiredPlacements}
            loadedPlacements={loadedExpiredPlacements}
            deletePlacement={placement => {
              setSelectedInterview(placement);
              setConfirmOpen(true);
            }}
            openPlacement={placement => {
              setIsEditOpen(true);
              setSelectedInterview(placement);
            }}
            openShare={placement => {
              setIsShareOpen(true);
              setSelectedInterview(placement);
            }}
            {...props}
          />
        </>
      )

      setExpiredView(innerExpired);
    }
    else
      setExpiredView(<></>)
  }, [loadedExpiredPlacements, expiredPlacements]);

  return (
    <div className="aflex-vertical dash-content">
      <Header
        onNewPlacement={() => {
          setSelectedInterview(undefined);
          setIsEditOpen(true);
        }}
        selectedInterview={selectedInterview}
        setSelectedInterview={setSelectedInterview}
        setIsEditOpen={setIsEditOpen}
        isEditOpen={isEditOpen}
      />
      <PlacementList
        orgId={orgId}
        placements={activePlacements}
        loadedPlacements={loadedActivePlacements}
        deletePlacement={placement => {
          setSelectedInterview(placement);
          setConfirmOpen(true);
        }}
        openPlacement={placement => {
          setIsEditOpen(true);
          setSelectedInterview(placement);
        }}
        openShare={placement => {
          setIsShareOpen(true);
          setSelectedInterview(placement);
        }}
        {...props}
      />

      {expiredView}

      <ConfirmModal
        open={confirmOpen}
        title="Are you sure?"
        onClose={() => {
          setSelectedInterview(undefined);
          setConfirmOpen(false);
        }}
        onSubmit={async () => {
          firebase
            .firestore()
            .doc(`Organizations/${orgId}/Placements/${selectedInterview!.id}`)
            .delete();
          setConfirmOpen(false);
        }}
      >
        Delete Interview {selectedInterview ? selectedInterview!.title : ''}?
      </ConfirmModal>

      {/* <InterviewEditModal
        orgId={orgId}
        open={isEditOpen}
        setOpen={setIsEditOpen}
        selectedInterview={selectedInterview}
        setSelectedInterview={setSelectedInterview}
      /> */}

      <InterviewShareModal
        open={isShareOpen}
        setOpen={setIsShareOpen}
        selectedInterview={selectedInterview}
        setSelectedInterview={setSelectedInterview}
        shareUrl={`${result}/interview/${orgId}`}
        embedUrl={`${result}/interview-embed/${orgId}`}
      />
    </div>
  );
}

export default Interviews;
