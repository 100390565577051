import React from 'react';
import { Reporter } from '../util/errors';
import Button from '../components/General/Buttons';

interface IInterviewRecorderProps {
  onRecordingDone: (blob: Blob, timestamp: string, duration?: number) => Promise<void>;
  record: boolean;
  upload: boolean;
  onDevicesNotFound?: (devicesNotFound: boolean) => void;
  setErrorMessage?: (error: string) => void;
  setMediaStream?: React.Dispatch<React.SetStateAction<MediaStream | undefined>>;
  mediaStream?: MediaStream | undefined;
  forceFallback?: boolean;
  deniedPermissions?: boolean;
}

interface IInterviewRecorderState {
  stream?: MediaStream;
  dimensions: IVideoDimensions;
  recording: boolean;
  uploading: boolean;
  forceFallback: boolean;
  deniedPermissions: boolean;
  devicesNotFound: boolean;
}

export interface IVideoDimensions {
  width: number;
  height: number;
}

export default class InterviewRecorder extends React.Component<IInterviewRecorderProps, IInterviewRecorderState> {
  private recorder!: MediaRecorder;
  private recordedChunks: {
    blob: Blob;
    timecode: number;
  }[] = [];

  private videoRef = React.createRef<HTMLVideoElement>();
  private fallbackInputRef = React.createRef<HTMLInputElement>();
  private firefoxCompat = false;
  private firefoxStart = 0.0;

  constructor(props: IInterviewRecorderProps) {
    super(props);

    this.state = {
      stream: this.props.mediaStream,
      dimensions: { width: 1280, height: 720 },
      recording: false,
      uploading: false,
      forceFallback: false,
      deniedPermissions: this.props.deniedPermissions ? this.props.deniedPermissions : false,
      devicesNotFound: this.props.forceFallback ? this.props.forceFallback : false
    };
  }

  webRTCSupported = () => !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia && !this.state.forceFallback;

  async componentDidMount() {
    // if (!this.hasStream()) {
    //   await this.start();
    // }
    this.attachToVideo();
  }

  async componentDidUpdate(oldProps: IInterviewRecorderProps) {
    // if (!this.hasStream()) {
    //   await this.start();
    // }
    if(this.state.devicesNotFound)
      return;
    this.attachToVideo();

    if (this.props.record && !oldProps.record) {
      this.record();
    } //else if (!this.props.record && oldProps.record) {
    //   this.stopRecord();
    // }

    if (this.props.upload) {
      await this.uploadVideo();
    }
  }

  private attachToVideo() {
    if (!this.webRTCSupported()) {
      return;
    }

    if (this.videoRef && this.videoRef.current) {
      if (!this.videoRef.current.srcObject && this.hasStream()) {
        const stream = this.getStream();
        if (stream) {
          this.videoRef.current.srcObject = stream;
        }
      }
    }
  }

  // componentWillUnmount() {
  //   if (this.state.recording) {
  //     this.stopRecord();
  //   }
  //   if (this.hasStream()) {
  //     this.stop();
  //   }
  // }

  // private async start() {
  //   if (this.props.mediaStream || !this.webRTCSupported()) {
  //     return;
  //   }

  //   console.log('Starting video stream.');

  //   return navigator.mediaDevices
  //     .getUserMedia({
  //       audio: true,
  //       video: {
  //         ...this.state.dimensions,
  //         facingMode: 'user'
  //       }
  //     })
  //     .then(stream => {
  //       if(this.props.onDevicesNotFound)
  //         this.props.onDevicesNotFound(false);

  //       if(this.props.setErrorMessage)
  //         this.props.setErrorMessage("");
        
  //       this.setState({ devicesNotFound: false });
  //       console.log('Started stream.');
  //       this.setState({ stream });

  //       if(this.props.setMediaStream)
  //         this.props.setMediaStream(stream);

  //       return this.state.stream;
  //     })
  //     .catch(err => {
  //       if (err.name === 'NotAllowedError') {
  //         if (navigator.userAgent.toLowerCase().includes('safari')) {
  //           this.setState({ forceFallback: true });
  //         } else {
  //           this.setState({ deniedPermissions: true });
  //         }
  //         return;
  //       } else if(err.name === 'NotFoundError') {
  //         if(this.props.onDevicesNotFound)
  //           this.props.onDevicesNotFound(true);

  //         if(this.props.setErrorMessage)
  //           this.props.setErrorMessage("We have no access to your microphone and/or camera. Please make sure they are enabled");

  //         this.setState({devicesNotFound: true});
  //       } else {
  //         Reporter.error("Couldn't start stream.", err);
  //       }
  //     });
  // }

  private record() {
    if (!this.hasStream()) {
      return;
    }

    if (this.webRTCSupported()) {
      this.recordedChunks = [];
      try {
        this.recorder = new MediaRecorder(this.state.stream!, { mimeType: 'video/webm;codecs=vp9' });
      } catch (e) {
        //Firefox WEBM VP9 is not supported!
        this.firefoxCompat = true;
        this.firefoxStart = performance.now();
        this.recorder = new MediaRecorder(this.state.stream!, { mimeType: 'video/webm;codecs="vp8, opus"' });
      }
      this.recorder.onerror = (evt: MediaRecorderErrorEvent) => {
        Reporter.error('Media recorder error', evt.error);
      };
      this.recorder.ondataavailable = (evt: BlobEvent) => {
        if (evt.data.size > 0) {
          this.recordedChunks.push({
            blob: evt.data,
            timecode: !this.firefoxCompat ? evt.timecode : performance.now() - this.firefoxStart
          });
        }
      };
      console.log('Started stream recording.');
      this.recorder.start(1000);
    } else {
      if (this.fallbackInputRef.current) {
        this.fallbackInputRef.current.click();
      }
    }

    this.setState({ recording: true });
  }

  // private stopRecord = () => {
  //   if (this.recorder && this.recorder.state === 'recording') {
  //     this.recorder.stop();
  //   }

  //   this.setState({ recording: false });
  //   console.log('Stopped stream recording.');

  //   return true;
  // };

  // private stop() {
  //   if (this.state.recording) {
  //     this.stopRecord();
  //   }

  //   if (this.hasStream() && this.state.stream) {
  //     this.state.stream.getTracks().forEach(t => t.stop());
  //   }

  //   this.setState({ recording: false });
  //   console.log('Stopped stream.');

  //   return true;
  // }

  private hasStream() {
    if (!this.webRTCSupported()) {
      return true;
    }

    return this.getStream() !== undefined;
  }

  private getStream() {
    return this.state.stream;
  }

  private uploadVideo = async () => {
    if (this.recordedChunks.length === 0) {
      console.error('No video chunks to upload.');
      return;
    }

    const blob = new Blob(
      this.recordedChunks.map(t => t.blob),
      {
        type: 'video/webm'
      }
    );

    return this.props.onRecordingDone(
      blob,
      this.recordedChunks[0].timecode.toString(),
      this.recordedChunks[this.recordedChunks.length - 1].timecode - this.recordedChunks[0].timecode
    );
  };

  render() {
    // if (this.state.deniedPermissions) {
    //   return (
    //     <div className="fallback-video-recorder text-white">
    //       To proceed, please enable webcam recording by clicking the target icon in the navigation bar.
    //     </div>
    //   );
    // }

    if (!this.webRTCSupported()) {
      return (
        <div className="fallback-video-recorder">
          <input
            type="file"
            key="fallbackVideo"
            name="fallbackVideo"
            accept="video/*"
            style={{ display: 'none' }}
            ref={this.fallbackInputRef}
            capture={'user'}
            onChange={e => {
              const files = e.target.files || (e as any).dataTransfer.files;
              if (files.length === 0) {
                return;
              }

              const video = files[0];
              delete e.target.value;

              this.props.onRecordingDone(video, new Date().getTime().toString());
            }}
          />
        </div>
      );
    }

    return (
      <div className="video-recorder">
        <video ref={this.videoRef} autoPlay muted playsInline />
        <div className="button-holder">
          <Button onClick={this.uploadVideo} className="delete finish-recording">
            Finish Recording
          </Button>
        </div>
      </div>
    );
  }
}
